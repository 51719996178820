import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserCompaniesComponent } from './user-companies/user-companies.component';

@NgModule({
  imports: [SharedModule, CommonModule, UserCompaniesComponent, RouterLink],
  declarations: [],
})
export class UserModule {}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.deployment === 'local') {
  Sentry.init({
    environment: environment.deployment,
  });
} else {
  Sentry.init({
    dsn: environment.sentry_dsn,
    environment: environment.deployment,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

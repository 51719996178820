import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleIdService {
  private moduleIdSubject = new BehaviorSubject<string | null>(null);
  moduleId$ = this.moduleIdSubject.asObservable();

  setModuleId(moduleId: string) {
    this.moduleIdSubject.next(moduleId);
  }
}

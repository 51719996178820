<div class="flex flex-col">
  <div class="flex flex-row justify-center">
    <div class="flex basis-1/2 flex-col">
      <div
        @fadeIn
        class="text-dms-teal mt-24 flex flex-row justify-center text-6xl font-bold"
      >
        Dashboard
      </div>
      <div
        @fadeIn
        class="text-dms-green mt-10 flex flex-row justify-center text-4xl font-light uppercase tracking-[.5em]"
      >
        Coming Soon
      </div>
    </div>
  </div>
</div>

<div dominionExpandableHost class="">
  <div dominionExpandableToggle class="">Toggle</div>
  <div dominionExpandable class="overflow-hidden bg-green-300">
    Here is one
    <br />
    Here is another
  </div>
</div>

<div dominionAccordion class="mt-16 flex flex-col bg-gray-100">
  <div class="flex flex-row">
    <div dominionExpandableHost class="flex flex-col">
      <div dominionExpandableToggle>Toggle Me</div>
      <div dominionExpandable class="overflow-hidden bg-green-300">
        <div>
          Here is one
          <br />
          Here is another
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div dominionExpandableHost class="flex flex-col">
      <div dominionExpandableToggle>Toggle Me</div>
      <div dominionExpandable class="overflow-hidden bg-green-300">
        <div>
          Here is one
          <br />
          Here is another
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div dominionExpandableHost class="flex flex-col">
      <div dominionExpandableToggle>Toggle Me</div>
      <div dominionExpandable class="overflow-hidden bg-green-300">
        <div>
          Here is one
          <br />
          Here is another
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-24 flex flex-row">
  <dominion-dropdown-caret [isFlipped]="flipped"></dominion-dropdown-caret>
</div>
<button (click)="flip()" class="mt-12">Flip</button>

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-handshake',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-vendor {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>Handshake</title>
    <line class="i-vendor" x1="18.75" y1="14.004" x2="13.963" y2="14.961" />
    <path
      class="i-vendor"
      d="M14.438,9.531l-2.26.955a1.42,1.42,0,0,1-1.122-.016,1.478,1.478,0,0,1-.781-.83,1.525,1.525,0,0,1,.706-1.9L13.24,6.613a1.961,1.961,0,0,1,1.594-.094l4.639,1.828"
    />
    <path
      class="i-vendor"
      d="M5.2,15h1.6l3.049,2.326a.83.83,0,0,0,1.15.09l4.26-3.518a.842.842,0,0,0,.129-1.164l-2.347-2.609"
    />
    <path
      class="i-vendor"
      d="M10.736,7.9l-.247-.2a1.787,1.787,0,0,0-1.725-.221L5.194,9"
    />
    <path
      class="i-vendor"
      d="M1.39,15.75H4.05a1.141,1.141,0,0,0,1.2-1.072V9.321A1.141,1.141,0,0,0,4.05,8.25H1.39"
    />
    <path
      class="i-vendor"
      d="M22.61,15.75H19.95a1.141,1.141,0,0,1-1.2-1.072V9.321a1.141,1.141,0,0,1,1.2-1.071h2.66"
    />
    <circle class="i-vendor" cx="12" cy="12" r="11.25" />
  </svg>`,
})
export class IconHandshakeComponent extends IconBase {}

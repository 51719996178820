<div
  dominionPopoverHost
  [placement]="'top'"
  [hoverTrigger]="true"
  class="{{ buttonHeight }} {{ buttonWidth }} {{ cursor }}"
>
  <dominion-icon-info
    dominionPopoverTarget
    [strokeColor]="strokeColor"
    [fillColor]="fillColor"
    [strokeWidth]="strokeWidth"
  ></dominion-icon-info>
  <div
    class="flex flex-col text-gray-100"
    dominionPopover
    [dark]="true"
    [popoverBorder]="'border-0'"
    [popoverBgColor]="'bg-dms-teal'"
  >
    <span class="flex flex-col"><ng-content></ng-content></span>
  </div>
</div>

<div class="fill-dms-green flex cursor-pointer items-center stroke-white">
  <!-- Add Icon-->
  <div class="relative mr-2 h-7 w-7">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-new {
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.2px;
          }
        </style>
      </defs>
      <title>{{ text }}</title>
      <circle class="i-new" cx="12" cy="12" r="11.25" />
      <line class="i-new" x1="12" y1="7.5" x2="12" y2="16.5" />
      <line class="i-new" x1="7.5" y1="12" x2="16.5" y2="12" />
    </svg>
  </div>
</div>

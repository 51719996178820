<div>
  <div class="mb-4 flex items-center gap-4">
    @for (view of views; track view) {
      <button
        [attr.aria-selected]="selectedView === view"
        (click)="setSelectedView(view)"
        class="aria-selected:text-dms-green capitalize text-gray-700 underline-offset-2 aria-selected:underline"
      >
        {{ view }}
      </button>
    }
  </div>

  <ol role="list" class="max-w-xl">
    @for (log of filteredLogs; track log.date) {
      <li
        class="flex cursor-default flex-col gap-1.5 rounded-sm border-b py-3 transition last:border-b-0"
        [ngClass]="{
          'opacity-60 hover:opacity-100':
            log.category === 'confirm' && log.value !== logs[0].value
        }"
      >
        <div class="flex items-center gap-1 text-sm">
          <div
            class="flex h-3.5 w-3.5 shrink-0 items-center justify-center rounded-sm text-xs font-medium uppercase text-white"
            [ngClass]="{
              'bg-dms-teal': log.userType === 'internal',
              'bg-dms-light-green': log.userType !== 'internal'
            }"
          >
            {{ log.firstName[0] }}
          </div>

          <span
            [title]="log.email"
            class="cursor-help font-semibold"
            [ngClass]="{
              'text-dms-teal': log.userType === 'internal',
              'text-dms-green': log.userType !== 'internal'
            }"
          >
            {{ log.firstName }}
          </span>

          @switch (log.category) {
            @case ('initial') {
              <span>set response</span>
            }
            @case ('update') {
              <span>updated response</span>
            }
            @case ('confirm') {
              <span>confirmed response</span>
            }
          }

          <div class="flex-1"></div>

          <p
            [title]="log.date | date: 'HH:mm:ss, EEEE, MMMM d, y'"
            class="text-xs text-gray-500"
          >
            {{ log.date | date: 'h:mm a' }},
            {{ log.date | date: 'M/dd/yy' }}
          </p>
        </div>

        @switch (log.category) {
          @case ('initial') {
            @if (log.formattedValue) {
              <div
                [title]="log.formattedValue"
                class="bg-dms-green/5 text-dms-green ml-[18px] max-w-full overflow-hidden truncate rounded-sm px-1.5 py-0.5 text-sm font-medium"
              >
                <code class="pr-1.5 text-base">✓</code>
                {{ log.formattedValue }}
              </div>
            }
          }
          @case ('update') {
            @if (log.formattedValue) {
              <div
                class="ml-[18px] flex max-w-full flex-col gap-1.5 text-sm font-medium"
              >
                @if (log.previousFormattedValue) {
                  <div
                    [title]="log.previousFormattedValue"
                    class="overflow-hidden truncate rounded-sm bg-gray-50 px-1.5 py-0.5 text-gray-500"
                  >
                    <code class="pr-1.5 text-base">&times;</code>
                    {{ log.previousFormattedValue }}
                  </div>
                }

                <div
                  [title]="log.formattedValue"
                  class="bg-dms-green/5 text-dms-green overflow-hidden truncate rounded-sm px-1.5 py-0.5"
                >
                  <code class="pr-1.5 text-base">✓</code>
                  {{ log.formattedValue }}
                </div>
              </div>
            }
          }
          @case ('confirm') {
            @if (log.formattedValue) {
              <div
                [title]="log.formattedValue"
                class="bg-dms-green/5 text-dms-green ml-[18px] max-w-full overflow-hidden truncate rounded-sm px-1.5 py-0.5 text-sm font-medium"
              >
                <code class="pr-1.5 text-base">✓</code>
                {{ log.formattedValue }}
              </div>
            }
          }
        }
      </li>
    } @empty {
      <p class="my-4 text-sm italic text-gray-600">No response history</p>
    }
  </ol>
</div>

import {
  AfterContentInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { LoadingPlaceholderComponent } from '../loading-placeholder/loading-placeholder.component';

@Component({
  selector: 'dominion-table',
  standalone: true,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  imports: [LoadingPlaceholderComponent],
})
export class TableComponent implements AfterContentInit {
  /**
   * Unique identifier for the table. This is used to generate unique test ids.
   */
  @Input() id: string = 'table';
  /**
   * Condition to show loading placeholders. Placeholder count is determined
   * by number of column headings, excluding any that include a `.sr-only`
   * class (e.g. icon-based columns).
   */
  @Input() isLoading: boolean = false;
  /**
   * Condition to show an empty state message.
   */
  @Input() isEmpty: boolean = false;
  /**
   * Simplify table container. Helpful when used inside a card.
   */
  @Input() hideFrame: boolean = false;
  /**
   * Reduce padding for a tighter, more compact table.
   */
  @Input() compact: boolean = false;

  @HostBinding('class') get class(): string {
    return this.hideFrame
      ? 'flex-1 overflow-auto border-t'
      : 'flex-1 overflow-auto rounded-sm border shadow-sm';
  }

  @ViewChild('table', { static: true }) table: ElementRef;

  columnCount: number = 0;
  placeholders: boolean[] = [];

  ngAfterContentInit() {
    const headings = Array.from(
      this.table.nativeElement.querySelectorAll('th') as HTMLElement[],
    );
    this.columnCount = headings.length;
    this.placeholders = headings.map((th) => !th.querySelector('.sr-only'));
  }
}

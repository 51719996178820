import { Pipe, PipeTransform } from '@angular/core';
import { StateList, States } from '@dominion/interfaces';

@Pipe({
  name: 'state',
})
export class StatePipe implements PipeTransform {
  transform(value: States): string {
    switch (value) {
      case 'AB':
        return 'Alberta';
      case 'AL':
        return 'Alabama';
      case 'AK':
        return 'Alaska';
      case 'AZ':
        return 'Arizona';
      case 'AR':
        return 'Arkansas';
      case 'BC':
        return 'British Columbia';
      case 'CA':
        return 'California';
      case 'CO':
        return 'Colorado';
      case 'CT':
        return 'Connecticut';
      case 'DC':
        return 'District of Columbia';
      case 'DE':
        return 'Delaware';
      case 'FL':
        return 'Florida';
      case 'GA':
        return 'Georgia';
      case 'HI':
        return 'Hawaii';
      case 'ID':
        return 'Idaho';
      case 'IL':
        return 'Illinois';
      case 'IN':
        return 'Indiana';
      case 'IA':
        return 'Iowa';
      case 'KS':
        return 'Kansas';
      case 'KY':
        return 'Kentucky';
      case 'LA':
        return 'Louisiana';
      case 'ME':
        return 'Maine';
      case 'MD':
        return 'Maryland';
      case 'MA':
        return 'Massachusetts';
      case 'MB':
        return 'Manitoba';
      case 'MI':
        return 'Michigan';
      case 'MN':
        return 'Minnesota';
      case 'MS':
        return 'Mississippi';
      case 'MO':
        return 'Missouri';
      case 'MT':
        return 'Montana';
      case 'NB':
        return 'New Brunswick';
      case 'NE':
        return 'Nebraska';
      case 'NV':
        return 'Nevada';
      case 'NH':
        return 'New Hampshire';
      case 'NJ':
        return 'New Jersey';
      case 'NL':
        return 'Newfoundland and Labrador';
      case 'NM':
        return 'New Mexico';
      case 'NS':
        return 'Nova Scotia';
      case 'NT':
        return 'Northwest Territories';
      case 'NU':
        return 'Nunavut';
      case 'NY':
        return 'New York';
      case 'NC':
        return 'North Carolina';
      case 'ND':
        return 'North Dakota';
      case 'OH':
        return 'Ohio';
      case 'OK':
        return 'Oklahoma';
      case 'ON':
        return 'Ontario';
      case 'OR':
        return 'Oregon';
      case 'PA':
        return 'Pennsylvania';
      case 'PE':
        return 'Prince Edward Island';
      case 'QC':
        return 'Quebec';
      case 'RI':
        return 'Rhode Island';
      case 'SC':
        return 'South Carolina';
      case 'SD':
        return 'South Dakota';
      case 'SK':
        return 'Saskatchewan';
      case 'TN':
        return 'Tennessee';
      case 'TX':
        return 'Texas';
      case 'UT':
        return 'Utah';
      case 'VT':
        return 'Vermont';
      case 'VA':
        return 'Virginia';
      case 'WA':
        return 'Washington';
      case 'WV':
        return 'West Virginia';
      case 'WI':
        return 'Wisconsin';
      case 'WY':
        return 'Wyoming';
    }
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'dominion-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  host: {
    '(window:keydown.esc)': 'close()',
  },
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() titleColor: string = 'text-dms-green';

  @Output() emitClosed: EventEmitter<void> = new EventEmitter();

  @ViewChild('dialog') dialog: any;

  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add('hidden');
  }

  open() {
    this.el.nativeElement.classList.remove('hidden');
    this.el.nativeElement.classList.add('block');
    this.dialog.nativeElement.showModal();
  }

  close() {
    this.el.nativeElement.classList.remove('block');
    this.el.nativeElement.classList.add('hidden');
    this.dialog.nativeElement.close();
    this.emitClosed.emit();
  }
}

import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dominion-icon-base',
  template: ``,
  standalone: true,
})
export class IconBase {
  @Input() height: string = 'h-full';
  @Input() width: string = 'w-full';
  @Input() fillColor: string = 'fill-none';
  @Input() strokeColor: string = 'stroke-gray-500';
  @Input() strokeWidth: string = 'stroke-1';

  @HostBinding('class') get class() {
    return `${this.height} ${this.width} block flex-none`;
  }
}

<div class="relative aspect-square h-full w-full">
  @if (isLocked) {
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="h-full w-full rounded-full bg-gray-200 p-[6px]"
      >
        <dominion-icon-lock-locked
          [strokeColor]="'stroke-gray-600'"
        ></dominion-icon-lock-locked>
      </div>
      <div dominionPopover class="w-48">
        <span class=""> Phase {{ stage }} is locked </span>
      </div>
    </div>
  } @else if (completion < 100) {
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="bg-dms-green h-full w-full rounded-full p-[6px]"
      >
        <dominion-icon-ellipsis
          [strokeColor]="'stroke-white'"
        ></dominion-icon-ellipsis>
      </div>
      <div dominionPopover class="w-48">
        <span>Phase {{ stage }} is {{ completion }}% complete</span>
      </div>
    </div>
  } @else if (completion === 100) {
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="bg-dms-teal h-full w-full rounded-full p-[6px]"
      >
        <dominion-icon-check
          [strokeColor]="'stroke-white'"
        ></dominion-icon-check>
      </div>
      <div dominionPopover class="w-48">
        <span>Phase {{ stage }} is complete.</span>
      </div>
    </div>
  }
  <div
    class="absolute -bottom-[7px] -right-[7px] flex h-4 w-4 flex-row items-center justify-center rounded-full border border-white bg-gray-300 text-[10px] text-gray-800"
  >
    {{ stage }}
  </div>
</div>

<button
  [disabled]="disabled || isLoading"
  [ngClass]="{ isLoading: isLoading }"
  dominionStandardFormSubmitBtn
>
  {{ text }}
  <dominion-load-spinner
    class="ml-2 h-5 w-5 fill-none stroke-white"
    *ngIf="isLoading"
  ></dominion-load-spinner>
</button>

import { IUserAuthorizations, IUserResourceAuth } from '@dominion/interfaces';
import { AuthorizationStrategy, IAuthorization } from '@dominion/interfaces';

export interface ResourceAuthQuery {
  resource: string;
  roles: string[];
  parents: {
    resource: string;
    roles: string[];
  }[];
}

/*
  DOMINION STRATEGIES
*/

export class DominionSuperStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super'],
        permissions: [],
      },
    ];
  }
}

export class DominionAdminStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
    ];
  }
}

export class DominionProjectManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin', 'projectmanager'],
        permissions: [],
      },
    ];
  }
}

export class DominionDeploymentManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin', 'projectmanager', 'deploymentmanager'],
        permissions: [],
      },
    ];
  }
}

export class DominionDeploymentTechnicianStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: [
          'super',
          'admin',
          'projectmanager',
          'deploymentmanager',
          'deploymenttechnician',
        ],
        permissions: [],
      },
    ];
  }
}

export class DominionViewerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: [
          'super',
          'admin',
          'projectmanager',
          'deploymentmanager',
          'deploymenttechnician',
          'viewer',
        ],
        permissions: [],
      },
    ];
  }
}

export class DominionAnyStrategy {
  public authorizations: IAuthorization[] = [];
  constructor() {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: [
          'super',
          'admin',
          'projectmanager',
          'deploymentmanager',
          'deploymenttechnician',
          'viewer',
        ],
        permissions: [],
      },
    ];
  }
}

// only allows if you are pm on this project or higher rank
export class LocalProjectManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'company',
        id: companyId,
        roles: ['projectmanager'],
        permissions: [],
      },
    ];
  }
}

// only allows if you are the dm on this project, pm on this project, or higher
export class LocalDeploymentManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'company',
        id: companyId,
        roles: ['projectmanager', 'deploymentmanager'],
        permissions: [],
      },
    ];
  }
}

// only allows if you are the dt on this project, dm on this project, pm on this project, or higher
export class LocalDeploymentTechnicianStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'dominion',
        id: '',
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'company',
        id: companyId,
        roles: ['projectmanager', 'deploymentmanager', 'deploymenttechnician'],
        permissions: [],
      },
    ];
  }
}

/*
  COMPANY STRATEGIES
*/

export class CompanySuperStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super'],
        permissions: [],
      },
    ];
  }
}

export class CompanyAdminStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super', 'admin'],
        permissions: [],
      },
    ];
  }
}

export class CompanyDeptManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super', 'admin', 'manager'],
        permissions: [],
      },
    ];
  }
}

export class LocalCompanyDeptManagerStrategy {
  public authorizations: IAuthorization[] = [];
  constructor(companyId: string, moduleId: string) {
    this.authorizations = [
      {
        type: 'company',
        id: companyId,
        roles: ['super', 'admin'],
        permissions: [],
      },
      {
        type: 'module',
        id: moduleId,
        roles: ['manager'],
        permissions: [],
      },
    ];
  }
}

/*
  AUTHORIZATION SERVICE
*/

export class Authorization {
  constructor() {}

  private checkAuthorization(
    resourceAuth: IUserResourceAuth,
    authorization: IAuthorization,
  ): boolean {
    // check roles
    for (const role of resourceAuth.roles) {
      if (authorization.roles.includes(role)) {
        return true;
      }
      continue;
    }
    // check permissions
    for (const permission of resourceAuth.permissions) {
      if (authorization.permissions.includes(permission)) {
        return true;
      }
      continue;
    }
    return false;
  }

  public isAuthorized(
    user: IUserAuthorizations,
    strategies: AuthorizationStrategy,
  ): boolean {
    if (!user || !strategies || strategies.length === 0) {
      return false;
    }
    // first loop iterates of each resource strategy
    for (const strategy of strategies) {
      let cumulativeKey = '';
      // second loop iterates over each authorization in the stretegy
      // this is each level of the resource hierarchy
      for (const authorization of strategy.authorizations) {
        // we construct the key and check for it on the user
        // if the user has the key we check roles and permissions
        // if not, then we continue the loop
        const key = `${authorization.type}:${authorization.id}`;
        // we want to add this key to the prefix for the next level of the hierarchy
        // if the prefix is empty, just set to the key
        // if the prefix is not empty, then add a colon and the key
        cumulativeKey = cumulativeKey ? `${cumulativeKey}:${key}` : key;
        // if the user has the key, then we check roles and permissions
        // if they do not have the key, then we skip to the next turn of the loop
        if (user[cumulativeKey]) {
          if (this.checkAuthorization(user[cumulativeKey], authorization)) {
            return true;
          }
        }
        continue;
      }
      continue;
    }
    return false;
  }
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-go-to',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ fillColor }} {{ strokeColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .a {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <polyline class="a" points="23.251 7.498 23.251 0.748 16.501 0.748" />
    <line class="a" x1="23.251" y1="0.748" x2="8.251" y2="15.748" />
    <path
      class="a"
      d="M11.251,5.248h-9a1.5,1.5,0,0,0-1.5,1.5v15a1.5,1.5,0,0,0,1.5,1.5h15a1.5,1.5,0,0,0,1.5-1.5v-9"
    />
  </svg>`,
})
export class IconGoToComponent extends IconBase {}

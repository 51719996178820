<div
  class="flex h-7 flex-row flex-1 items-center border {{ borderRadius }} {{
    hoverShadow
  }} {{ focusBorderColor }} {{ textSize }} {{ borderColor }}"
>
  <!-- search icon -->
  <div
    class="flex-none {{ searchIconSize }} mx-2 relative fill-none {{
      searchIconStroke
    }}"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-search {
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title>search</title>
      <circle
        class="i-search"
        cx="9.813"
        cy="9.812"
        r="9.063"
        transform="translate(-3.056 4.62) rotate(-23.025)"
      />
      <line class="i-search" x1="16.221" y1="16.22" x2="23.25" y2="23.25" />
    </svg>
  </div>

  <!-- search input -->
  <form [formGroup]="searchForm" class="flex flex-1 flex-row">
    <input
      formControlName="search"
      #searchInputEl
      type="text"
      class="w-full outline-none"
    />
  </form>

  <!-- loading indicator -->
  <div
    *ngIf="isLoading"
    class="flex flex-none flex-row items-center justify-center px-2"
  >
    <dominion-load-spinner
      class="{{ loadingIconStroke }} {{ loadingIconSize }} fill-none"
    ></dominion-load-spinner>
  </div>

  <!-- clear search -->
  <div
    (click)="clear()"
    *ngIf="searchForm.get('search')?.value && !isLoading"
    class="flex flex-none cursor-pointer flex-row px-2"
  >
    <div class="fill-none {{ cancelSize }} {{ cancelStroke }}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs>
          <style>
            .i-close-search {
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.5px;
            }
          </style>
        </defs>
        <title>close</title>
        <line
          class="i-close-search"
          x1="0.75"
          y1="23.249"
          x2="23.25"
          y2="0.749"
        />
        <line
          class="i-close-search"
          x1="23.25"
          y1="23.249"
          x2="0.75"
          y2="0.749"
        />
      </svg>
    </div>
  </div>
</div>

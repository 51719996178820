<div class="flex h-full w-full flex-col gap-8 overflow-y-auto p-4 antialiased">
  <div dominionCoreOutletTitle>Questions</div>
  <div class="flex flex-wrap items-center justify-between gap-4">
    <ol role="list" class="flex items-center gap-px">
      @for (module of moduleTypes; track module) {
        <li class="overflow-hidden first:rounded-l-sm last:rounded-r-sm">
          <button
            (click)="selectModule(module)"
            [attr.aria-selected]="module === selectedModule()"
            class="aria-selected:bg-dms-teal flex w-full shrink-0 cursor-pointer items-center justify-center bg-gray-100 px-2 py-1 text-sm font-medium aria-selected:text-white"
          >
            {{ module | moduleType }}
          </button>
        </li>
      }
    </ol>
    <dominion-question-search
      [questions]="searchableQuestions"
    ></dominion-question-search>
  </div>

  <div class="grid grid-cols-[minmax(auto,280px),1fr] gap-6 md:gap-12">
    <nav aria-label="Question groups">
      <h2 class="sr-only text-sm text-gray-500">Groups</h2>
      <ol role="list" class="pr-3 [&>ol~li]:mt-6">
        @if (groups().preliminary.length) {
          <li class="text-sm text-gray-500">
            Phase 1, Preliminary
            <span class="text-xs tabular-nums"
              >({{ groups().preliminary.length }})</span
            >
          </li>
          <hr class="mb-1.5 mt-1.5" />
          <ol role="list">
            @for (group of groups().preliminary; track group.groupKey) {
              <li>
                <button
                  [attr.aria-selected]="group.groupKey === selectedGroupKey()"
                  (click)="selectSubmoduleGroup('preliminary', group.groupKey)"
                  class="aria-selected:text-dms-green p-1.5 text-left font-medium leading-tight"
                >
                  {{ group.groupLabel }}
                </button>
              </li>
            }
          </ol>
        }
        @if (groups().maintenance.length) {
          <li class="text-sm text-gray-500">
            Phase 2, Maintenance
            <span class="text-xs tabular-nums"
              >({{ groups().maintenance.length }})</span
            >
          </li>
          <hr class="mb-1.5 mt-1.5" />
          <ol role="list">
            @for (group of groups().maintenance; track group.groupKey) {
              <li>
                <button
                  [attr.aria-selected]="group.groupKey === selectedGroupKey()"
                  (click)="selectSubmoduleGroup('maintenance', group.groupKey)"
                  class="aria-selected:text-dms-green p-1.5 text-left font-medium leading-tight"
                >
                  {{ group.groupLabel }}
                </button>
              </li>
            }
          </ol>
        }
        @if (groups().default.length) {
          <li class="text-sm text-gray-500">
            Phase 3, Default
            <span class="text-xs tabular-nums"
              >({{ groups().default.length }})</span
            >
          </li>
          <hr class="mb-1.5 mt-1.5" />
          <ol role="list">
            @for (group of groups().default; track group.groupKey) {
              <li>
                <button
                  [attr.aria-selected]="group.groupKey === selectedGroupKey()"
                  (click)="selectSubmoduleGroup('default', group.groupKey)"
                  class="aria-selected:text-dms-green p-1.5 text-left font-medium leading-tight"
                >
                  {{ group.groupLabel }}
                </button>
              </li>
            }
          </ol>
        }
      </ol>
    </nav>
    <div>
      <h2 class="text-sm text-gray-500">Questions</h2>
      <hr class="mb-3 mt-1.5" />
      <dominion-question-list
        [groups]="groups()"
        [questions]="selectedGroup()?.questions"
        [selectedModule]="selectedModule()"
        [selectedSubmodule]="selectedSubmodule()"
        [selectedGroupKey]="selectedGroupKey()"
        [expandedQuestionKey]="expandedQuestionKey()"
        [selectedQuestionKey]="selectedQuestionKey()"
        [selectedQuestion]="selectedQuestion()"
      ></dominion-question-list>
    </div>
  </div>
</div>

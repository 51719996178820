import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { IDiscoveryDataProperty, ResponseOptions } from '@dominion/interfaces';
import { DiscoveryBtnDirective } from '../../shared/discovery-btn/discovery-btn.directive';
import { LogComponent } from '../../log/log.component';
import { ModalComponent } from '../../shared/modal/modal.component';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'dominion-input-select',
  standalone: true,
  imports: [CommonModule, SharedModule, DiscoveryBtnDirective, LogComponent],
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css'],
})
export class InputSelectComponent implements ResponseInput {
  @Input({ required: true }) data: IDiscoveryDataProperty<unknown>;
  get log() {
    return this.data?.log ? this.data.log : [];
  }
  @Input({ required: true }) options: ResponseOptions[];

  @Output() response: EventEmitter<unknown> = new EventEmitter<unknown>();

  @ViewChild('logModal') logModal: ModalComponent;

  constructor() {}

  initializeSelection() {}

  handleErr(err: string): void {
    throw new Error(err);
  }

  handleSuccess(): void {
    return;
  }

  selectResponse(value: unknown): void {
    this.response.emit(value);
  }
}

import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dominionDiscoveryGroup]',
  standalone: true,
})
export class DiscoveryGroupDirective implements AfterViewInit {
  constructor(private el: ElementRef<HTMLDivElement>) {}

  ngAfterViewInit() {
    this.el.nativeElement.classList.add('pb-[100%]');
    this.el.nativeElement.classList.add('block');
    this.el.nativeElement.classList.add('w-full');
    this.el.nativeElement.classList.add('h-full');
    this.el.nativeElement.classList.add('overflow-y-scroll');
    this.el.nativeElement.classList.add('scroll-smooth');
  }
}

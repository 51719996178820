import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '../dropdown-search/dropdown-search.component';
import { CommonModule } from '@angular/common';
import { DiscoveryBtnDirective } from '../discovery-btn/discovery-btn.directive';

@Component({
  selector: 'dominion-discovery-button-input',
  templateUrl: './discovery-button-input.component.html',
  styleUrls: ['./discovery-button-input.component.css'],
  standalone: true,
  imports: [CommonModule, DiscoveryBtnDirective],
})
export class DiscoveryButtonInputComponent {
  @Input() options: DropdownOption[] = [];
  @Input() selectedOption: string | boolean | number | undefined = undefined;

  @Output() emitSelectedOption = new EventEmitter<DropdownOption>();

  constructor() {}

  selectOption(option: DropdownOption) {
    this.selectedOption = option.value;
    this.emitSelectedOption.emit(option);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ICoreModule } from '@dominion/interfaces';

@Pipe({
  name: 'submoduleStatus',
  standalone: true,
})
export class SubmoduleStatusPipe implements PipeTransform {
  transform(
    submodule: ICoreModule['submodules'][
      | 'preliminary'
      | 'maintenance'
      | 'default'],
  ) {
    if (!submodule.completion) {
      return 0;
    }
    const total = submodule.completion.total;
    const completed = submodule.completion.complete;
    if (total !== undefined && completed !== undefined) {
      const percentage = Math.round((completed / total) * 100);
      return percentage;
    }
    return 0;
  }
}

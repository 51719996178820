<dominion-discovery-side-nav></dominion-discovery-side-nav>

<div class="flex h-full w-full flex-col">
  <div class="flex items-center gap-2 p-4">
    <div class="text-dms-teal text-xl font-bold">Documents</div>

    <div>
      <dominion-information-button [buttonHeight]="'h-5'" [buttonWidth]="'w-5'">
        <div class="flex w-64 flex-col space-y-2 p-2">
          <p>
            This page contains a list of all documents that your company has
            uploaded to VOX.
          </p>
        </div>
      </dominion-information-button>
    </div>
  </div>

  <div class="flex-1 overflow-hidden p-4">
    <div class="flex h-full w-full flex-col">
      <dominion-table
        [isLoading]="isLoadingDocuments"
        [isEmpty]="documents ? documents.length === 0 : true"
      >
        <thead>
          <tr>
            <th scope="col">Filename</th>
            <th scope="col">Date Uploaded</th>
            <th scope="col"><span class="sr-only">Download</span></th>
          </tr>
        </thead>
        <tbody>
          @if (documents) {
            @for (doc of documents; track doc._id) {
              <tr>
                <td>
                  <div class="font-semibold">
                    {{ doc.name }}
                  </div>
                </td>
                <td>
                  @if (doc.dateUploaded) {
                    {{ doc.dateUploaded | date }}
                  } @else {
                    <span class="text-gray-400">&mdash;</span>
                  }
                </td>
                <td align="right">
                  <button
                    (click)="downloadFile(doc)"
                    class="text-dms-green hover:bg-dms-light-green/20 group flex h-7 cursor-pointer items-center gap-1.5 truncate rounded-sm p-1.5 text-sm font-medium"
                  >
                    <span>Download</span>
                    <div class="h-4 w-4">
                      <dominion-icon-download
                        strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
                      ></dominion-icon-download>
                    </div>
                  </button>
                </td>
              </tr>
            }
          }
        </tbody>
      </dominion-table>
    </div>
  </div>
</div>

import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[dominionStandardFormLabel]',
})
export class StandardFormLabelDirective implements OnInit {
  @Input() required = false;

  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add(
      'text-dms-grey',
      'text-sm',
      'mb-1',
      'after:text-red-500',
    );
    if (this.required) {
      native.classList.add('after:content-["*"]');
    }
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

import {
  AfterContentInit,
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { ExpandableDirective } from './expandable.directive';
import { ExpandableToggleDirective } from './expandable-toggle.directive';
import { DropdownCaretComponent } from '../dropdown-caret/dropdown-caret.component';
@Directive({
  selector: '[dominionExpandableHost]',
  standalone: true,
})
export class ExpandableHostDirective implements AfterContentInit {
  @Output() expandableToggled: EventEmitter<ExpandableHostDirective> =
    new EventEmitter<ExpandableHostDirective>();

  @ContentChild(ExpandableToggleDirective) toggle: ExpandableToggleDirective;
  @ContentChild(ExpandableDirective) expandable: ExpandableDirective;
  @ContentChild(DropdownCaretComponent) caret?: DropdownCaretComponent;

  constructor() {}

  ngAfterContentInit(): void {
    this.toggle.clicked.subscribe(() => {
      this.expandableToggled.emit(this);
      if (this.expandable.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
    });
  }

  collapse() {
    if (this.expandable.isExpanded) {
      this.expandable.collapse();
      if (this.caret) {
        this.caret.isFlipped = false;
      }
    }
  }

  expand() {
    if (!this.expandable.isExpanded) {
      this.expandable.expand();
      if (this.caret) {
        this.caret.isFlipped = true;
      }
    }
  }
}

<div class="flex flex-1 flex-row justify-center">
  <form
    [formGroup]="addressForm"
    (ngSubmit)="submit()"
    class="flex max-w-md flex-1 flex-col"
  >
    <!-- LINE 1 -->
    <div class="flex flex-col">
      <div dominionStandardFormLabel [required]="true" class="flex flex-row">
        Street Address
      </div>
      <div #streetHost dominionPopoverHost class="flex flex-row">
        <input
          dominionPopoverTarget
          dominionAddressTextInput
          type="text"
          formControlName="street"
          name="street"
          (input)="checkStreetErr()"
          (blur)="checkStreetErr()"
        />
        <div dominionPopover>
          <span dominionStandardFormError>Please enter a street address</span>
        </div>
      </div>
    </div>

    <!-- LINE 2 -->
    <div class="flex flex-col">
      <div dominionStandardFormLabel class="flex flex-row">PO Box or Suite</div>
      <div class="flex flex-row">
        <input
          dominionAddressTextInput
          type="text"
          formControlName="line2"
          name="line2"
        />
      </div>
    </div>

    <!-- CITY, STATE, ZIP -->
    <div class="flex flex-auto flex-row space-x-1">
      <!-- CITY -->
      <div class="flex flex-auto flex-col">
        <div dominionStandardFormLabel [required]="true" class="flex flex-row">
          City
        </div>

        <div #cityHost dominionPopoverHost class="flex flex-row">
          <input
            dominionPopoverTarget
            dominionAddressTextInput
            type="text"
            formControlName="city"
            name="city"
            (input)="checkCityErr()"
            (blur)="checkCityErr()"
          />
          <div dominionPopover>
            <span dominionStandardFormError>Please enter a city</span>
          </div>
        </div>
      </div>

      <!-- STATE -->
      <div class="flex flex-col">
        <div dominionStandardFormLabel [required]="true" class="flex flex-row">
          State
        </div>
        <div #stateHost dominionPopoverHost class="flex min-w-[100px] flex-row">
          <dominion-dropdown-search
            dominionPopoverTarget
            viewType="form"
            class="flex flex-1"
            toggleText="State"
            togglePaddingY="py-[6px]"
            [options]="states"
            [selectedOption]=""
            [dropdownCaret]="true"
            dropdownCaretHeight="h-2"
            dropdownCaretWidth="w-2"
            [hoverBorder]="true"
            [selectedOption]="selectedState"
            (emitClosed)="stateClosed()"
            (emitSelectedValue)="stateSelected($event)"
          ></dominion-dropdown-search>
          <div dominionPopover class="">
            <span dominionStandardFormError>Please select a state</span>
          </div>
        </div>
      </div>

      <!-- ZIP -->
      <div class="flex flex-shrink flex-col">
        <div dominionStandardFormLabel [required]="true" class="flex flex-row">
          Zip
        </div>

        <div #zipHost class="flex flex-row" dominionPopoverHost>
          <input
            dominionPopoverTarget
            dominionAddressTextInput
            type="text"
            formControlName="zip"
            name="zip"
            size="5"
            (input)="checkZipErr()"
            (blur)="checkZipErr()"
          />
          <div dominionPopover>
            <span dominionStandardFormError
              >Please enter a valid 5-digit zip code</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- NEXT BUTTON -->
    <div class="mt-8 flex items-center">
      @if (log.length > 0) {
        <div>
          <button
            type="button"
            (click)="logModal.open()"
            class="group flex items-center gap-1 transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-gray-400"
            >
              <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M12 7v5l4 2" />
            </svg>
            <span class="text-sm text-gray-500 transition group-hover:underline"
              >History</span
            >
          </button>
        </div>
      }
      <div class="flex-1"></div>
      <button
        type="submit"
        [ngClass]="{ disabled: addressForm.invalid }"
        [disabled]="addressForm.invalid"
        dominionDiscoveryNextBtn
      >
        Next
      </button>
    </div>
  </form>
</div>

<dominion-modal #logModal title="Response History">
  <dominion-log body class="h-[400px]" [logs]="log"></dominion-log>
</dominion-modal>

<dominion-auth-root heading="Forgot Password">
  @if (successful) {
    <div
      class="text-dms-green border-dms-green/20 bg-dms-green/5 shadow-dms-green/10 mx-auto mb-6 flex w-full max-w-sm justify-center rounded border p-2 shadow-md"
      data-testid="success-el"
    >
      <p class="text-sm" data-testid="successful">
        An email has been sent to
        <span class="font-bold">{{ this.emailForm.get('email')!.value }}</span>
        with instructions on how to reset your password.
      </p>
    </div>
  }

  @if (isErr) {
    <div
      class="mx-auto mb-6 flex w-full max-w-sm justify-center rounded border border-red-200 bg-red-50 p-2 text-sm text-red-700 shadow-md shadow-red-700/10"
      data-testid="emailForm-gen-err"
    >
      {{ errMessage }}
    </div>
  }

  @if (!successful) {
    <form
      class="flex w-full flex-col gap-5"
      [formGroup]="emailForm"
      (ngSubmit)="sendForgotPasswordRequest()"
      data-testid="form-el"
    >
      <!-- EMAIL -->
      <div class="flex flex-col gap-1.5">
        <label for="email" class="form__label">Email</label>
        <input
          id="email"
          class="form__control"
          type="email"
          placeholder=""
          formControlName="email"
          data-testid="email-input"
          required
        />
        @if (
          this.emailForm.get('email')!.invalid &&
          this.emailForm.get('email')!.touched
        ) {
          <div class="form__error" data-testid="email-err">
            Please enter a valid email address.
          </div>
        }
      </div>

      <div>
        <!-- SUBMIT -->
        <button
          type="submit"
          class="disabled:bg-dms-light-grey bg-dms-teal h-10 w-full rounded px-2 text-sm text-white disabled:cursor-not-allowed disabled:text-gray-400"
          [disabled]="this.emailForm.invalid"
          data-testid="submit-btn"
        >
          Reset Password
        </button>
      </div>
    </form>
  }

  <div class="mt-10 flex w-full flex-col gap-1.5">
    <div>
      <a
        routerLink="/login"
        class="hover:text-dms-teal text-sm text-gray-500"
        data-testid="registerForm-login"
      >
        &larr; Back to sign in
      </a>
    </div>
  </div>
</dominion-auth-root>

<ngx-spinner
  type="ball-clip-rotate-pulse"
  size="large"
  data-testid="forgot-spinner"
>
  <p style="color: white" data-testid="spinner">Requesting password reset...</p>
</ngx-spinner>

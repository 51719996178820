import { Pipe, PipeTransform } from '@angular/core';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';

type User = {
  firstName?: string;
  lastName?: string;
  email: string;
  _id: string;
};

@Pipe({
  name: 'moduleActiveAssignedUser',
  standalone: true,
})
export class ModuleActiveAssignedUserPipe implements PipeTransform {
  transform(user: User | string | undefined): DropdownOption | undefined {
    if (user && typeof user === 'object') {
      return {
        label:
          user.firstName && user.lastName
            ? user.firstName + ' ' + user.lastName
            : user.email,
        value: user._id,
      };
    }

    return undefined;
  }
}

import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IAssignVendorToCompanyDto,
  IVendorCustom,
  IVendorDiscoveryDataResponse,
  IVendorQuestion,
  IVendorReference,
  ResponseOptions,
  TAllGroups,
  IVendorCustomCreateDto,
} from '@dominion/interfaces';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { VendorService } from '../vendors/vendor.service';
import { CompanyService } from '../company/company.service';
import { firstQuestionAnimation } from '../shared/animations/first-question.animation';
import { DiscoveryNextBtnDirective } from '../shared/directives/discovery-next-btn.directive';
import { RouterLink } from '@angular/router';
import { FormInputComponent } from '../form-input/form-input.component';
import { ModalComponent } from '../shared/modal/modal.component';

@Component({
  selector: 'dominion-vendor-group',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DiscoveryNextBtnDirective,
    RouterLink,
    FormInputComponent,
  ],
  templateUrl: './vendor-group.component.html',
  styleUrls: ['./vendor-group.component.css'],
  animations: [firstQuestionAnimation],
})
export class VendorGroupComponent implements OnInit {
  @Input() companyId: string;
  @Input() moduleId: string;
  @Input() submoduleId: string;
  @Input() group: TAllGroups;

  @ViewChild('addVendorModal') addVendorModal: ModalComponent;

  vendorKeys: string[] = [];
  customVendors: IVendorCustom[] = [];
  vendorQuestions: (IVendorQuestion & {
    fullVendors: (IVendorCustom | IVendorReference)[];
    vendorOptions: ResponseOptions[];
  })[] = [];
  discoveryData: {
    [k: string]: {
      value: IVendorDiscoveryDataResponse;
      log: [];
    };
  };
  selectedOptions: {
    [k: string]: ResponseOptions;
  } = {};

  addVendorForm: FormGroup;
  isAddingVendor: boolean = false;
  customVendorQuestionKey: string | null = null;

  constructor(
    private vendorService: VendorService,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) {
    this.addVendorForm = this.fb.group({
      providerName: ['', Validators.required],
      appName: [''],
    });
  }

  ngOnInit(): void {
    // get vendor questions
    // get current discovery data values
    // get custom vendors for company
    this.getCustomVendors();
    this.getVendorQuestions();
    this.getDiscoveryData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['group']) {
      this.vendorKeys = Object.keys(this.group.questions);
      this.getVendorQuestions();
    }
  }

  getCustomVendors() {
    if (this.companyId) {
      this.vendorService.getCustomVendors(this.companyId).subscribe({
        next: (response) => {
          this.customVendors = response;
          this.mergeCustomVendorsIntoVendors();
        },
      });
    }
  }

  getVendorQuestions() {
    this.vendorService
      .getVendorQuestionsByKeys({ questionKeys: this.vendorKeys })
      .subscribe({
        next: (res) => {
          this.vendorQuestions = res as typeof this.vendorQuestions;
          this.mergeCustomVendorsIntoVendors();
        },
      });
  }

  mergeCustomVendorsIntoVendors() {
    if (this.vendorQuestions.length > 0) {
      for (const question of this.vendorQuestions) {
        question.fullVendors = [...this.customVendors, ...question.vendors];
        question.fullVendors.sort((a, b) => {
          const lowerA = a.providerName.toLowerCase();
          const lowerB = b.providerName.toLowerCase();
          if (lowerA < lowerB) {
            return -1;
          }
          if (lowerA > lowerB) {
            return 1;
          }
          return 0;
        });
      }
      this.buildDropdownOptions();
    }
  }

  buildDropdownOptions() {
    for (const question of this.vendorQuestions) {
      question.vendorOptions = question.fullVendors.map((vendor) => {
        if ('vendorId' in vendor) {
          return {
            label: vendor.providerName + ': ' + vendor.appName,
            value: vendor.vendorId + ':standard',
          };
        } else {
          return {
            label: vendor.providerName + ': ' + vendor.appName,
            value: vendor._id + ':custom',
          };
        }
      });
      question.vendorOptions.unshift({
        label: 'None',
        value: 'none',
      });
    }
    this.buildSelectedOptions();
  }

  buildSelectedOptions() {
    if (
      this.vendorQuestions.length > 0 &&
      this.vendorQuestions[0].vendorOptions &&
      this.discoveryData
    ) {
      for (const question of this.vendorQuestions) {
        const currentResponse = this.discoveryData[question.questionKey]?.value;
        if (currentResponse) {
          if (currentResponse.vendorType === 'none') {
            this.selectedOptions[question.questionKey] = {
              label: 'None',
              value: 'none',
            };
          } else {
            const vendor = question.vendorOptions.find((v) => {
              const currentString =
                currentResponse.vendorId + ':' + currentResponse.vendorType;
              return v.value === currentString;
            });
            if (vendor) {
              this.selectedOptions[question.questionKey] = vendor;
            }
          }
        }
      }
    }
  }

  getDiscoveryData() {
    if (this.companyId && this.moduleId && this.submoduleId) {
      this.companyService
        .getSubmoduleFields({
          submoduleId: this.submoduleId,
          fields: this.vendorKeys,
        })
        .subscribe((res) => {
          this.discoveryData = res;
          this.buildSelectedOptions();
        });
    }
  }

  saveVendorResponse(questionKey: string, option: ResponseOptions) {
    let dto: IAssignVendorToCompanyDto;
    if (option.value === 'none') {
      dto = {
        submoduleId: this.submoduleId,
        questionKey: questionKey,
        vendorId: null,
        vendorType: 'none',
      };
    } else {
      const split = (option.value as string).split(':');
      const vendorId = split[0];
      const vendorType = split[1];
      dto = {
        submoduleId: this.submoduleId,
        questionKey: questionKey,
        vendorId: vendorId,
        vendorType: vendorType as IAssignVendorToCompanyDto['vendorType'],
      };
    }

    this.vendorService.assignVendorToCompany(dto).subscribe({
      next: () => {
        this.selectedOptions[questionKey] = option;
      },
    });
  }

  openAddVendorModal(questionKey: string) {
    this.addVendorModal.open();
    this.customVendorQuestionKey = questionKey;
  }

  addVendor() {
    if (this.addVendorForm.valid) {
      this.isAddingVendor = true;
      const dto: IVendorCustomCreateDto = {
        companyId: this.companyId,
        providerName: this.addVendorForm.get('providerName')?.value,
        appName:
          this.addVendorForm.get('appName')?.value === ''
            ? undefined
            : this.addVendorForm.get('appName')?.value,
      };
      this.vendorService.saveCustomVendor(dto).subscribe({
        next: (res) => {
          this.customVendors.push(res);
          this.saveVendorResponse(this.customVendorQuestionKey!, {
            label: res.providerName + ': ' + res.appName,
            value: res._id + ':custom',
          });
          this.mergeCustomVendorsIntoVendors();
          this.isAddingVendor = false;
          this.addVendorModal.close();
        },
      });
    }
  }

  closeAddVendorModal() {
    this.addVendorModal.close();
    this.addVendorForm.reset();
    this.customVendorQuestionKey = null;
  }
}

import {
  AfterContentInit,
  ContentChildren,
  Directive,
  OnChanges,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ExpandableDirective } from './expandable.directive';
import { ExpandableHostDirective } from './expandable-host.directive';
import { ExpandableToggleDirective } from './expandable-toggle.directive';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dominionAccordion]',
  standalone: true,
  host: {},
})
export class AccordionDirective {
  @ContentChildren(ExpandableDirective, { descendants: true })
  expandables: QueryList<ExpandableDirective>;
  @ContentChildren(ExpandableHostDirective, { descendants: true })
  hosts: QueryList<ExpandableHostDirective>;

  subs: Subscription[] = [];

  constructor() {}

  ngAfterContentInit(): void {
    this.hosts.changes.subscribe((changes) => {
      this.handleHostChanges();
    });
  }

  handleHostChanges() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subs = [];
    this.hosts.forEach((host) => {
      host.expandableToggled.subscribe((expandable) => {
        this.handleToggleEvent(expandable);
      });
    });
  }

  handleToggleEvent(hostTarget: ExpandableHostDirective) {
    this.hosts.forEach((host) => {
      if (host === hostTarget) {
        return;
      } else {
        host.collapse();
      }
    });
  }
}

export type States =
  | 'AB'
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'BC'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MB'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NB'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NL'
  | 'NM'
  | 'NS'
  | 'NT'
  | 'NU'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'ON'
  | 'OR'
  | 'PA'
  | 'PE'
  | 'QC'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'SK'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY';

export const StateList: States[] = [
  'AB',
  'AL',
  'AK',
  'AZ',
  'AR',
  'BC',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MB',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NB',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NL',
  'NM',
  'NS',
  'NT',
  'NU',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'ON',
  'OR',
  'PA',
  'PE',
  'QC',
  'RI',
  'SC',
  'SD',
  'SK',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const StateOptions = StateList.map((state) => {
  return {
    value: state,
    label: state,
  };
});

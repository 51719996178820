import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { inject } from '@angular/core';
import { IRequestUserPayload } from '@dominion/interfaces';

export const ExternalGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  let user: IRequestUserPayload | undefined = undefined;

  authService.user.subscribe({
    next: (userInfo) => {
      user = userInfo;
    },
  });

  if (
    user &&
    ((user as any).userType === 'internal' ||
      (user as any).userType === 'external')
  ) {
    return true;
  }
  return router.parseUrl('login');
};

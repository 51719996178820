import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[dominionCoreOutletTitle]',
})
export class CoreOutletTitleDirective {
  @HostBinding('class') class = 'text-dms-green text-xl font-bold';

  constructor(private el: ElementRef) {}
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconInfoComponent } from '../../icons/icon-info.component';
import { PopoverHostDirective } from '../directives/popover-host.directive';
import { PopoverDirective } from '../directives/popover.directive';
import { PopoverTargetDirective } from '../directives/popover-target.directive';

@Component({
  selector: 'dominion-information-button',
  standalone: true,
  imports: [
    CommonModule,
    IconInfoComponent,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
  ],
  templateUrl: './information-button.component.html',
  styleUrls: ['./information-button.component.css'],
})
export class InformationButtonComponent {
  @Input() buttonHeight: string = 'h-3';
  @Input() buttonWidth: string = 'w-3';
  @Input() strokeColor: string = 'stroke-gray-400 hover:stroke-white';
  @Input() fillColor: string = 'fill-none hover:fill-dms-teal';
  @Input() strokeWidth: string = 'stroke-1.5';
  @Input() cursor: string = 'cursor-auto';
}

import { Directive } from '@angular/core';

@Directive({
  selector: '[dominionDiscoveryBtn]',
  standalone: true,
  host: {
    class:
      'basis-1/2 p-2 text-center border border-gray-300 rounded cursor-pointer hover:ring ring-offset-1 ring-dms-light-green [&.selected]:bg-dms-green [&.selected]:border-dms-green [&.selected]:text-white aria-selected:bg-dms-green aria-selected:border-dms-green aria-selected:text-white',
  },
})
export class DiscoveryBtnDirective {
  constructor() {}
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  effect,
} from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import {
  ICompanyReadExternalBrief,
  IRequestUserPayload,
} from '@dominion/interfaces';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CompanyService } from '../company/company.service';
import { RoutingService } from '../routing-service/routing.service';
import { APP_VERSION } from './../../version';

@Component({
  selector: 'dominion-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {
  user: IRequestUserPayload | undefined;
  activeCompany: ICompanyReadExternalBrief | undefined;
  companies: ICompanyReadExternalBrief[] = [];
  activeCompanyId: string | undefined;
  appVersion = APP_VERSION;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private routingService: RoutingService,
  ) {
  }

  goToManageAccount() {
    if (this.user?.userType === 'external') {
      this.router.navigateByUrl(
        '/core/' + this.activeCompanyId + '/manage-account',
      );
    } else {
      this.router.navigateByUrl('/core/admin/manage-account');
    }
  }

  logout() {
    this.authService.logout();
  }

  goToCompany(company: ICompanyReadExternalBrief) {
    this.router.navigateByUrl(`/core/${company._id}`);
  }

  setActiveCompany() {
    if (!this.activeCompanyId) {
      return;
    }
    this.activeCompany = this.companies?.find((company) => {
      return this.activeCompanyId === company._id;
    });
  }

  initialize() {
    if (this.activeCompanyId) {
      this.setActiveCompany();
    }
  }

  handleNavEvent() {
    if (this.user?.userType === 'external') {
      if (
        this.route.parent &&
        this.route.parent.firstChild &&
        this.route.parent.firstChild.firstChild
      ) {
        this.route.parent.firstChild.firstChild.params.subscribe({
          next: (params) => {
            this.activeCompanyId = params['companyid'];
            this.initialize();
          },
        });
      }
    }
  }

  handleSupportClick() {
    if (this.user?.userType === 'internal') {
      window.open('https://share.hsforms.com/1w6q799wzQ3uQEbq7y9-ZEg4xav0', '_blank')
    }
  }

  ngOnInit(): void {
    this.handleNavEvent();
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.handleNavEvent();
        }
      },
    });
    this.authService.user.subscribe({
      next: (user) => {
        this.user = user;
        if (this.user?.userType === 'external') {
          this.routingService.companies$.subscribe({
            next: (companies) => {
              if (companies) {
                this.companies = companies;
                this.handleNavEvent();
              }
            },
          });
        }
      },
    });
  }
}

/*

all users:
  get the user

if user is internal:
  do nothing additional

if the user is external
  get the companies for the user
    set the default company for the user
  check to see if a companyid is specified in the route
    if no:
      navigate to the default company route
    if yes:
      set the active company to the id from the route

*/

<dominion-auth-root heading="Sign in to VOX">
  @if (errMessage) {
    <div
      class="mx-auto mb-6 flex w-full max-w-sm justify-center rounded border border-red-200 bg-red-50 p-2 text-sm text-red-700 shadow-md shadow-red-700/10"
      data-testid="loginForm-err"
    >
      {{ errMessage }}
    </div>
  }

  <form
    class="flex w-full flex-col gap-5"
    [formGroup]="loginForm"
    (ngSubmit)="login()"
  >
    <!-- EMAIL -->
    <div class="flex flex-col gap-1.5">
      <label for="email" class="form__label">Email</label>
      <input
        id="email"
        class="form__control"
        type="email"
        placeholder=""
        formControlName="email"
        data-testid="loginForm-email"
        required
      />
      @if (
        this.loginForm.get('email')!.invalid &&
        this.loginForm.get('email')!.touched
      ) {
        <div class="form__error" data-testid="loginForm-email-err">
          Please enter your email address.
        </div>
      }
    </div>

    <!-- PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="password" class="form__label">Password</label>
      <div class="form__control">
        <input
          id="password"
          #password
          type="password"
          placeholder=""
          formControlName="password"
          data-testid="loginForm-password"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          data-testid="loginForm-show-password"
          class="absolute right-2"
          tabindex="-1"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>
      @if (
        this.loginForm.get('password')!.invalid &&
        this.loginForm.get('password')!.touched
      ) {
        <div class="form__error" data-testid="loginForm-password-err">
          Please enter your password.
        </div>
      }
    </div>

    <div>
      <!-- SUBMIT -->
      <button
        type="submit"
        class="disabled:bg-dms-light-grey bg-dms-teal h-10 w-full rounded px-2 text-sm text-white disabled:cursor-not-allowed disabled:text-gray-400"
        [disabled]="loginForm.invalid"
        data-testid="loginForm-submit"
      >
        Sign In
        <ngx-spinner
          name="login"
          type="ball-clip-rotate-pulse"
          data-testid="login-btn-spinner"
        ></ngx-spinner>
      </button>
    </div>
  </form>

  <div class="mt-10 flex w-full flex-col gap-1.5">
    <div>
      <a
        routerLink="/forgot-password"
        class="hover:text-dms-teal text-sm text-gray-500"
        data-testid="loginForm-forgot-password"
      >
        Forgot your password?
      </a>
    </div>
    <div>
      <a
        routerLink="/register"
        class="hover:text-dms-teal text-sm text-gray-500"
        data-testid="loginForm-register"
      >
        Need to register?
      </a>
    </div>
  </div>
</dominion-auth-root>

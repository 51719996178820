import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-cards',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
    viewBox="0 0 24 24"
  >
    <path d="M2 7v10" stroke="currentColor" stroke-width="1.5" />
    <path d="M6 5v14" stroke="currentColor" stroke-width="1.5" />
    <rect
      width="12"
      height="18"
      x="10"
      y="3"
      rx="2"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>`,
})
export class IconCardsComponent extends IconBase {}

import { DataInitializerBasic } from '../../core/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/core-submodule.interfaces';

export type TSalesPrelimGroupIdentifiers =
  | 'groupVendors'
  | 'groupDesking'
  | 'groupMisc'
  | 'groupOutOfStateSales'
  | 'groupProposals'
  | 'groupSalesPeople';

export type TSalesPrelimDataKeys =
  | 'deskingLocation'
  | 'deskingCRMtoDMS'
  | 'hasCommercial'
  | 'customerClasses'
  | 'hasInHouseLeasing'
  | 'doesBuyHerePayHere'
  | 'customerSignaturePad'
  | 'hasBDC'
  | 'plansBDC'
  | 'hasOutOfStateSales'
  | 'whichStates'
  | 'outOfStatePaperwork'
  | 'doesProposals'
  | 'proposalDeskingTool'
  | 'dealershipProposals'
  | 'salesAccessDMS'
  | 'salesManagersMultiStore'
  | 'salesPeopleMultiStore'
  | 'salesRepairOrders'
  | 'salesScheduleService'
  | 'salesIssuePO'
  | 'vehicleInventoryScanningVendor'
  | 'salesWebsiteVendor'
  | 'vehicleInventoryVendor'
  | 'creditAppVendor'
  | 'appraisalVendor'
  | 'serviceHistoryVendor'
  | 'crmVendor';

const salesPrelimGroupMap = {
  moduleType: 'sales',
  submoduleType: 'preliminary',
  discriminator: 'sales-preliminary',
  groups: {
    groupDesking: ['deskingLocation', 'deskingCRMtoDMS'],
    groupMisc: [
      'hasCommercial',
      'customerClasses',
      'hasInHouseLeasing',
      'doesBuyHerePayHere',
      'customerSignaturePad',
      'hasBDC',
      'plansBDC',
    ],
    groupOutOfStateSales: [
      'hasOutOfStateSales',
      'whichStates',
      'outOfStatePaperwork',
    ],
    groupProposals: [
      'doesProposals',
      'proposalDeskingTool',
      'dealershipProposals',
    ],
    groupSalesPeople: [
      'salesAccessDMS',
      'salesManagersMultiStore',
      'salesPeopleMultiStore',
      'salesRepairOrders',
      'salesScheduleService',
      'salesIssuePO',
    ],
    groupVendors: [
      'vehicleInventoryScanningVendor',
      'salesWebsiteVendor',
      'vehicleInventoryVendor',
      'creditAppVendor',
      'appraisalVendor',
      'serviceHistoryVendor',
      'crmVendor',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TSalesPrelimGroupIdentifiers,
  TSalesPrelimDataKeys
>;

export type TSalesPrelimGroupMap = typeof salesPrelimGroupMap;

export class SalesPreliminaryData
  implements TDataDefinition<TSalesPrelimDataKeys>
{
  // DESKING
  // groupDesking
  deskingLocation = new DataInitializerBasic<'crm' | 'dms'>();
  deskingCRMtoDMS = new DataInitializerBasic<boolean>();

  // MISC
  // groupMisc
  hasCommercial = new DataInitializerBasic<
    'commercial' | 'fleet' | 'both' | 'neither'
  >();
  customerClasses = new DataInitializerBasic<string>();
  hasInHouseLeasing = new DataInitializerBasic<boolean>();
  doesBuyHerePayHere = new DataInitializerBasic<boolean>();
  customerSignaturePad = new DataInitializerBasic<boolean>();
  hasBDC = new DataInitializerBasic<boolean>();
  plansBDC = new DataInitializerBasic<boolean>();

  // OUT OF STATE SALES
  // groupOutOfStateSales
  hasOutOfStateSales = new DataInitializerBasic<boolean>();
  whichStates = new DataInitializerBasic<string>();
  outOfStatePaperwork = new DataInitializerBasic<string>();

  // PROPOSALS
  // groupProposals
  doesProposals = new DataInitializerBasic<boolean>();
  proposalDeskingTool = new DataInitializerBasic<boolean>();
  dealershipProposals = new DataInitializerBasic<boolean>();

  // SALES PEOPLE
  // groupSalesPeople
  salesAccessDMS = new DataInitializerBasic<boolean>();
  salesManagersMultiStore = new DataInitializerBasic<boolean>();
  salesPeopleMultiStore = new DataInitializerBasic<boolean>();
  salesRepairOrders = new DataInitializerBasic<boolean>();
  salesScheduleService = new DataInitializerBasic<boolean>();
  salesIssuePO = new DataInitializerBasic<boolean>();

  // VENDORS
  // groupVendors
  vehicleInventoryScanningVendor = new DataInitializerBasic<string>();
  salesWebsiteVendor = new DataInitializerBasic<string>();
  vehicleInventoryVendor = new DataInitializerBasic<string>();
  creditAppVendor = new DataInitializerBasic<string>();
  appraisalVendor = new DataInitializerBasic<string>();
  serviceHistoryVendor = new DataInitializerBasic<string>();
  crmVendor = new DataInitializerBasic<string>();
}

export const salesPreliminaryStructure: Structures['sales-preliminary'] = {
  moduleType: 'sales',
  submoduleType: 'preliminary',
  discriminator: 'sales-preliminary',
  groups: {
    groupDesking: {
      groupLabel: 'Desking Processes',
      groupType: 'standard',
      groupUrl: 'groupDesking',
      questions: {
        deskingLocation: {
          questionKey: 'deskingLocation',
          label: 'Desking Location',
          prompt: [
            {
              text: 'Do you desk in your CRM or your DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: 'crm',
              label: 'CRM',
            },
            {
              value: 'dms',
              label: 'DMS',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: 'crm',
              dependentKeys: ['deskingCRMtoDMS'],
            },
          ],
        },
        deskingCRMtoDMS: {
          questionKey: 'deskingCRMtoDMS',
          label: 'Desking CRM to DMS',
          prompt: [
            {
              text: 'Does your desking tool push back to your DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSalesPeople: {
      groupLabel: 'Sales People',
      groupType: 'standard',
      groupUrl: 'groupSalesPeople',
      questions: {
        salesAccessDMS: {
          questionKey: 'salesAccessDMS',
          label: 'Sales Access DMS',
          prompt: [
            {
              text: 'Do sales people have access to the DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salesManagersMultiStore: {
          questionKey: 'salesManagersMultiStore',
          label: 'Sales Managers Multi Store',
          prompt: [
            {
              text: 'Do sales managers or F&I managers sell across stores?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salesPeopleMultiStore: {
          questionKey: 'salesPeopleMultiStore',
          label: 'Sales People Multi Store',
          prompt: [
            {
              text: 'Do sales people sell across stores?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salesRepairOrders: {
          questionKey: 'salesRepairOrders',
          label: 'Sales Repair Orders',
          prompt: [
            {
              text: 'Do sales people open pre-delivery repair orders?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salesScheduleService: {
          questionKey: 'salesScheduleService',
          label: 'Sales Schedule Service',
          prompt: [
            {
              text: 'Do sales people schedule first service appontments?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        salesIssuePO: {
          questionKey: 'salesIssuePO',
          label: 'Sales Issue PO',
          prompt: [
            {
              text: 'Do sales people issue purchase orders?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupProposals: {
      groupLabel: 'Proposals',
      groupType: 'standard',
      groupUrl: 'groupProposals',
      questions: {
        doesProposals: {
          questionKey: 'doesProposals',
          label: 'Does Proposals',
          prompt: [
            {
              text: 'Do you use proposals?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['proposalDeskingTool', 'dealershipProposals'],
            },
          ],
        },
        proposalDeskingTool: {
          questionKey: 'proposalDeskingTool',
          label: 'Proposal Desking Tool',
          prompt: [
            {
              text: 'Are proposals generated by the desking tool that you use?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        dealershipProposals: {
          questionKey: 'dealershipProposals',
          label: 'Dealership Proposals',
          prompt: [
            {
              text: 'Are proposals created by the dealership?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupOutOfStateSales: {
      groupLabel: 'Out of State Sales',
      groupType: 'standard',
      groupUrl: 'groupOutOfStateSales',
      questions: {
        hasOutOfStateSales: {
          questionKey: 'hasOutOfStateSales',
          label: 'Has Out of State Sales',
          prompt: [
            {
              text: 'Do you perform out of state sales? (US only)',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['whichStates', 'outOfStatePaperwork'],
            },
          ],
        },
        whichStates: {
          questionKey: 'whichStates',
          label: 'Which States',
          prompt: [
            {
              text: 'Which states do you primarily work with?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        outOfStatePaperwork: {
          questionKey: 'outOfStatePaperwork',
          label: 'Out of State Paperwork',
          prompt: [
            {
              text: 'How are out of state deals handled with paperwork and taxes?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },

    groupMisc: {
      groupLabel: 'Miscellaneous',
      groupType: 'standard',
      groupUrl: 'groupMisc',
      questions: {
        hasCommercial: {
          questionKey: 'hasCommercial',
          label: 'Has Commercial',
          prompt: [
            {
              text: 'Do you have a commercial or fleet department?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: 'commercial',
              label: 'Commercial',
            },
            {
              value: 'fleet',
              label: 'Fleet',
            },
            {
              value: 'both',
              label: 'Both',
            },
            {
              value: 'neither',
              label: 'Neither',
            },
          ],
          validation: [],
          dependencies: [],
        },
        customerClasses: {
          questionKey: 'customerClasses',
          label: 'Customer Classes',
          prompt: [
            {
              text: 'What customer classes do you currently use in your DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        hasInHouseLeasing: {
          questionKey: 'hasInHouseLeasing',
          label: 'Has In House Leasing',
          prompt: [
            {
              text: 'Do you offer in-house leasing?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        doesBuyHerePayHere: {
          questionKey: 'doesBuyHerePayHere',
          label: 'Does Buy Here Pay Here',
          prompt: [
            {
              text: 'Do you offer buy here pay here?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        customerSignaturePad: {
          questionKey: 'customerSignaturePad',
          label: 'Customer Signature Pad',
          prompt: [
            {
              text: 'Do you use a customer signature pad?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'Are you planning on using an iPad, Surface Pro, touch screen monitor, or other electronic signing device?',
              attributes: null,
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        hasBDC: {
          questionKey: 'hasBDC',
          label: 'Has BDC',
          prompt: [
            {
              text: 'Do you have a Business Development Center?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['plansBDC'],
            },
          ],
        },
        plansBDC: {
          questionKey: 'plansBDC',
          label: 'Plans BDC',
          prompt: [
            {
              text: 'Do you plan to implement a BDC?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupVendors: {
      groupLabel: 'Vendors',
      groupType: 'vendor',
      groupUrl: 'groupVendors',
      questions: {
        vehicleInventoryScanningVendor: {
          questionKey: 'vehicleInventoryScanningVendor',
          label: 'Vehicle Inventory Scanning Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for vehicle inventory scanning?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        salesWebsiteVendor: {
          questionKey: 'salesWebsiteVendor',
          label: 'Sales Website Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for your website?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        vehicleInventoryVendor: {
          questionKey: 'vehicleInventoryVendor',
          label: 'Vehicle Inventory Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for managing vehicle inventory?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        creditAppVendor: {
          questionKey: 'creditAppVendor',
          label: 'Credit App Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for credit applications?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        appraisalVendor: {
          questionKey: 'appraisalVendor',
          label: 'Appraisal Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for appraisals?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        serviceHistoryVendor: {
          questionKey: 'serviceHistoryVendor',
          label: 'Service History Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for vehicle service history?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        crmVendor: {
          questionKey: 'crmVendor',
          label: 'CRM Vendor',
          prompt: [
            {
              text: 'What CRM vendor do you plan to use?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
};

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Editable } from '../../../interfaces/editable.interfaces';
import { SharedModule } from '../../shared.module';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectOption, findOption } from '@dominion/interfaces';
import { DropdownOption } from '../../dropdown-search/dropdown-search.component';
import { GetSelectOptionPipe } from '../../pipes/get-select-option.pipe';
import { debounceTime, fromEvent, tap } from 'rxjs';
import { PopoverHostDirective } from '../../directives/popover-host.directive';

type StyleConfig = {
  optionsPaddingX: string;
  optionsPaddingY: string;
  optionsTextSize: string;
  toggleTextSize: string;
  togglePaddingX: string;
  togglePaddingY: string;
  shadowSize: string;
  createNewTextWidth: string;
  hoverBorder: boolean;
  dropdownCaretWidth: string;
  dropdownCaretHeight: string;
  ring: boolean;
  capitalizeOptionLabels: boolean;
};

type ConfigOptions = StyleConfig & {
  /**
   * Whether to use a button to toggle the dropdown.
   *
   * @usageNotes
   * If true, provide the desired button element through the `buttonToggle` projection slot.
   *
   * @default false
   */
  useButtonToggle: boolean;
  /**
   * The type of view to display.
   *
   * @remarks
   * If `'editable'`, the component will appear as an editable UI element.
   * If `'form'`, the dropdown will be displayed as a form element.
   *
   * @default 'form'
   */
  viewType: 'editable' | 'form';
  /**
   * The text to display on the toggle button.
   *
   * @default 'Select'
   */
  toggleText: string;
  /**
   * Whether the component is interactive.
   *
   * @default false
   */
  disabled: boolean;
  /**
   * Whether to filter the dropdown options locally or on the server.
   *
   * @default true
   *
   * @usageNotes
   * If true, provide the entire options list to the `[options]` input and the component will filter locally based on search input criteria. If false, provide a pre-filtered list of options to the `[options]` input.
   */
  localFiltering: boolean;
  /**
   * Whether to enable the creation of new options.
   *
   * @default false
   */
  enableCreateNew: boolean;
  /**
   * The text to display for the create new option.
   *
   * @default 'Create New'
   */
  createNewLabel: string;
  /**
   * Whether the dropdown is searchable.
   *
   * @default true
   *
   * @remarks
   * If true, a search input will be displayed in the dropdown.
   *
   */
  searchable: boolean;
  /**
   * Whether to display a dropdown caret.
   *
   * @default false
   */
  dropdownCaret: boolean;
};

const defaultConfig: ConfigOptions = {
  useButtonToggle: false,
  viewType: 'form',
  toggleText: 'Select',
  disabled: false,
  localFiltering: true,
  enableCreateNew: false,
  createNewLabel: 'Create New',
  searchable: true,
  dropdownCaret: false,
  optionsPaddingX: 'px-2',
  optionsPaddingY: 'py-1',
  optionsTextSize: 'text-sm',
  toggleTextSize: 'text-sm',
  togglePaddingX: 'px-0.5',
  togglePaddingY: 'py-px',
  shadowSize: 'shadow-md',
  createNewTextWidth: '[&>div]:hover:w-32',
  hoverBorder: false,
  dropdownCaretWidth: 'w-4',
  dropdownCaretHeight: 'h-4',
  ring: false,
  capitalizeOptionLabels: false,
};

//
//
//
//
// COMPONENT

@Component({
  selector: 'dominion-in-situ-select',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    GetSelectOptionPipe,
  ],
  templateUrl: './in-situ-select.component.html',
  styleUrls: ['./in-situ-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InSituSelectComponent
  implements AfterViewInit, OnInit, Editable<unknown>, OnChanges
{
  @HostBinding('class')
  class = 'flex max-w-full';

  //
  //
  // INPUTS

  /**
   * The configuration provided by the client.
   */
  @Input({ required: true }) initialValue?: string | number | boolean | null;
  @Input({ required: true }) defaultValue?: string | number | boolean | null;
  @Input({ required: true }) isDisabled: boolean;
  @Input({ required: true }) options: DropdownOption[];
  @Input() config: Partial<ConfigOptions> = {};
  @Input() allowNull = false;

  //
  //
  // OUTPUTS

  @Output() saved = new EventEmitter<unknown>();
  @Output() cancelled = new EventEmitter<void>();
  @Output() startedEditing = new EventEmitter<void>();
  /**
   * Emits when the dropdown is opened
   */
  @Output() opened = new EventEmitter<void>();
  /**
   * Emits when the dropdown is closed
   */
  @Output() closed = new EventEmitter<void>();
  /**
   * Emits when the create new option is clicked
   */
  @Output() createdNew = new EventEmitter<void>();
  /**
   * Emits the filter value
   */
  @Output() filtered = new EventEmitter<string | number | boolean>();

  //
  //
  // VIEW CHILDREN

  @ViewChild('filterInputEl') filterInputEl: ElementRef<HTMLInputElement>;
  @ViewChild('toggle')
  dropdownToggle: ElementRef<HTMLDivElement>;
  @ViewChild(PopoverHostDirective) popoverHost: PopoverHostDirective;

  //
  //
  // STATE

  /**
   * Whether the dropdown is open
   */
  isOpen: boolean;
  /**
   * Whether the filtered options list is loading
   */
  isLoading: boolean;
  filterForm: FormGroup;
  /**
   * The filtered options list
   */
  filteredOptions: SelectOption<string | number | boolean>[] = [];
  /**
   * Whether the filter is active
   */
  isFilterActive: boolean = false;
  /**
   * The configurations to be used by the component.
   *
   * @remarks
   * This is composed from the default configuration and the client-provided configuration.
   *
   * @private
   */
  composedConfig: ConfigOptions = defaultConfig;
  /**
   * The error message from the server
   */
  serverErrMsg: string | null = null;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.filterForm = this.fb.group({
      filter: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']) {
      this.composedConfig = { ...defaultConfig, ...this.config };
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.composedConfig = { ...defaultConfig, ...this.config };
  }

  ngAfterViewInit(): void {
    // if the client requests a searchable dropdown, need to set up observable for filtering the options list on input changes
    if (this.composedConfig.searchable) {
      fromEvent(this.filterInputEl.nativeElement, 'keyup')
        .pipe(
          tap(() => {
            this.isLoading = true;
            this.changeDetectorRef.markForCheck();
          }),
          debounceTime(250),
        )
        .subscribe({
          next: () => {
            this.filterOptions(this.filterForm.get('filter')?.value);
          },
        });
    }
    if (this.composedConfig.viewType === 'editable') {
      this.dropdownToggle.nativeElement.classList.add('editable');
    }
  }

  save(value: string | number | boolean | null) {
    this.saved.emit(value);
  }

  cancel(): void {
    return;
  }

  open() {
    this.isOpen = true;
    this.opened.emit();
  }

  close() {
    this.isOpen = false;
    this.closed.emit();
  }

  createNew(): void {
    this.createdNew.emit();
  }

  getOption(
    value: string | number | boolean,
  ): SelectOption<string | number | boolean> | null {
    return findOption(this.options, value);
  }

  //
  //
  // FILTERING

  clearFilter(): void {
    this.filterForm.reset();
    this.isFilterActive = false;
    this.filteredOptions = this.options;
    this.changeDetectorRef.markForCheck();
  }

  filterOptions(filter: string): void {
    if (filter) {
      this.isFilterActive = true;
      if (this.composedConfig.localFiltering) {
        this.setFilteredOptions(filter);
      } else {
        this.filtered.emit(filter);
      }
    } else {
      this.isFilterActive = false;
      this.isLoading = false;
      this.filteredOptions = this.options;
    }
    this.changeDetectorRef.markForCheck();
  }

  private setFilteredOptions(filter: string): void {
    this.filteredOptions = this.options.filter((option) =>
      option.label.toLowerCase().includes(filter.toLowerCase()),
    );
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  //
  //
  // SERVER RESPONSE HANDLERS

  handleError(errMsg: string): void {
    this.serverErrMsg = errMsg;
    this.popoverHost.show();
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      this.popoverHost.hide();
      this.serverErrMsg = null;
      this.changeDetectorRef.markForCheck();
    }, 3500);
  }

  handleSuccess(): void {
    this.popoverHost.hide();
    this.changeDetectorRef.markForCheck();
    return;
  }
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlipDirective } from '../directives/flip.directive';

@Component({
  selector: 'dominion-dropdown-caret',
  standalone: true,
  imports: [CommonModule, FlipDirective],
  templateUrl: './dropdown-caret.component.html',
  styleUrls: ['./dropdown-caret.component.css'],
})
export class DropdownCaretComponent {
  @Input() fill: string = 'fill-none';
  @Input() stroke: string = 'stroke-gray-400';
  @Input() hoverStroke: string = 'hover:stroke-gray-900';
  @Input() strokeWidth: string = 'stroke-1';
  @Input() caretHeight: string = 'h-4';
  @Input() caretWidth: string = 'w-4';
  @Input() isFlipped: boolean = false;

  constructor() {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { IDiscoveryDataProperty } from '@dominion/interfaces';

@Component({
  selector: 'dominion-input-date',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css'],
})
export class InputDateComponent implements ResponseInput {
  @Input({ required: true }) data: IDiscoveryDataProperty<Date>;
  get log() {
    return this.data?.log ? this.data.log : [];
  }

  @Output() response: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleErr(err: string): void {
    throw new Error(err);
  }

  handleSuccess(): void {
    throw new Error('Method not implemented.');
  }
}

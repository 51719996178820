import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ICompanyReadFull } from '@dominion/interfaces';
import { Types } from 'mongoose';
import { LoadingPlaceholderComponent } from '../../shared/loading-placeholder/loading-placeholder.component';
import { SharedModule } from '../../shared/shared.module';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';

@Component({
  selector: 'dominion-company-related-companies',
  templateUrl: './company-related-companies.component.html',
  styleUrls: ['./company-related-companies.component.css'],
  standalone: true,
  imports: [CommonModule, RouterLink, SharedModule, InformationButtonComponent],
  host: {
    class:
      'bg-white shadow-md hover:shadow-lg min-h-[200px] p-4 border border-gray-100',
  },
})
export class CompanyRelatedCompaniesComponent {
  @Input() isLoading: boolean = false;
  @Input() company: ICompanyReadFull | undefined;
  @Input() internalView: boolean = false;

  constructor(private router: Router) {}

  goToCompany(id: string | Types.ObjectId) {
    if (this.internalView) {
      this.router.navigateByUrl(`core/admin/companies/${id}/detail`);
    } else {
      this.router.navigateByUrl(`core/${id}`);
    }
  }
}

<dominion-auth-root heading="Register for VOX">
  <div
    class="text-dms-green border-dms-green/20 bg-dms-green/5 shadow-dms-green/10 mx-auto mb-6 flex w-full max-w-sm flex-col rounded border p-2 shadow-md"
    data-testid="successful"
  >
    <h2 class="mb-1 text-sm font-medium">Please Confirm Your Email</h2>
    <p class="text-sm">
      Please confirm your email address by clicking the link we sent to you.
    </p>
  </div>
</dominion-auth-root>

import { Pipe, PipeTransform } from '@angular/core';
import { ModuleType } from '@dominion/interfaces';

@Pipe({
  name: 'moduleType',
  standalone: true,
})
export class ModuleTypePipe implements PipeTransform {
  transform(value: ModuleType): string {
    switch (value) {
      case 'sales':
        return 'Sales';
      case 'service':
        return 'Service';
      case 'parts':
        return 'Parts';
      case 'accounting':
        return 'Accounting';
      case 'payroll':
        return 'Payroll';
      case 'fi':
        return 'F&I';
      case 'infrastructure':
        return 'Infrastructure';
      default:
        return value;
    }
  }
}

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing-module/app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { NavComponent } from './nav/nav.component';
import { CoreRootComponent } from './core-root/core-root.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminSideNavComponent } from './admin/admin-side-nav/admin-side-nav.component';
import { InternalComponent } from './admin/admin/admin.component';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';
import { CreateCompanyComponent } from './admin/admin-companies/create-company/create-company.component';
import { Authorization } from '@dominion/authorization';
import { DiscoveryModuleDashboardComponent } from './discovery-module-dashboard/discovery-module-dashboard.component';
import { AdminSupportComponent } from './admin/admin-support/admin-support.component';
import { CompanyService } from './company/company.service';
import { DiscoveryGroupTitleComponent } from './shared/discovery-group-title/discovery-group-title.component';

import * as Sentry from '@sentry/angular-ivy';
import { userDeactivationInterceptor } from './auth/auth.interceptor';
import { IconCloseComponent } from './icons/icon-close.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminDashboardComponent,
    NavComponent,
    CoreRootComponent,
    DashboardComponent,
    AdminSideNavComponent,
    InternalComponent,
    AdminSupportComponent,
    CreateCompanyComponent,
  ],
  imports: [
    DiscoveryModuleDashboardComponent,
    DiscoveryGroupTitleComponent,
    AuthModule,
    BrowserModule,
    IconCloseComponent,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    UserModule,
    SharedModule,
  ],
  providers: [
    provideHttpClient(withInterceptors([userDeactivationInterceptor])),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Authorization,
      useClass: Authorization,
    },
    CompanyService,
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule {}

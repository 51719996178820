import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[dominionAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  @Input() enableAutofocus: boolean = true;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.enableAutofocus && this.el) {
      this.el.nativeElement.focus();
    }
  }
}

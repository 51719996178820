<div class="flex h-full flex-row space-x-6 overflow-y-hidden overflow-x-scroll">
  <ng-container *ngFor="let module of moduleSortedVendorQuestions">
    <div
      *ngIf="
        selectedModuleGroup.value === 'all' ||
        selectedModuleGroup.value === module.moduleType
      "
      class="flex flex-col space-y-2"
    >
      <div
        class="bg-dms-teal text-dms-light-grey shrink rounded-[2px] px-2 py-1 text-lg font-bold uppercase"
      >
        <span class="sticky left-0">
          {{ module.moduleType }}
        </span>
      </div>
      <div class="flex grow flex-row space-x-2 overflow-hidden">
        <div
          *ngFor="let question of module.questions"
          class="flex w-[275px] shrink-0 flex-col space-y-2 overflow-hidden"
        >
          <div
            class="flex shrink flex-row justify-center rounded-[2px] bg-gray-200 p-2 text-center font-semibold"
          >
            <div class="flex flex-col">
              <div class="flex flex-row justify-center">
                {{ question.label }}
              </div>
              <div class="flex flex-row justify-center text-sm font-light">
                {{ question.vendors.length }} Vendors
              </div>
              <div class="flex flex-row justify-center">
                <dominion-dropdown-search
                  [usesButtonToggle]="true"
                  [options]="vendorOptions"
                  (emitSelectedValue)="addVendor($event, question)"
                >
                  <div
                    buttonToggle
                    class="hover:stroke-dms-mid-green relative mt-1 h-5 w-5 cursor-pointer fill-none stroke-black"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <defs>
                        <style>
                          .i-new {
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.2px;
                          }
                        </style>
                      </defs>
                      <title></title>
                      <circle class="i-new" cx="12" cy="12" r="11.25" />
                      <line class="i-new" x1="12" y1="7.5" x2="12" y2="16.5" />
                      <line class="i-new" x1="7.5" y1="12" x2="16.5" y2="12" />
                    </svg>
                  </div>
                </dominion-dropdown-search>
              </div>
            </div>
          </div>
          <div
            class="flex flex-grow flex-col overflow-scroll rounded-[2px] bg-gray-50"
          >
            <div
              *ngFor="let vendor of question.vendors"
              class="hover:bg-dms-light-grey group px-2"
            >
              <div class="flex flex-row space-x-2">
                <div class="flex-none">
                  {{ vendor.providerName }}
                </div>
                <div class="grow truncate font-light text-gray-600">
                  {{ vendor.appName }}
                </div>
                <div
                  (click)="stageVendorDeletion(question, vendor)"
                  class="group-hover:stroke-dms-grey flex flex-none cursor-pointer items-center fill-none stroke-none group-hover:hover:stroke-red-700"
                >
                  <div class="h-4 w-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <defs>
                        <style>
                          .i-delete {
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                          }
                        </style>
                      </defs>
                      <title>Remove Vendor</title>
                      <line
                        class="i-delete"
                        x1="1.5"
                        y1="4.5"
                        x2="22.5"
                        y2="4.5"
                      />
                      <path
                        class="i-delete"
                        d="M14.25,1.5H9.75A1.5,1.5,0,0,0,8.25,3V4.5h7.5V3A1.5,1.5,0,0,0,14.25,1.5Z"
                      />
                      <line
                        class="i-delete"
                        x1="9.75"
                        y1="17.25"
                        x2="9.75"
                        y2="9.75"
                      />
                      <line
                        class="i-delete"
                        x1="14.25"
                        y1="17.25"
                        x2="14.25"
                        y2="9.75"
                      />
                      <path
                        class="i-delete"
                        d="M18.865,21.124A1.5,1.5,0,0,1,17.37,22.5H6.631a1.5,1.5,0,0,1-1.495-1.376L3.75,4.5h16.5Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- DELETE MODAL -->
<dominion-modal #deleteModal title="Delete Confirmation">
  <div body class="flex flex-col">
    <div class="mb-4 mt-6 flex flex-row justify-center">
      Are you sure that you want to delete:
    </div>
    <div
      class="my-2 flex flex-row justify-center text-lg font-bold text-red-700"
    >
      {{ deletion.vendor?.desc }}
    </div>
    <div class="mb-2 mt-6 flex flex-row">
      <dominion-standard-delete-buttons
        (emitCancel)="cancelDeletion()"
        (emitDelete)="deleteVendor()"
      ></dominion-standard-delete-buttons>
    </div>
  </div>
</dominion-modal>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { CommonModule } from '@angular/common';
import { IconLockLockedComponent } from '../../icons/icon-lock-locked.component';

@Component({
  selector: 'dominion-module-action-btn',
  standalone: true,
  imports: [IconGoToComponent, CommonModule, IconLockLockedComponent],
  template: `
    <button
      (click)="handleClick()"
      [disabled]="!isAvailable && !isInternalView"
      class="bg-dms-teal flex flex-1 cursor-pointer items-center rounded-sm p-1 px-3 text-sm text-white transition-all hover:ring-offset-1 enabled:hover:ring-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-600"
    >
      @if (isInternalView) {
        Open
        <div class="ml-2 h-4 w-4">
          <dominion-icon-go-to
            [strokeColor]="'stroke-current'"
            [strokeWidth]="'stroke-2'"
          ></dominion-icon-go-to>
        </div>
      } @else if (!isInternalView) {
        @if (isAvailable) {
          @if (percentageComplete === 0) {
            Start
            <div class="ml-2 h-4 w-4">
              <dominion-icon-go-to
                [strokeColor]="'stroke-current'"
                [strokeWidth]="'stroke-2'"
              ></dominion-icon-go-to>
            </div>
          } @else if (percentageComplete < 100) {
            Continue
            <div class="ml-2 h-4 w-4">
              <dominion-icon-go-to
                [strokeColor]="'stroke-current'"
                [strokeWidth]="'stroke-2'"
              ></dominion-icon-go-to>
            </div>
          } @else if (percentageComplete === 100) {
            Revisit
            <div class="ml-2 h-4 w-4">
              <dominion-icon-go-to
                [strokeColor]="'stroke-current'"
                [strokeWidth]="'stroke-2'"
              ></dominion-icon-go-to>
            </div>
          }
        } @else if (!isAvailable) {
          Unavailable
          <div class="ml-2 h-4 w-4">
            <dominion-icon-lock-locked
              [strokeColor]="'stroke-current'"
              [strokeWidth]="'stroke-2'"
            ></dominion-icon-lock-locked>
          </div>
        }
      }
    </button>
  `,
})
export class ModuleActionBtnComponent {
  @Input() isInternalView: boolean = false;
  @Input() isAvailable: boolean = false;
  @Input() percentageComplete: number = 0;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  handleClick() {
    if (this.isAvailable || this.isInternalView) {
      this.clicked.emit();
    }
  }
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-menu',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .i-menu-a,
        .i-menu-b {
        }
        .i-menu-a {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
        .i-menu-b {
          stroke-miterlimit: 10;
        }
      </style>
    </defs>
    <title>Menu</title>
    <rect
      class="i-menu-a"
      x="0.75"
      y="0.753"
      width="22.5"
      height="22.5"
      rx="1.5"
      ry="1.5"
    />
    <path
      class="i-menu-b"
      d="M6,10.5A1.5,1.5,0,1,0,7.5,12,1.5,1.5,0,0,0,6,10.5Z"
    />
    <path
      class="i-menu-b"
      d="M18,10.5A1.5,1.5,0,1,0,19.5,12,1.5,1.5,0,0,0,18,10.5Z"
    />
    <path
      class="i-menu-b"
      d="M12,10.5A1.5,1.5,0,1,0,13.5,12,1.5,1.5,0,0,0,12,10.5Z"
    />
  </svg>`,
})
export class IconMenuComponent extends IconBase {}

import { Directive } from '@angular/core';

@Directive({
  selector: '[dominionDropdownToggle]',
})
export class DropdownToggleDirective {
  constructor() {
    //
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import {
  IDiscoveryDataProperty,
  ResponseValidation,
} from '@dominion/interfaces';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { HttpErrorResponse } from '@angular/common/http';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { ValidationService } from '../../services/input-validation.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { LogComponent } from '../../log/log.component';
import { SharedModule } from '../../shared/shared.module';
import { AutofocusDirective } from '../../shared/directives/autofocus.directive';

@Component({
  selector: 'dominion-input-text',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DiscoveryNextBtnDirective,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    LogComponent,
    AutofocusDirective,
  ],
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css'],
})
export class InputTextComponent implements OnChanges, ResponseInput {
  @Input({ required: true }) data: IDiscoveryDataProperty<string>;
  get log() {
    return this.data?.log ? this.data.log : [];
  }
  @Input() validation?: ResponseValidation[] = [];
  @Input() isCurrentQuestion: boolean = false;

  @Output() response: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(PopoverHostDirective) popoverHost: PopoverHostDirective;
  @ViewChild('logModal') logModal: ModalComponent;

  form: FormGroup;
  errorMessage: string | undefined;
  serverErrMsg: string | undefined;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
  ) {
    this.form = this.fb.group({
      text: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.setInitialValue();
    }
    if (changes['validation'] && this.validation) {
      this.applyValidators();
    }
  }

  applyValidators(): void {
    const composedValidator = this.validationService.getComposedValidator(
      this.validation ?? [],
    );
    this.form.get('text')?.setValidators(composedValidator);
    this.form.get('text')?.updateValueAndValidity();
  }

  setInitialValue() {
    this.form.get('text')!.setValue(this.data.value);
  }

  checkErrors() {
    if (this.form.invalid) {
      this.errorMessage = 'Please enter a valid response.';
      this.popoverHost.show();
    }
  }

  save() {
    this.resetErrors();
    if (this.form.valid) {
      this.response.emit(this.form.get('text')!.value);
    } else {
      this.errorMessage = 'Please enter a valid response.';
      this.popoverHost.show();
    }
  }

  handleErr(err: HttpErrorResponse): void {
    this.serverErrMsg = err.error.message;
    this.popoverHost.show();
  }

  handleSuccess(): void {
    return;
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.errorMessage = undefined;
    this.popoverHost.hide();
  }
}

import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[dominionStandardFormSectionLabel]',
})
export class StandardFormSectionLabelDirective implements OnInit {
  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add(
      'uppercase',
      'w-full',
      'font-light',
      'text-dms-green',
      'pt-7',
      'text-sm',
    );
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-right-caret',
  template: `<svg
    id="Regular"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ fillColor }} {{ strokeColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .i-right-caret {
          stroke-linecap: round;
          stroke-linejoin: round;
          fill-rule: evenodd;
        }
      </style>
    </defs>
    <title>Right Arrow</title>
    <path
      class="i-right-caret"
      d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"
    />
  </svg>`,
  standalone: true,
})
export class IconRightCaretComponent extends IconBase {}

import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dominionStandardFormCancelBtn]',
})
export class StandardFormCancelBtnDirective {
  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add(
      'w-full',
      'bg-dms-light-grey',
      'rounded-l',
      'text-dms-grey',
      'p-2',
      'text-center',
      'cursor-pointer',
      'mr-[1px]',
    );
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

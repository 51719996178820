import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { APP_VERSION } from '../../version';

const apiBaseUrl = environment.apiBaseUrl;

@Component({
  selector: 'dominion-core-root',
  templateUrl: './core-root.component.html',
  styleUrls: ['./core-root.component.css'],
})
export class CoreRootComponent implements OnInit, OnDestroy {
  private authService = inject(AuthenticationService);
  private router = inject(Router);
  private http = inject(HttpClient);
  public showVersionNotification = false;
  public versionInterval: any;
  public appVersion: string = APP_VERSION;
  public serverVersion: string | null = null;
  private versionIntervalDuration = 1000 * 60 * 5; //  5 minutes

  constructor() {}

  ngOnInit(): void {
    this.authService.user.subscribe({
      next: (user) => {
        if (!user) {
          this.router.navigateByUrl('/login');
        }
      },
    });
    this.versionInterval = setInterval(() => {
      this.checkServerVersion();
    }, this.versionIntervalDuration);
  }

  ngOnDestroy(): void {
    clearInterval(this.versionInterval);
  }

  checkServerVersion(): void {
    this.http.get<{ version: string }>(`${apiBaseUrl}version`).subscribe({
      next: (res) => {
        this.serverVersion = res.version;
        this.compareVersions();
      },
    });
  }

  compareVersions(): void {
    if (this.serverVersion !== this.appVersion) {
      this.triggerVersionNotification();
    }
  }

  triggerVersionNotification(): void {
    this.showVersionNotification = true;
  }

  refreshPage(): void {
    window.location.reload();
  }

  dismissVersion(): void {
    this.showVersionNotification = false;
  }
}

/*

when component is initialized:
  if user is not logged in, redirect to login

if the params ever end at just core
  if admin user:
    redirect to core/admin
  if external user:
    redirect to default company

*/

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-shield',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .a {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>protection-shield-4</title>
    <path
      class="a"
      d="M20.769,16.269,6.2,1.483A19.156,19.156,0,0,0,3.118,2.561,1.486,1.486,0,0,0,2.25,3.923V5.162L17.634,20.541A11.923,11.923,0,0,0,20.769,16.269Z"
    />
    <path
      class="a"
      d="M2.25,3.923v7.614A11.907,11.907,0,0,0,9.882,22.65l1.041.4a3,3,0,0,0,2.154,0l1.041-.4A11.907,11.907,0,0,0,21.75,11.537V3.923a1.487,1.487,0,0,0-.868-1.362A21.7,21.7,0,0,0,12,.75,21.7,21.7,0,0,0,3.118,2.561,1.487,1.487,0,0,0,2.25,3.923Z"
    />
  </svg>`,
})
export class IconShieldComponent extends IconBase {}

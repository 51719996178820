import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptText } from '@dominion/interfaces';
import { PopoverDirective } from '../shared/directives/popover.directive';
import { PopoverHostDirective } from '../shared/directives/popover-host.directive';
import { PopoverTargetDirective } from '../shared/directives/popover-target.directive';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'dominion-prompt',
  standalone: true,
  imports: [
    CommonModule,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
    RouterLink,
  ],
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.css'],
})
export class PromptComponent {
  @Input() prompt: PromptText[];
  @Input() subprompt: boolean = false;
}

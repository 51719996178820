import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IAssignDominionToCompanyDto,
  ICompanyReadFull,
  IDominionAssignables,
} from '@dominion/interfaces';
import { CompanyService } from '../../company/company.service';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';
import { AuthorizationService } from '../../auth/authorization.service';
import {
  DominionDeploymentManagerStrategy,
  DominionProjectManagerStrategy,
} from '@dominion/authorization';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';
import { ModalComponent } from '../../shared/modal/modal.component';

@Component({
  selector: 'dominion-team-member',
  standalone: true,
  imports: [CommonModule, SharedModule],
  template: `
    <div class="flex flex-col gap-1">
      <h2 class="text-dms-green text-xs font-medium uppercase">
        {{ title }}
      </h2>

      <div class="flex flex-col gap-0.5">
        <div class="text-dms-ultra-grey -ml-[5px] flex font-semibold">
          <dominion-dropdown-search
            [options]="options"
            [selectedOption]="selectedOption"
            viewType="editable"
            toggleText="Unassigned"
            toggleTextSize="text-md"
            togglePaddingX="px-1"
            togglePaddingY="py-0"
            [disabled]="disabled"
            (emitSelectedValue)="emitSelectedValue.emit($event)"
          ></dominion-dropdown-search>
        </div>

        @if (email) {
          <p class="text-sm text-gray-500">{{ email }}</p>
        }
      </div>
    </div>
  `,
  styles: [],
})
export class TeamMemberComponent {
  @Input() title!: string;
  @Input() options: any[] = [];
  @Input() selectedOption!: any;
  @Input() email?: string;
  @Input() disabled = false;
  @Output() emitSelectedValue = new EventEmitter<any>();
}

@Component({
  selector: 'dominion-company-team',
  templateUrl: './company-team.component.html',
  styleUrls: ['./company-team.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    InformationButtonComponent,
    TeamMemberComponent,
  ],
})
export class CompanyTeamComponent implements OnChanges, OnInit {
  @Input() company: ICompanyReadFull | undefined;
  @Input() internalView: boolean = false;

  @HostBinding('class') class = '';

  @ViewChild('teamModal') teamModal: ModalComponent;

  assignables: IDominionAssignables | undefined;
  assignableOptions: {
    projectmanagers: DropdownOption[];
    deploymentmanagers: DropdownOption[];
    deploymenttechnicians: DropdownOption[];
  } = {
    projectmanagers: [],
    deploymentmanagers: [],
    deploymenttechnicians: [],
  };
  isLoadingAssignables = false;
  isAssigningUser = false;

  // selected assignables
  selectedProjectManager: DropdownOption | undefined;
  selectedDeploymentManager: DropdownOption | undefined;
  selectedDeploymentTechnician: DropdownOption | undefined;

  // permissions
  canAssignProjectManager = false;
  canAssignDeploymentManager = false;
  canAssignDeploymentTechnician = false;

  constructor(
    private companyService: CompanyService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    this.authorizationService.authorizations.subscribe({
      next: (authorizations) => {
        this.initializePermissions();
      },
    });
  }

  initializePermissions() {
    if (!this.company) {
      return;
    }
    this.canAssignProjectManager = this.authorizationService.isAuthorized([
      new DominionProjectManagerStrategy(),
    ]);
    this.canAssignDeploymentManager = this.authorizationService.isAuthorized([
      new DominionProjectManagerStrategy(),
    ]);
    this.canAssignDeploymentTechnician = this.authorizationService.isAuthorized(
      [new DominionDeploymentManagerStrategy()],
    );
  }

  setSelectedAssignables() {
    if (!this.company) {
      return;
    }
    this.selectedProjectManager = this.assignableOptions.projectmanagers.find(
      (pm) => pm.value === this.company?.internalAssigned?.projectmanager?._id,
    );
    this.selectedDeploymentManager =
      this.assignableOptions.deploymentmanagers.find(
        (dm) =>
          dm.value === this.company?.internalAssigned?.deploymentmanager?._id,
      );
    this.selectedDeploymentTechnician =
      this.assignableOptions.deploymenttechnicians.find(
        (dt) =>
          dt.value ===
          this.company?.internalAssigned?.deploymenttechnician?._id,
      );
  }

  getAssignables() {
    this.isLoadingAssignables = true;
    this.companyService.getDominionAssignables().subscribe({
      next: (assignables) => {
        this.assignables = assignables;
        this.isLoadingAssignables = false;
        this.buildAssignableOptions();
        this.setSelectedAssignables();
      },
    });
  }

  buildAssignableOptions() {
    if (!this.assignables) {
      return;
    }
    this.assignableOptions.projectmanagers =
      this.assignables.projectmanagers.map((pm) => ({
        label: `${pm.firstName} ${pm.lastName}`,
        value: pm._id,
      }));
    this.assignableOptions.deploymentmanagers =
      this.assignables.deploymentmanagers.map((dm) => ({
        label: `${dm.firstName} ${dm.lastName}`,
        value: dm._id,
      }));
    this.assignableOptions.deploymenttechnicians =
      this.assignables.deploymenttechnicians.map((dt) => ({
        label: `${dt.firstName} ${dt.lastName}`,
        value: dt._id,
      }));
  }

  updateAssignedUser(
    option: DropdownOption,
    role: 'projectmanager' | 'deploymentmanager' | 'deploymenttechnician',
  ) {
    // update the company assigned user
    // update selected option
    // get the assignable based on id
    const assigned = this.assignables![
      role === 'deploymentmanager'
        ? 'deploymentmanagers'
        : role === 'deploymenttechnician'
          ? 'deploymenttechnicians'
          : 'projectmanagers'
    ].find((a) => a._id === option.value);
    if (!assigned) {
      return;
    }
    switch (role) {
      case 'projectmanager':
        this.company!.internalAssigned.projectmanager = assigned;
        break;
      case 'deploymentmanager':
        this.company!.internalAssigned.deploymentmanager = assigned;
        break;
      case 'deploymenttechnician':
        this.company!.internalAssigned.deploymenttechnician = assigned;
        break;
    }
    this.setSelectedAssignables();
  }

  assignProjectManager(option: DropdownOption) {
    const dto: IAssignDominionToCompanyDto = {
      userId: option.value as string,
      companyId: this.company!._id,
    };
    this.isAssigningUser = true;
    this.companyService.assignInternalUserToCompany(dto).subscribe({
      next: () => {
        this.updateAssignedUser(option, 'projectmanager');
        this.isAssigningUser = false;
      },
    });
  }

  assignDeploymentManager(option: DropdownOption) {
    const dto: IAssignDominionToCompanyDto = {
      userId: option.value as string,
      companyId: this.company!._id,
    };
    this.isAssigningUser = true;
    this.companyService.assignInternalUserToCompany(dto).subscribe({
      next: () => {
        this.updateAssignedUser(option, 'deploymentmanager');
        this.isAssigningUser = false;
      },
    });
  }

  assignDeploymentTechnician(option: DropdownOption) {
    const dto: IAssignDominionToCompanyDto = {
      userId: option.value as string,
      companyId: this.company!._id,
    };
    this.isAssigningUser = true;
    this.companyService.assignInternalUserToCompany(dto).subscribe({
      next: () => {
        this.updateAssignedUser(option, 'deploymenttechnician');
        this.isAssigningUser = false;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['company']) {
      this.getAssignables();
      this.initializePermissions();
    }
  }

  openTeamModal() {
    this.teamModal.open();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'dominion-standard-delete-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './standard-delete-buttons.component.html',
  styleUrls: ['./standard-delete-buttons.component.css'],
})
export class StandardDeleteButtonsComponent {
  @Output() emitDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitCancel: EventEmitter<void> = new EventEmitter<void>();

  @Input() paddingX: string = 'px-2';
  @Input() paddingY: string = 'py-1';
  @Input() maxRowWidth: string = 'max-w-sm';
}

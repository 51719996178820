import {
  AfterContentInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { createPopper, Instance } from '@popperjs/core';
import { DropdownToggleDirective } from './dropdown-toggle.directive';
import { DropdownMenuDirective } from './dropdown-menu.directive';

@Directive({
  selector: '[dominionDropdownHost]',
})
export class DropdownHostDirective implements AfterContentInit {
  /**
   * The Popper.js instance for the dropdown menu
   */
  popper: Instance | undefined;
  /**
   * Whether the dropdown menu is open or not
   */
  open = false;
  /**
   * The search input element
   */
  searchInput: HTMLInputElement | undefined;
  /**
   * The search form element
   */
  searchForm: HTMLFormElement | undefined;

  /**
   * Whether the dropdown is searchable or not
   */
  @Input() searchable: boolean = false;
  /**
   * Whether the dropdown is disabled or not
   */
  @Input() disabled: boolean = false;

  /**
   * Emits an event when the dropdown is opened
   */
  @Output() opened = new EventEmitter<void>();
  /**
   * Emits an event when the dropdown is closed
   */
  @Output() closed = new EventEmitter<void>();

  @ContentChild(DropdownToggleDirective, {
    read: ElementRef,
    descendants: true,
  })
  toggle: ElementRef<HTMLDivElement>;

  @ContentChild(DropdownMenuDirective, {
    read: ElementRef<HTMLDivElement>,
    descendants: true,
  })
  menu: ElementRef<HTMLDivElement>;

  constructor(private el: ElementRef) {}

  toggleMenu(event: Event) {
    event;
    if (this.disabled) {
      return;
    }
    if (!this.open) {
      setTimeout(() => {
        this.menu.nativeElement.setAttribute('data-show', '');
        this.popper!.update();
        this.open = true;
        this.opened.emit();
      });
    } else {
      this.hide();
    }
  }

  hide() {
    if (this.open) {
      this.menu.nativeElement.removeAttribute('data-show');
      this.open = false;
      this.closed.emit();
    }
  }

  /**
   * Use popper.js to create the menu popper
   */
  private createPopperEl() {
    this.popper = createPopper(
      this.toggle.nativeElement,
      this.menu.nativeElement,
    );
  }

  private addToggleClickListener() {
    this.toggle.nativeElement.addEventListener(
      'click',
      this.toggleMenu.bind(this),
    );
  }

  private stopSearchClickPropagation() {
    if (this.searchable) {
      this.searchInput = this.el.nativeElement.querySelector('input');
      this.searchForm = this.el.nativeElement.querySelector('form');
      if (!this.searchInput || !this.searchForm) {
        return;
      }
      this.searchInput.addEventListener('click', (event: Event) => {
        event.stopPropagation();
      });
      this.searchForm?.addEventListener('click', (event: Event) => {
        event.stopPropagation();
      });
    }
  }

  ngAfterContentInit(): void {
    this.createPopperEl();
    this.addToggleClickListener();
    this.stopSearchClickPropagation();
    // make dropdown close when clicking outside of search
    window.addEventListener('click', this.hide.bind(this));
  }
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-ellipsis',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .a {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title></title>
    <circle class="a" cx="3.375" cy="12.023" r="2.625" />
    <circle class="a" cx="20.625" cy="12.023" r="2.625" />
    <circle class="a" cx="12" cy="12.023" r="2.625" />
  </svg>`,
})
export class IconEllipsisComponent extends IconBase {}

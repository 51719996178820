import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/core-submodule.interfaces';

export type TServiceMaintenanceGroupIdentifiers =
  | 'groupBodyShopRO'
  | 'groupCustomerPayRO'
  | 'groupExtendedServiceRO'
  | 'groupInternalPayRO'
  | 'groupLaborRatesList'
  | 'groupOpCodes'
  | 'groupQuickLubeRO'
  | 'groupServiceDisclaimer'
  | 'groupServiceDiscounts'
  | 'groupServiceFees'
  | 'groupSkillSets'
  | 'groupCommonOpCodes'
  | 'groupConcerns'
  | 'groupWarrantyPayRO';

export type TServiceMaintenanceDataKeys =
  | 'hasBodyShop'
  | 'useDefaultServiceDisclaimer'
  | 'bodyShopRO'
  | 'hasQuickLube'
  | 'customerPayRO'
  | 'extendedServiceRO'
  | 'internalPayRO'
  | 'laborRatesList'
  | 'opCodes'
  | 'quickLubeRO'
  | 'serviceDisclaimer'
  | 'serviceDiscounts'
  | 'commonOpCodes'
  | 'concerns'
  | 'serviceFees'
  | 'usesSkillSets'
  | 'skillSets'
  | 'warrantyPayRO';

const serviceMaintenanceGroupMap = {
  moduleType: 'service',
  submoduleType: 'maintenance',
  discriminator: 'service-maintenance',
  groups: {
    groupConcerns: ['concerns'],
    groupCommonOpCodes: ['commonOpCodes'],
    groupBodyShopRO: ['hasBodyShop', 'bodyShopRO'],
    groupCustomerPayRO: ['customerPayRO'],
    groupExtendedServiceRO: ['extendedServiceRO'],
    groupInternalPayRO: ['internalPayRO'],
    groupLaborRatesList: ['laborRatesList'],
    groupOpCodes: ['opCodes'],
    groupQuickLubeRO: ['quickLubeRO', 'hasQuickLube'],
    groupServiceDisclaimer: [
      'useDefaultServiceDisclaimer',
      'serviceDisclaimer',
    ],
    groupServiceDiscounts: ['serviceDiscounts'],
    groupServiceFees: ['serviceFees'],
    groupSkillSets: ['usesSkillSets', 'skillSets'],
    groupWarrantyPayRO: ['warrantyPayRO'],
  },
} satisfies TSubmoduleGroupMap<
  TServiceMaintenanceGroupIdentifiers,
  TServiceMaintenanceDataKeys
>;

export type TServiceMaintenanceGroupMap = typeof serviceMaintenanceGroupMap;

export class ServiceMaintenanceData
  implements TDataDefinition<TServiceMaintenanceDataKeys>
{
  concerns = new DataInitializerBasic<IFileUploadReference[]>([]);
  commonOpCodes = new DataInitializerBasic<IFileUploadReference[]>([]);
  hasBodyShop = new DataInitializerBasic<boolean>();
  bodyShopRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  hasQuickLube = new DataInitializerBasic<boolean>();
  customerPayRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  extendedServiceRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  internalPayRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  laborRatesList = new DataInitializerBasic<IFileUploadReference[]>([]);
  opCodes = new DataInitializerBasic<IFileUploadReference[]>([]);
  quickLubeRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  serviceDisclaimer = new DataInitializerBasic<IFileUploadReference[]>([]);
  serviceDiscounts = new DataInitializerBasic<IFileUploadReference[]>([]);
  serviceFees = new DataInitializerBasic<IFileUploadReference[]>([]);
  skillSets = new DataInitializerBasic<IFileUploadReference[]>([]);
  warrantyPayRO = new DataInitializerBasic<IFileUploadReference[]>([]);
  useDefaultServiceDisclaimer = new DataInitializerBasic<boolean>();
  usesSkillSets = new DataInitializerBasic<boolean>();
}

export const serviceMaintenanceStructure: Structures['service-maintenance'] = {
  moduleType: 'service',
  submoduleType: 'maintenance',
  discriminator: 'service-maintenance',
  groups: {
    groupBodyShopRO: {
      groupLabel: 'Body Shop RO',
      groupUrl: 'groupBodyShopRO',
      groupType: 'standard',
      questions: {
        hasBodyShop: {
          questionKey: 'hasBodyShop',
          label: 'Do you have a body shop?',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have a body shop?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['bodyShopRO'],
            },
          ],
        },
        bodyShopRO: {
          questionKey: 'bodyShopRO',
          label: 'Body Shop RO',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'body shop pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each RO, please provide both ',
            },
            {
              attributes: { bold: true },
              text: 'customer and accounting copies. ',
            },
            {
              attributes: null,
              text: 'These should include various fees, discounts, and deductibles, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupQuickLubeRO: {
      groupLabel: 'Quick Lube RO',
      groupUrl: 'groupQuickLubeRO',
      groupType: 'standard',
      questions: {
        hasQuickLube: {
          questionKey: 'hasQuickLube',
          label: 'Do you have a quick lube?',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you have a quick lube?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['quickLubeRO'],
            },
          ],
        },
        quickLubeRO: {
          questionKey: 'quickLubeRO',
          label: 'Quick Lube RO',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'quick lube pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each RO, please provide both ',
            },
            {
              attributes: { bold: true },
              text: 'customer and accounting copies. ',
            },
            {
              attributes: null,
              text: 'These should include various fees, discounts, and deductibles, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupCustomerPayRO: {
      groupLabel: 'Customer Pay RO',
      groupUrl: 'groupCustomerPayRO',
      groupType: 'standard',
      questions: {
        customerPayRO: {
          questionKey: 'customerPayRO',
          label: 'Customer Pay RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'customer pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each RO, please provide both ',
            },
            {
              attributes: { bold: true },
              text: 'customer and accounting copies. ',
            },
            {
              attributes: null,
              text: 'These should include various fees, discounts, and deductibles, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupExtendedServiceRO: {
      groupLabel: 'Extended Service RO',
      groupUrl: 'groupExtendedServiceRO',
      groupType: 'standard',
      questions: {
        extendedServiceRO: {
          questionKey: 'extendedServiceRO',
          label: 'Extended Service RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'extended service pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each RO, please provide both ',
            },
            {
              attributes: { bold: true },
              text: 'customer and accounting copies. ',
            },
            {
              attributes: null,
              text: 'These should include various fees, discounts, and deductibles, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupInternalPayRO: {
      groupLabel: 'Internal Pay RO',
      groupUrl: 'groupInternalPayRO',
      groupType: 'standard',
      questions: {
        internalPayRO: {
          questionKey: 'internalPayRO',
          label: 'Internal Pay RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'internal pay.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each RO, please provide both ',
            },
            {
              attributes: { bold: true },
              text: 'customer and accounting copies. ',
            },
            {
              attributes: null,
              text: 'These should include various fees, discounts, and deductibles, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupWarrantyPayRO: {
      groupLabel: 'Warranty Pay RO',
      groupUrl: 'groupWarrantyPayRO',
      groupType: 'standard',
      questions: {
        warrantyPayRO: {
          questionKey: 'warrantyPayRO',
          label: 'Warranty Pay RO',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide 3 examples of closed repair orders using ',
            },
            {
              attributes: { bold: true },
              text: 'warranty pay. ',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each RO, please provide both ',
            },
            {
              attributes: { bold: true },
              text: 'customer and accounting copies. ',
            },
            {
              attributes: null,
              text: 'These should include various fees, discounts, and deductibles, if possible.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupLaborRatesList: {
      groupLabel: 'Labor Rates List',
      groupUrl: 'groupLaborRatesList',
      groupType: 'standard',
      questions: {
        laborRatesList: {
          questionKey: 'laborRatesList',
          label: 'Labor Rates List',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your labor rates.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each labor rate, please provide:\n    \u2022 the code\n    \u2022 description\n    \u2022 the rate\n    \u2022 copy of matrix pricing, if applicable',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupOpCodes: {
      groupLabel: 'Op Codes',
      groupUrl: 'groupOpCodes',
      groupType: 'standard',
      questions: {
        opCodes: {
          questionKey: 'opCodes',
          label: 'Op Codes',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your op codes.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each op code, please provide:\n    \u2022 the op code\n    \u2022 description\n    \u2022 concern/cause/correction or trouble/condition\n    \u2022 labor hours\n    \u2022 rate\n    \u2022 amount',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupCommonOpCodes: {
      groupLabel: 'Common Op Codes',
      groupUrl: 'groupCommonOpCodes',
      groupType: 'standard',
      questions: {
        commonOpCodes: {
          questionKey: 'commonOpCodes',
          label: 'Common Op Codes',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your top 10 most common op codes.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'VUE uses this top-10 list to enable you to "quick add" op codes.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupConcerns: {
      groupLabel: 'Standard Concerns',
      groupUrl: 'groupConcerns',
      groupType: 'standard',
      questions: {
        concerns: {
          questionKey: 'concerns',
          label: 'Standard Concerns',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your standard concerns/causes/corrections.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'These might be called "trouble/condition" codes in your retiring DMS. For each item, please provide:\n    \u2022 the code\n    \u2022 the type (example: concern or cause)\n    \u2022 the description\n\nExamples:\n\nCode: C\nType: Cause/Correction\nDescription: Completed\n\nCode: NS\nType: Concern\nDescription: Customer states vehicle will not start',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDisclaimer: {
      groupLabel: 'Service Disclaimer',
      groupUrl: 'groupServiceDisclaimer',
      groupType: 'standard',
      questions: {
        useDefaultServiceDisclaimer: {
          questionKey: 'useDefaultServiceDisclaimer',
          label: 'Use Default Service Disclaimer',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Do you want to use VUE's default service invoice disclaimer?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: "VUE's default disclaimer is:\n\nI hereby authorize the repair work herein set forth to be done along with the necessary material and agree that you are not responsible for loss or damage to vehicle or articles left in the vehicle in case of fire, theft, or any other cause beyond your control or for any delays caused by the unavailability of parts or delays in parts shipments by the supplier or transporter. I hereby grant you and/or your employee’s permission to operate the vehicle herein described on streets, highways, or elsewhere for the purpose of testing and/or inspection. An express mechanic's lien is hereby acknowledged on the vehicle to secure the number of repairs thereto.\n\nALL REPAIRS SUBJECT TO A MINIMUM OF .5 HOUR DIAGNOSTIC TIME.\n\nDISCLAIMER OF WARRANTIES: All warranties on this product are the manufacturers. (Insert Dealership Name) hereby expressly disclaims all warranties either expressed or implied, including any implied warranty of merchantability or fitness for a particular purpose and (Insert Dealership Name) neither assumes nor authorizes any other person to assume for it any liability in connection with the sale of the product. This disclaimer by (Insert Dealership Name) in no way affects the terms of the manufacturer's warranty.\n\nAll parts installed are new/salvaged or reconditioned parts.\n\nTERMS: STRICTLY CASH unless arrangements are made.",
            },
          ],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['serviceDisclaimer'],
            },
          ],
        },
        serviceDisclaimer: {
          questionKey: 'serviceDisclaimer',
          label: 'Service Disclaimer',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide the disclaimer that should print on your ROs/work orders.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'If you use separate disclaimers for ROs and work orders, please upload either two separate files or one file with each disclaimer clearly marked.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceDiscounts: {
      groupLabel: 'Service Discounts',
      groupUrl: 'groupServiceDiscounts',
      groupType: 'standard',
      questions: {
        serviceDiscounts: {
          questionKey: 'serviceDiscounts',
          label: 'Service Discounts',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of the most common discounts that you apply to your service invoices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each discount, please provide:\n    \u2022 the code\n    \u2022 description\n    \u2022 and whether the discount is shared with the\n       parts department',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupServiceFees: {
      groupLabel: 'Service Fees',
      groupUrl: 'groupServiceFees',
      groupType: 'standard',
      questions: {
        serviceFees: {
          questionKey: 'serviceFees',
          label: 'Service Fees',
          componentType: 'file',
          requirement: 'required',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of the most common fees that you apply to your service invoices.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each fee, please provide:\n    \u2022 the code\n    \u2022 description\n    \u2022 and whether the fee is shared with the\n       parts department',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupSkillSets: {
      groupLabel: 'Skill Sets',
      groupUrl: 'groupSkillSets',
      groupType: 'standard',
      questions: {
        usesSkillSets: {
          questionKey: 'usesSkillSets',
          label: 'Uses Skill Sets',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'Do you use skill sets?',
            },
          ],
          subprompt: [],
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['skillSets'],
            },
          ],
        },
        skillSets: {
          questionKey: 'skillSets',
          label: 'Skill Sets',
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: true,
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your skill sets.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each skill set, please provide:\n    \u2022 the skill set name\n    \u2022 rate levels\n\nExample:\n\nHVAC\nA/Advanced/$36.00\nB/Beginner/$28.00',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['service-maintenance'];

export type ServiceMaintenanceGroupRoutes =
  (typeof serviceMaintenanceStructure)['groups'][keyof (typeof serviceMaintenanceStructure)['groups']]['groupUrl'];

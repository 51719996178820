import {
  Component,
  Directive,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '../shared.module';
import { StateList, StateOptions } from '@dominion/interfaces';
import { DropdownOption } from '../dropdown-search/dropdown-search.component';
import { PopoverHostDirective } from '../directives/popover-host.directive';
import { DiscoveryNextBtnDirective } from '../directives/discovery-next-btn.directive';
import { PopoverDirective } from '../directives/popover.directive';
import { PopoverTargetDirective } from '../directives/popover-target.directive';

@Directive({
  selector: '[dominionAddressTextInput]',
  standalone: true,
  host: {
    class:
      'flex flex-auto mb-4 flex-row border border-dms-light-gray rounded p-1 outline-none text-center focus:border-dms-green focus:shadow-md placeholder:font-light',
  },
})
export class AddressTextInput {}

@Component({
  selector: 'dominion-address-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AddressTextInput,
    SharedModule,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    DiscoveryNextBtnDirective,
  ],
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.css'],
  host: {
    class: 'flex flex-row justify-center',
  },
})
export class AddressInputComponent {
  states = StateOptions;
  addressForm: FormGroup;
  selectedState: DropdownOption | null;

  @Input() currentValue: any;

  @ViewChild('zipHost', { read: PopoverHostDirective })
  zipHost: PopoverHostDirective;
  @ViewChild('stateHost', { read: PopoverHostDirective })
  stateHost: PopoverHostDirective;
  @ViewChild('cityHost', { read: PopoverHostDirective })
  cityHost: PopoverHostDirective;
  @ViewChild('streetHost', { read: PopoverHostDirective })
  streetHost: PopoverHostDirective;

  @Output() emitSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {
    this.addressForm = this.fb.group({
      street: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentValue']) {
      this.setCurrentValue();
    }
  }

  setCurrentValue() {
    if (this.currentValue) {
      this.addressForm.patchValue(this.currentValue);
      const state = this.states.find(
        (state) => state.value === this.currentValue.state,
      );
      if (state) {
        this.selectedState = state;
      }
    }
  }

  checkStreetErr() {
    const isInvalid =
      this.addressForm.get('street')!.invalid &&
      this.addressForm.get('street')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.streetHost.show();
    } else {
      this.streetHost.hide();
    }
  }

  checkCityErr() {
    const isInvalid =
      this.addressForm.get('city')!.invalid &&
      this.addressForm.get('city')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.cityHost.show();
    } else {
      this.cityHost.hide();
    }
  }

  getStateErr() {
    const isInvalid =
      this.addressForm.get('state')!.invalid &&
      this.addressForm.get('state')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.stateHost.show();
    } else {
      this.stateHost.hide();
    }
  }

  checkZipErr() {
    const isInvalid =
      this.addressForm.get('zip')!.invalid &&
      this.addressForm.get('zip')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.zipHost.show();
    } else {
      this.zipHost.hide();
    }
  }

  stateSelected(selection: DropdownOption) {
    this.addressForm.get('state')!.setValue(selection.value);
    this.selectedState = selection;
  }

  stateClosed() {
    this.addressForm.get('state')!.markAsTouched();
    this.getStateErr();
  }

  submit() {
    this.emitSubmit.emit(this.addressForm.value);
  }

  ngOnInit(): void {}
}

<dominion-auth-root
  heading="Register for VOX"
  subheading="Internal user registration"
>
  @if (this.error) {
    <div
      class="mx-auto mb-6 flex w-full max-w-sm justify-center rounded border border-red-200 bg-red-50 p-2 text-sm text-red-700 shadow-md shadow-red-700/10"
      data-testid="registerForm-gen-err"
    >
      {{ this.error.message }}
    </div>
  }

  <form
    class="grid w-full grid-cols-1 gap-5 sm:grid-cols-2"
    [formGroup]="registerForm"
    (ngSubmit)="register()"
  >
    <!-- FIRST NAME -->
    <div class="flex flex-col gap-1.5">
      <label for="firstName" class="form__label">First Name</label>
      <input
        id="firstName"
        class="form__control"
        type="text"
        placeholder="Claire"
        formControlName="firstName"
        data-testid="registerForm-first"
        required
      />
      @if (
        this.registerForm.get('firstName')!.invalid &&
        this.registerForm.get('firstName')!.touched
      ) {
        <div class="form__error" data-testid="registerForm-first-err">
          Please enter your first name.
        </div>
      }
    </div>

    <!-- LAST NAME -->
    <div class="flex flex-col gap-1.5">
      <label for="lastName" class="form__label">Last Name</label>
      <input
        id="lastName"
        class="form__control"
        type="text"
        placeholder="Voyant"
        formControlName="lastName"
        data-testid="registerForm-last"
        required
      />
      @if (
        this.registerForm.get('lastName')!.invalid &&
        this.registerForm.get('lastName')!.touched
      ) {
        <div class="form__error" data-testid="registerForm-last-err">
          Please enter your last name.
        </div>
      }
    </div>

    <!-- EMAIL -->
    <div class="flex flex-col gap-1.5 sm:col-span-2">
      <label for="email" class="form__label">Email</label>
      <input
        id="email"
        class="form__control"
        type="email"
        placeholder="claire@dominiondms.com"
        formControlName="email"
        data-testid="registerForm-email"
        required
      />
      @if (
        this.registerForm.get('email')!.errors?.['pattern'] ||
        ((this.registerForm.get('email')!.errors?.['email'] ||
          this.registerForm.get('email')!.errors?.['required']) &&
          this.registerForm.get('email')!.touched)
      ) {
        <div class="form__error" data-testid="registerForm-email-err-invalid">
          Please enter a valid email address.
        </div>
      }
      @if (
        this.registerForm.get('email')!.errors?.['pattern'] &&
        this.registerForm.get('email')!.touched
      ) {
        <div class="form__error" data-testid="registerForm-email-err-domain">
          You must use an "&#64;dominiondms.com" email address to register here.
        </div>
      }
    </div>

    <!-- JOB TITLE -->
    <div class="flex flex-col gap-1.5">
      <label for="jobTitle" class="form__label">Job Title</label>
      <input
        id="jobTitle"
        class="form__control"
        type="text"
        placeholder="Sales Manager"
        formControlName="jobTitle"
        id="jobTitle"
        name="jobTitle"
        data-testid="registerForm-jobTitle"
      />
    </div>

    <!-- DEPARTMENT -->
    <div class="flex flex-col gap-1.5">
      <label for="department" class="form__label">Department</label>
      <select
        id="department"
        class="form__control"
        formControlName="department"
        data-testid="registerForm-department"
      >
        <option value="" disabled selected>Select your department</option>
        <option value="sales" data-testid="registerForm-department-sales">
          Sales
        </option>
        <option value="pm">Project Management</option>
        <option value="deployment">Deployment</option>
        <option value="implementation">Implementation</option>
        <option value="support">Support</option>
        <option value="other">Other</option>
      </select>
      @if (
        this.registerForm.get('department')!.invalid &&
        this.registerForm.get('department')!.touched
      ) {
        <div class="form__error" data-testid="registerForm-department-err">
          Please select your department.
        </div>
      }
    </div>

    <!-- PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="password" class="form__label flex items-center gap-1">
        Password
        <dominion-information-button
          [strokeColor]="'stroke-gray-600 hover:stroke-white'"
        >
          <div class="w-64 p-2">
            <p>
              Password must be at least 8 characters long, include at least one
              lowercase letter, one uppercase letter, one digit, and one special
              character (# ? ! &#64; $ % ^ & * -).
            </p>
          </div>
        </dominion-information-button>
      </label>
      <div class="form__control">
        <input
          id="password"
          #password
          type="password"
          formControlName="password"
          data-testid="registerForm-password"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          class="absolute right-2"
          tabindex="-1"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>
      @if (
        registerForm.get('password')?.invalid &&
        registerForm.get('password')?.touched
      ) {
        <div class="form__error" data-testid="registerForm-password-err">
          {{ getPasswordErrorMessage() }}
        </div>
      }
    </div>

    <!-- CONFIRM PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="confirmPassword" class="form__label">Confirm Password</label>
      <div class="form__control">
        <input
          id="confirmPassword"
          #confirm
          type="password"
          formControlName="confirmPassword"
          data-testid="registerForm-confirmPassword"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          class="absolute right-2"
          tabindex="-1"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>

      @if (
        this.registerForm.errors?.['passwordMismatch'] &&
        this.registerForm.get('confirmPassword')!.touched
      ) {
        <div class="form__error" data-testid="registerForm-confirmPassword-err">
          Passwords must match.
        </div>
      }
    </div>

    <div class="sm:col-span-2">
      <!-- SUBMIT -->
      <button
        type="submit"
        class="disabled:bg-dms-light-grey bg-dms-teal h-10 w-full rounded px-2 text-sm text-white disabled:cursor-not-allowed disabled:text-gray-400"
        [disabled]="registerForm.invalid"
        data-testid="registerForm-submit"
      >
        Create Account
      </button>
    </div>
  </form>

  <div class="mt-10 flex w-full flex-col gap-1.5">
    <div>
      <a
        routerLink="/login"
        class="hover:text-dms-teal text-sm text-gray-500"
        data-testid="registerForm-login"
      >
        Already have an account? Sign in
      </a>
    </div>
  </div>
</dominion-auth-root>

<ngx-spinner
  name="spin"
  type="ball-clip-rotate-pulse"
  size="large"
  data-testid="registerForm-spinner"
>
  <p style="color: white" data-testid="spinner">Registering...</p>
</ngx-spinner>

import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { groupTitleAnimation } from '../animations/group-title.animation';
import { IconRightCaretComponent } from '../../icons/right-caret.component';
import { RouterLink } from '@angular/router';
import { ModuleTypePipe } from '../pipes/module-type.pipe';
import { SubmodulePhasePipe } from '../pipes/submodule-phase.pipe';
import { ModuleType, SubmoduleType } from '@dominion/interfaces';

@Component({
  selector: 'dominion-discovery-group-title',
  standalone: true,
  imports: [
    CommonModule,
    IconRightCaretComponent,
    RouterLink,
    ModuleTypePipe,
    SubmodulePhasePipe,
  ],
  templateUrl: './discovery-group-title.component.html',
  styleUrls: ['./discovery-group-title.component.css'],
  animations: [groupTitleAnimation],
})
export class DiscoveryGroupTitleComponent {
  @HostBinding('class') class =
    'flex flex-row px-4 py-2 sticky top-0 z-30 bg-white shadow-sm capitalize';
  @Input() module: ModuleType;
  @Input() submodule: SubmoduleType;
  @Input() group: string;
}

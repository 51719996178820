import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[dominionStandardFormSubmitBtn]',
})
export class StandardFormSubmitBtnDirective implements OnInit {
  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add(
      'flex',
      'justify-center',
      'items-center',
      'w-full',
      'bg-dms-green',
      'rounded-r',
      'text-white',
      'p-2',
      'text-center',
      'cursor-pointer',
      'hover:bg-dms-mid-green',
      'disabled:bg-dms-light-grey',
      'disabled:cursor-not-allowed',
      'disabled:text-gray-400',
      '[&.isLoading]:disabled:cursor-not-allowed',
      '[&.isLoading]:disabled:bg-dms-mid-green',
      '[&.isLoading]:disabled:text-white',
    );
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

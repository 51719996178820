import { CommonModule } from '@angular/common';
import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'dominion-question-container',
  templateUrl: './question-container.component.html',
  styleUrls: ['./question-container.component.css'],
  standalone: true,
  imports: [CommonModule],
  host: {
    class: 'flex flex-1 flex-col pt-16',
  },
})
export class QuestionContainerComponent implements DoCheck {
  active: boolean = false;

  constructor(private el: ElementRef<HTMLDivElement>) {}

  ngDoCheck(): void {
    if (this.el.nativeElement.nextElementSibling) {
      this.active = false;
    } else {
      this.active = true;
    }
  }
}

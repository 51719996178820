import { Inject, Injectable, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  IInvitedUser,
  IInvitedUserRegisterDto,
  IRequestUserPayload,
  IResetPasswordDto,
  IUserAuthorizations,
  IUserCreateDto,
  JwtAccessToken,
} from '@dominion/interfaces';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { BROWSER_STORAGE } from '../util/browser-storage.constant';
import { Router } from '@angular/router';
import { parse } from 'date-fns';

const apiBaseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: Subject<IRequestUserPayload | undefined> = new BehaviorSubject<
    IRequestUserPayload | undefined
  >(this.preInitialize());
  authorizations: Subject<IUserAuthorizations> =
    new BehaviorSubject<IUserAuthorizations>(undefined as any);

  public httpOptions:
    | {
        headers: HttpHeaders;
      }
    | undefined;

  public fileHttpOptions:
    | {
        headers: HttpHeaders;
        responseType: 'blob';
      }
    | undefined;

  constructor(
    private http: HttpClient,
    @Inject(BROWSER_STORAGE) private localStorage: Storage,
    private router: Router,
  ) {
    this.preInitialize();
  }

  /*
  TOKEN MANAGEMENT
  */

  saveToken(token: string) {
    this.localStorage.setItem('access_token', token);
  }

  getToken(): string | null {
    const token = this.localStorage.getItem('access_token');
    return token;
  }

  /*
  INITIALIZATION
  */

  preInitialize() {
    const token = this.getToken();
    if (token) {
      const userInfo = this.extractUserInfo(token);
      if (this.isTokenExpired(userInfo)) {
        this.logout();
        return;
      }
      this.setHttpOptions(token);
      this.getAuthorizations();
      return userInfo;
    }
    return;
  }

  isTokenExpired(userInfo: IRequestUserPayload): boolean {
    const exp = parse((userInfo as any).exp, 't', new Date());
    return exp < new Date();
  }

  // we check for a token immediately on user accessing the app
  // if has a token, then we initialize based on token
  initialize(): IRequestUserPayload | undefined {
    const token = this.getToken();
    if (token) {
      return this.initializeWithToken(token);
    }
    this.user.next(undefined);
    this.authorizations.next(undefined as any);
    return;
  }

  private initializeWithToken(token: string) {
    this.setHttpOptions(token);
    this.getAuthorizations(); // make sure to set HTTP options first, because the server relies on the token to get authorizations
    const userInfo = this.extractUserInfo(token);

    this.user.next(userInfo);
    return userInfo;
  }

  /*
  SET HTTP OPTIONS
  */

  setHttpOptions(token: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    this.fileHttpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
      responseType: 'blob',
    };
  }

  /*
  EXTRACT USER INFO
  */

  extractUserInfo(token: string): IRequestUserPayload {
    return JSON.parse(window.atob(token.split('.')[1]));
  }

  /*
  LOGOUT
  */

  logout() {
    this.localStorage.removeItem('access_token');
    this.httpOptions = undefined;
    if (this.user) {
      this.user.next(undefined);
    }
    if (this.authorizations) {
      this.authorizations.next(undefined as any);
    }
    this.router.navigateByUrl('login');
  }

  /*
  HTTP CALLS
  */

  // LOGIN
  login(dto: { email: string; password: string }): Observable<void> {
    return new Observable((subscriber: Subscriber<any>) => {
      this.http.post<JwtAccessToken>(`${apiBaseUrl}auth/login`, dto).subscribe({
        next: (token: JwtAccessToken) => {
          this.initializeWithToken(token.access_token);
          this.saveToken(token.access_token);
          subscriber.next();
        },
        error: (err: HttpErrorResponse) => {
          subscriber.error(err);
        },
        complete: () => {
          subscriber.complete();
        },
      });
    });
  }

  // GET AUTHORIZATIONS
  getAuthorizations(): void {
    this.http
      .get<IUserAuthorizations>(
        `${apiBaseUrl}auth/authorizations`,
        this.httpOptions,
      )
      .subscribe({
        next: (authorizations: IUserAuthorizations) => {
          this.authorizations.next(authorizations);
        },
        error: (err: HttpErrorResponse) => {},
      });
  }

  // REGISTER
  register(dto: IUserCreateDto): Observable<void> {
    return this.http.post<void>(`${apiBaseUrl}users`, dto);
  }

  // CONFIRM EMAIL
  confirmEmail(_id: string, code: string): Observable<void> {
    return this.http.get<void>(
      `${apiBaseUrl}auth/confirm-email/${_id}/${code}`,
    );
  }

  // RESEND CONFIRMATION EMAIL
  sendForgotPasswordRequest(email: string): Observable<void> {
    return this.http.post<void>(`${apiBaseUrl}auth/forgot-password`, { email });
  }

  // RESET PASSWORD
  sendResetPassword(dto: IResetPasswordDto): Observable<void> {
    return this.http.post<void>(`${apiBaseUrl}auth/reset-password`, dto);
  }

  // GET INVITED USER
  getInvitedUser(id: string): Observable<IInvitedUser> {
    return this.http.get<IInvitedUser>(`${apiBaseUrl}users/invite/${id}`);
  }

  // REGISTER INVITED USER
  registerInvitedUser(dto: IInvitedUserRegisterDto): Observable<void> {
    return this.http.post<void>(`${apiBaseUrl}users/invite-registration`, dto);
  }
}

<aside
  class="fixed inset-0 z-[9999] flex items-center justify-center bg-black/60 p-3 backdrop-blur-md sm:hidden"
>
  <div class="text-center">
    <p class="p-3 text-white">
      For optimal performance, please open VOX in Google Chrome on a desktop
      computer.
    </p>
  </div>
</aside>

<div class="flex h-full w-full flex-col overflow-hidden sm:overflow-auto">
  <dominion-nav class="flex-none"></dominion-nav>
  <router-outlet class="hidden"></router-outlet>
  <div
    class="text-dms-teal border-dms-light-green/40 closed absolute left-[50%] top-2 z-50 min-h-16 w-80 -translate-x-[50%] overflow-hidden rounded-sm border bg-white text-xs shadow-xl transition-all duration-500 [&.closed]:-translate-y-[115%] [&.closed]:shadow-none"
    [ngClass]="{ closed: !showVersionNotification }"
  >
    <div class="bg-dms-light-green/10 flex h-full flex-col space-y-1 p-2">
      <div class="flex grow flex-row">
        <div class="">
          This version of VOX is outdated. Please refresh your page to get the
          latest version.
        </div>
        <div class="flex flex-none basis-3 cursor-pointer text-lg font-light">
          <dominion-icon-close (click)="dismissVersion()"></dominion-icon-close>
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <button
          class="bg-dms-teal rounded-sm p-1 text-white"
          (click)="refreshPage()"
        >
          Refresh
        </button>
      </div>
    </div>
  </div>
</div>

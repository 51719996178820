import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-company',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ fillColor }} {{ strokeColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .i-companies {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>Company</title>
    <line class="i-companies" x1="20.25" y1="3.75" x2="20.25" y2="23.25" />
    <line class="i-companies" x1="3.75" y1="3.75" x2="20.25" y2="3.75" />
    <line class="i-companies" x1="3.75" y1="23.25" x2="3.75" y2="3.75" />
    <line class="i-companies" x1="20.25" y1="23.25" x2="3.75" y2="23.25" />
    <polygon
      class="i-companies"
      points="20.25 3.75 3.75 3.75 5.25 0.75 18.75 0.75 20.25 3.75"
    />
    <line class="i-companies" x1="0.75" y1="23.25" x2="23.25" y2="23.25" />
    <path class="i-companies" d="M14.25,21a2.25,2.25,0,0,0-4.5,0v2.25h4.5Z" />
    <path class="i-companies" d="M7.5,6.375a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M7.125,6.75A.375.375,0,0,1,7.5,6.375" />
    <path class="i-companies" d="M7.5,7.125a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M7.875,6.75a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M7.5,10.875a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M7.125,11.25a.375.375,0,0,1,.375-.375" />
    <path class="i-companies" d="M7.5,11.625a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M7.875,11.25a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M7.5,15.375a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M7.125,15.75a.375.375,0,0,1,.375-.375" />
    <path class="i-companies" d="M7.5,16.125a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M7.875,15.75a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M12,6.375a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M11.625,6.75A.375.375,0,0,1,12,6.375" />
    <path class="i-companies" d="M12,7.125a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M12.375,6.75A.375.375,0,0,1,12,7.125" />
    <path class="i-companies" d="M12,10.875a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M11.625,11.25A.375.375,0,0,1,12,10.875" />
    <path class="i-companies" d="M12,11.625a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M12.375,11.25a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M12,15.375a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M11.625,15.75A.375.375,0,0,1,12,15.375" />
    <path class="i-companies" d="M12,16.125a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M12.375,15.75a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M16.5,6.375a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M16.125,6.75a.375.375,0,0,1,.375-.375" />
    <path class="i-companies" d="M16.5,7.125a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M16.875,6.75a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M16.5,10.875a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M16.125,11.25a.375.375,0,0,1,.375-.375" />
    <path class="i-companies" d="M16.5,11.625a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M16.875,11.25a.375.375,0,0,1-.375.375" />
    <path class="i-companies" d="M16.5,15.375a.375.375,0,0,1,.375.375" />
    <path class="i-companies" d="M16.125,15.75a.375.375,0,0,1,.375-.375" />
    <path class="i-companies" d="M16.5,16.125a.375.375,0,0,1-.375-.375" />
    <path class="i-companies" d="M16.875,15.75a.375.375,0,0,1-.375.375" />
  </svg>`,
})
export class IconCompanyComponent extends IconBase {}

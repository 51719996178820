<div
  class="cursor-pointer overflow-hidden {{ caretHeight }} {{ caretWidth }} {{
    fill
  }} {{ strokeWidth }} {{ stroke }} {{ hoverStroke }}"
>
  <svg
    dominionFlip
    [isFlipped]="isFlipped"
    id="Regular"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-drop-caret {
          stroke-linecap: round;
          stroke-linejoin: round;
          fill-rule: evenodd;
        }
      </style>
    </defs>
    <title>Expand</title>
    <path
      class="i-drop-caret"
      d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
    />
  </svg>
</div>

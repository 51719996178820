import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[dominionStandardTextInput]',
})
export class StandardTextInputDirective implements OnInit {
  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add(
      'w-full',
      'border',
      'border-dms-light-grey',
      'p-2',
      'rounded-sm',
      'text-center',
      'focus:outline-dms-green',
      'placeholder:font-light',
    );
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

<!-- Static Version -->
<!-- This is the non-editing version of the component -->
<div
  #static
  id="static"
  [title]="initialValue ? initialValue : defaultValue"
  (click)="!isEditing && activateEditing()"
  [class]="
    'flex max-w-full cursor-pointer items-center [&.disabled]:cursor-not-allowed [&.disabled]:border-transparent [&.disabled]:hover:border-transparent [&.placeholder]:italic [&.placeholder]:text-gray-500 ' +
    staticClasses
  "
  [ngClass]="{
    disabled: isDisabled,
    placeholder: !initialValue,
    'pointer-events-none invisible': isEditing
  }"
  data-testid="static"
>
  <span class="inline-block max-w-full truncate">
    {{ initialValue ? initialValue : defaultValue }}
  </span>
</div>

<!-- Dynamic Version -->
<!-- This is the editing version of the component -->
@if (isEditing) {
  <div
    #dynamic
    dominionPopoverHost
    [class]="
      'absolute left-0 top-0 z-10 flex grow items-center bg-white/90 shadow-md backdrop-blur-sm supports-[backdrop-filter]:bg-white/80 ' +
      dynamicClasses
    "
    [ngClass]="{
      'min-w-[max(80px,120%)] max-w-full': type !== 'date'
    }"
    [formGroup]="form"
  >
    <input
      #inputEl
      dominionPopoverTarget
      [type]="type"
      formControlName="text"
      [placeholder]="initialValue ? initialValue : defaultValue"
      class="flex max-w-full bg-transparent outline-none"
      (blur)="save()"
      (keyup)="keyup($event)"
    />
    <div dominionPopover popoverTextColor="text-red-700">
      @if (isFormErr) {
        {{ localErrMsg }}
      } @else if (isServerErr) {
        {{ serverErrMsg }}
      }
    </div>
  </div>
}

import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';
import { OptionToggleComponent } from '../../shared/option-toggle/option-toggle.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { InvitedUsersTableComponent } from './invited-users-table/invited-users-table.component';

@Component({
  selector: 'dominion-internal-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css'],
  standalone: true,
  imports: [
    SharedModule,
    UsersTableComponent,
    InvitedUsersTableComponent,
    OptionToggleComponent,
  ],
})
export class AdminUsersComponent {
  public selectedView: DropdownOption = {
    label: 'Active Users',
    value: 'active',
  };

  setSelectedView(view: DropdownOption) {
    this.selectedView = view;
  }
}

import { Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[dominionPopoverTarget]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(mouseenter)': 'mouseEnter()',
    '(mouseleave)': 'mouseLeave()',
    '(click)': 'click()',
  },
})
export class PopoverTargetDirective {
  @Output() mouseEntered: EventEmitter<void> = new EventEmitter();
  @Output() mouseLeft: EventEmitter<void> = new EventEmitter();
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  constructor() {}

  mouseEnter() {
    this.mouseEntered.emit();
  }

  mouseLeave() {
    this.mouseLeft.emit();
  }

  click() {
    this.clicked.emit();
  }
}

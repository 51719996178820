import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@dominion/interfaces';
import { findOption } from '@dominion/interfaces';

/**
 * Returns the select option associated with the given value.
 *
 * @param value
 * @param options
 * @param config
 * @returns The select option if `config` is set to `'option'`, the label if `config` is set to `'label'` or not set
 */
@Pipe({
  name: 'getSelectOption',
  standalone: true,
})
export class GetSelectOptionPipe<T extends string | number | boolean>
  implements PipeTransform
{
  transform(
    value: T,
    options: SelectOption<T>[],
    config?: 'option' | 'label',
  ): string | SelectOption<T> | null {
    const option = findOption(options, value);
    if (!option) {
      return null;
    }
    return config === 'option' ? option : option.label;
  }
}

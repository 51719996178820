import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-home',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-dashboard {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>Home</title>
    <path
      class="i-dashboard"
      d="M22.868,8.947,12,.747,1.122,8.947a1.177,1.177,0,0,0-.377.8V22.269a.981.981,0,0,0,.978.978H8.245V18a3.75,3.75,0,0,1,7.5,0v5.25h6.521a.982.982,0,0,0,.979-.978V9.747A1.181,1.181,0,0,0,22.868,8.947Z"
    />
  </svg>`,
})
export class IconHomeComponent extends IconBase {}

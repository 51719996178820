import { Component, Input } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-check-circle',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeWidth }} {{ strokeColor }} {{ fillColor }}"
  >
    <defs>
      <style>
        .a {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title></title>
    <circle
      class="a {{ circleStrokeColor }} {{ circleFillColor }}"
      cx="12"
      cy="11.999"
      r="11.25"
    />
    <path
      class="a {{ checkStrokeColor }}"
      d="M6,13.223,8.45,16.7a1.049,1.049,0,0,0,1.707.051L18,6.828"
    />
  </svg>`,
})
export class IconCheckCircleComponent extends IconBase {
  @Input() circleFillColor: string = 'fill-dms-green';
  @Input() circleStrokeColor: string = 'stroke-dms-green';
  @Input() checkStrokeColor: string = 'stroke-gray-50';
}

<div
  dominionPopoverHost
  [hoverTrigger]="true"
  [placement]="'top'"
  class="h-full w-full"
>
  <div
    dominionPopoverTarget
    class="flex flex-row flex-1 h-full overflow-hidden {{ rounded }} {{
      bgColor
    }}"
  >
    <div
      [style.width]="percentage + '%'"
      class="flex h-full flex-none {{ completionColor }}"
    ></div>
    <div dominionPopover>{{ percentage }}% Complete</div>
  </div>
</div>

import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[dominionPopover]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class:
      "[&>div]:data-[popper-placement^='bottom']:-top-[5px] [&>div]:data-[popper-placement^='bottom']:before:border-l [&>div]:data-[popper-placement^='bottom']:before:border-t [&>div]:data-[popper-placement^='bottom']:before:-translate-x-1 [&>div]:data-[popper-placement^='top']:bottom-[3px] [&>div]:data-[popper-placement^='top']:before:border-r [&>div]:data-[popper-placement^='top']:before:border-b [&>div]:data-[popper-placement^='top']:before:-translate-x-1 hidden z-20 data-[show]:block [&>div]:data-[popper-placement^='right']:-right-[5px] [&>div]:data-[popper-placement^='right']:before:border-r [&>div]:data-[popper-placement^='right']:before:border-b [&>div]:data-[popper-placement^='right']:before:-translate-y-1 shadow-xl",
  },
})
export class PopoverDirective implements AfterContentInit {
  @Input() popoverTextSize: string = 'text-sm';
  @Input() popoverBgColor: string = 'bg-white';
  @Input() popoverTextColor: string = 'text-dms-dark-grey';
  @Input() popoverBorder: string = 'border';
  @Input() popoverBorderColor: string = 'border-dms-light-grey';
  @Input() popoverPadding: string = 'p-2';
  @Input() popoverShadow: string = 'shadow';
  @Input() popoverRounded: string = 'rounded-sm';
  @Input() popoverMaxWidth: string = 'max-w-[300px]';
  @Input() dark: boolean = false;

  constructor(private el: ElementRef) {}

  applyClasses() {
    this.el.nativeElement.classList.add(
      this.popoverTextSize,
      this.popoverBgColor,
      this.popoverBorder,
      this.popoverBorderColor,
      this.popoverPadding,
      this.popoverShadow,
      this.popoverRounded,
      this.popoverTextColor,
      'text-sm',
      'font-normal',
    );
  }

  addArrow() {
    const arrow = document.createElement('div');
    arrow.classList.add(
      'invisible',
      'before:visible',
      'before:absolute',
      'before:w-2',
      'before:h-2',
      'before:rotate-45',
      this.dark ? 'before:bg-dms-teal' : 'before:bg-white',
    );
    arrow.setAttribute('data-popper-arrow', '');
    this.el.nativeElement.appendChild(arrow);
  }

  ngAfterContentInit(): void {
    this.applyClasses();
    this.addArrow();
  }
}

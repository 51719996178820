import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { IconLockLockedComponent } from '../../icons/icon-lock-locked.component';

export type ProgressStatus = 'not-started' | 'in-progress' | 'complete';

@Component({
  selector: 'dominion-enter-module-btn',
  standalone: true,
  imports: [CommonModule, IconGoToComponent, IconLockLockedComponent],
  templateUrl: './enter-module-btn.component.html',
  styleUrls: ['./enter-module-btn.component.css'],
})
export class EnterModuleBtnComponent implements OnChanges {
  @Input() completed: number;
  @Input() total: number;
  @Input() view: 'internal' | 'external' = 'external';
  @Input() isAvailable: boolean = true;

  buttonText: string = 'Start';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['completed'] || changes['total']) {
      this.getText();
    }
  }

  getText() {
    if (this.view === 'internal') {
      this.buttonText = 'Open';
    } else {
      const diff = this.total - this.completed;
      if (!this.isAvailable) {
        this.buttonText = 'Unavailable';
        return;
      }
      if (diff === 0) {
        this.buttonText = 'Revisit';
      } else if (diff === this.total) {
        this.buttonText = 'Start';
      } else {
        this.buttonText = 'Continue';
      }
    }
  }
}

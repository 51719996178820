@for (navItem of navItems; track navItem.route) {
  <a
    [routerLink]="navItem.route"
    routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
    class="hover:bg-dms-light-grey flex cursor-pointer flex-col items-center gap-0.5 p-2 lg:flex-row lg:gap-2"
  >
    <div icon class="relative h-7 w-7 rounded-sm p-1">
      <ng-container *ngComponentOutlet="navItem.icon"></ng-container>
    </div>
    <span class="text-[10px] font-medium lg:text-base lg:font-normal">{{
      navItem.label
    }}</span>
  </a>
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'dominion-standard-form-submit',
  templateUrl: './standard-form-submit.component.html',
  styleUrls: ['./standard-form-submit.component.css'],
})
export class StandardFormSubmitComponent {
  @Input() text: string = 'Save';
  @Input() isLoading: boolean = false;
  @Input() disabled: boolean = false;

  constructor() {}
}

import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[dominionFlip]',
  standalone: true,
  host: {
    class: 'transition-all duration-750 ease-in-out [&.flip]:rotate-180',
    '[class.flip]': 'isFlipped',
  },
})
export class FlipDirective {
  @Input() isFlipped = false;

  constructor() {}
}

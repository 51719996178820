import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { AuthenticationService } from './authentication.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InviteRegistrationComponent } from './invite-registration/invite-registration.component';
import { IconEyeOpenComponent } from '../icons/icon-eye-open.component';
import { IconEyeClosedComponent } from '../icons/icon-eye-closed.component';
import { AuthRootComponent } from './auth-root/auth-root.component';
import { InformationButtonComponent } from '../shared/information-button/information-button.component';

@NgModule({
  declarations: [
    ConfirmEmailComponent,
    RegistrationSuccessComponent,
    LoginComponent,
    ForgotPasswordComponent,
    InviteRegistrationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterModule,
    AuthRootComponent,
    IconEyeOpenComponent,
    IconEyeClosedComponent,
    InformationButtonComponent,
  ],
  providers: [AuthenticationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { IDiscoveryDataProperty, ResponseOptions } from '@dominion/interfaces';
import { SharedModule } from '../../shared/shared.module';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { ModalComponent } from '../../shared/modal/modal.component';
import { LogComponent } from '../../log/log.component';

@Component({
  selector: 'dominion-input-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    DiscoveryNextBtnDirective,
    LogComponent,
  ],
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.css'],
})
export class InputDropdownComponent
  implements ResponseInput, OnInit, OnChanges
{
  @Input({ required: true }) data: IDiscoveryDataProperty<unknown>;
  get log() {
    return this.data?.log ? this.data.log : [];
  }
  @Input({ required: true }) options: ResponseOptions[];

  @Output() response: EventEmitter<unknown> = new EventEmitter<unknown>();

  @ViewChild('logModal') logModal: ModalComponent;

  selectedOption: ResponseOptions | undefined | null;

  constructor() {}

  ngOnInit(): void {
    this.selectedOption = this.options.find(
      (option) => option.value === this.data.value,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.selectedOption = this.options.find(
        (option) => option.value === changes['data'].currentValue?.value,
      );
    }
  }

  selectResponse(option: ResponseOptions) {
    this.selectedOption = option;
  }

  saveResponse() {
    if (this.selectedOption) {
      this.response.emit(this.selectedOption.value);
    }
  }

  handleErr(err: string): void {
    console.error(err);
  }

  handleSuccess(): void {
    return;
  }
}

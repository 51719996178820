<div @firstQuestion class="mt-12 flex flex-1 flex-col">
  <!-- PROMPT -->
  <div class="mb-8 flex flex-row justify-center text-2xl">
    Which vendors do you plan to use for the following services?
  </div>
  <div class="mb-12 flex flex-row justify-center text-lg text-gray-700">
    <div class="max-w-[350px] text-center">
      Please select <strong>None</strong> if you do not plan to use a vendor for
      a particular service.
    </div>
  </div>

  <div class="flex flex-row justify-center">
    <div
      class="border-dms-light-grey flex max-w-3xl flex-1 flex-col rounded border [&>*:nth-child(even)]:bg-gray-50"
    >
      <div
        *ngFor="let question of vendorQuestions"
        class="flex flex-row items-center p-2"
      >
        <div class="flex flex-1 basis-1/2 text-lg font-semibold">
          {{ question.label }}
        </div>
        <div class="flex basis-1/2">
          <dominion-dropdown-search
            class="flex-1"
            [options]="question.vendorOptions ? question.vendorOptions : []"
            [createNewLabel]="'Add a Vendor'"
            [enableCreateNew]="true"
            [toggleTextSize]="'text-lg'"
            [viewType]="'form'"
            [ring]="true"
            [dropdownCaret]="true"
            (emitCreateNew)="openAddVendorModal(question.questionKey)"
            (emitSelectedValue)="
              saveVendorResponse(question.questionKey, $event)
            "
            [selectedOption]="
              selectedOptions[question.questionKey]
                ? selectedOptions[question.questionKey]
                : undefined
            "
            [toggleText]="'Select an Option'"
          ></dominion-dropdown-search>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-12 flex flex-row justify-center">
    <button dominionDiscoveryNextBtn type="button" [routerLink]="['../../../']">
      Next
    </button>
  </div>

  <dominion-modal
    #addVendorModal
    title="Add Vendor"
    (emitClosed)="addVendorForm.reset()"
  >
    <form
      body
      [formGroup]="addVendorForm"
      (ngSubmit)="addVendor()"
      class="flex w-full flex-col gap-6"
    >
      <dominion-form-input
        ngDefaultControl
        id="providerName"
        label="Vendor Name"
        placeholder="Vision Dealer Solutions"
        [formGroup]="addVendorForm"
        formControlName="providerName"
        [required]="true"
        errorStyle="below"
        data-testid="addVendorForm-providerName"
      ></dominion-form-input>

      <dominion-form-input
        ngDefaultControl
        id="appName"
        label="Application Name"
        placeholder="VisionMenu"
        [formGroup]="addVendorForm"
        formControlName="appName"
        [required]="false"
        errorStyle="below"
        data-testid="addVendorForm-appName"
      ></dominion-form-input>

      <div class="flex items-center justify-end gap-4">
        <button
          type="button"
          (click)="closeAddVendorModal()"
          class="h-9 rounded border bg-gray-50 px-3 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-950"
        >
          Cancel
        </button>

        <button
          type="submit"
          class="bg-dms-teal flex h-9 items-center gap-2 rounded px-3 text-sm text-white disabled:cursor-not-allowed"
          [ngClass]="{
            'disabled:bg-dms-mid-green disabled:text-white': isAddingVendor,
            'disabled:bg-dms-light-grey disabled:text-gray-400': !isAddingVendor
          }"
          [disabled]="!addVendorForm.valid"
        >
          @if (isAddingVendor) {
            <dominion-load-spinner
              class="h-4 w-4 fill-none stroke-current"
            ></dominion-load-spinner>
          } @else {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              class="h-4 w-4"
            >
              <path
                d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z"
              />
            </svg>
          }
          <span class="pr-1">Add Vendor</span>
        </button>
      </div>
    </form>
  </dominion-modal>
</div>

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-user',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-user {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>User</title>
    <path
      class="i-user"
      d="M12.138,5.25S10.7,8.847,7.107,8.995a5.25,5.25,0,1,0,10.062,0C13.571,8.847,12.138,5.25,12.138,5.25Z"
    />
    <path
      class="i-user"
      d="M17.974,19.026a14.584,14.584,0,0,0,3.391-1.006,1.5,1.5,0,0,0,.762-1.961l-1.375-3.21a4.5,4.5,0,0,1-.364-1.773V9a8.25,8.25,0,0,0-16.5,0v2.076a4.5,4.5,0,0,1-.364,1.773l-1.376,3.21A1.5,1.5,0,0,0,2.91,18.02,14.611,14.611,0,0,0,6.3,19.026"
    />
    <path class="i-user" d="M21.614,23.25a10.483,10.483,0,0,0-18.952,0" />
    <path
      class="i-user"
      d="M16.422,18.164c-1.284,2.086-4.284,2.086-4.284,5.086,0-3-3-3-4.284-5.086"
    />
  </svg>`,
})
export class IconUserComponent extends IconBase {}

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DiscoverySideNavComponent } from '../discovery-side-nav/discovery-side-nav.component';
import { ModuleIdService } from '../services/module-id.service';

@Component({
  selector: 'dominion-discovery-root',
  templateUrl: './discovery-root.component.html',
  standalone: true,
  imports: [RouterOutlet, CommonModule, DiscoverySideNavComponent],
  styleUrls: ['./discovery-root.component.css'],
})
export class DiscoveryRootComponent implements OnInit {
  moduleId: string | null = null;

  constructor(
    private moduleIdService: ModuleIdService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.moduleIdService.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
      this.cdr.detectChanges();
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreCreateBtnComponent } from './core-create-btn/core-create-btn.component';
import { LoadSpinnerComponent } from './load-spinner/load-spinner.component';
import { StandardFormSubmitComponent } from './standard-form-submit/standard-form-submit.component';
import { StandardFormSubmitBtnDirective } from './directives/standard-form-submit-btn.directive';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';
import { StandardFormSectionLabelDirective } from './directives/standard-form-section-label.directive';
import { StandardFormCancelBtnDirective } from './directives/standard-form-cancel-btn.directive';
import { StandardFormLabelDirective } from './directives/standard-form-label.directive';
import { StandardFormErrorDirective } from './directives/standard-form-error.directive';
import { StandardTextInputDirective } from './directives/standard-text-input.directive';
import { CoreOutletTitleDirective } from './directives/core-outlet-title.directive';
import { DropdownHostDirective } from './directives/dropdown-host.directive';
import { DropdownItemDirective } from './directives/dropdown-item.directive';
import { DropdownToggleDirective } from './directives/dropdown-toggle.directive';
import { DropdownMenuDirective } from './directives/dropdown-menu.directive';
import { DmsPipe } from './pipes/dms.pipe';
import { StatePipe } from './pipes/state.pipe';
import { StandardFormSelectDirective } from './directives/standard-form-select.directive';
import { TableNavComponent } from './table-nav/table-nav.component';
import { ModalComponent } from './modal/modal.component';
import { DropdownSearchComponent } from './dropdown-search/dropdown-search.component';
import { QuestionContainerComponent } from './question-container/question-container.component';
import { DiscoveryTextInputComponent } from './discovery-text-input/discovery-text-input.component';
import { DiscoveryButtonInputComponent } from './discovery-button-input/discovery-button-input.component';
import { EnterModuleBtnComponent } from './enter-module-btn/enter-module-btn.component';
import { ModuleNamePipe } from './pipes/module-name.pipe';
import { RouterModule } from '@angular/router';
import { PopoverDirective } from './directives/popover.directive';
import { PopoverHostDirective } from './directives/popover-host.directive';
import { PopoverTargetDirective } from './directives/popover-target.directive';
import { DiscoveryNextBtnDirective } from './directives/discovery-next-btn.directive';
import { DepartmentPipe } from './pipes/department.pipe';
import { InSituTextComponent } from './inputs/in-situ-text/in-situ-text.component';
import { IconSearchComponent } from '../icons/icon-search.component';
import { IconBase } from '../icons/icon-base.class';
import { IconDownCaretComponent } from '../icons/icon-down-caret.component';
import { IconPlusComponent } from '../icons/icon-plus.component';
import { IconPlusCircleComponent } from '../icons/icon-plus-circle.component';

@NgModule({
  declarations: [
    CoreCreateBtnComponent,
    LoadSpinnerComponent,
    StandardFormSubmitComponent,
    StandardFormSubmitBtnDirective,
    StandardFormSectionLabelDirective,
    StandardFormCancelBtnDirective,
    StandardFormLabelDirective,
    StandardFormErrorDirective,
    StandardTextInputDirective,
    CoreOutletTitleDirective,
    DropdownHostDirective,
    DropdownItemDirective,
    DropdownToggleDirective,
    DropdownMenuDirective,
    DmsPipe,
    StatePipe,
    StandardFormSelectDirective,
    TableNavComponent,
    ModalComponent,
    DropdownSearchComponent,
    DiscoveryTextInputComponent,
    ModuleNamePipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EnterModuleBtnComponent,
    RouterModule,
    DiscoveryButtonInputComponent,
    PopoverDirective,
    PopoverHostDirective,
    DepartmentPipe,
    PopoverTargetDirective,
    QuestionContainerComponent,
    DiscoveryNextBtnDirective,
    InSituTextComponent,
    IconSearchComponent,
    IconDownCaretComponent,
    IconBase,
    IconPlusComponent,
    LoadingPlaceholderComponent,
    IconPlusCircleComponent,
  ],
  exports: [
    CoreCreateBtnComponent,
    LoadSpinnerComponent,
    StandardFormSubmitComponent,
    StandardFormSubmitBtnDirective,
    DropdownHostDirective,
    DropdownItemDirective,
    DiscoveryNextBtnDirective,
    DepartmentPipe,
    DropdownMenuDirective,
    DropdownToggleDirective,
    CoreOutletTitleDirective,
    StandardTextInputDirective,
    StandardFormErrorDirective,
    StandardFormLabelDirective,
    StandardFormCancelBtnDirective,
    StandardFormSectionLabelDirective,
    StandardFormSelectDirective,
    LoadingPlaceholderComponent,
    DmsPipe,
    StatePipe,
    TableNavComponent,
    ModalComponent,
    IconPlusCircleComponent,
    DropdownSearchComponent,
    LoadSpinnerComponent,
    QuestionContainerComponent,
    DiscoveryTextInputComponent,
    EnterModuleBtnComponent,
    ModuleNamePipe,
    DiscoveryButtonInputComponent,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
    InSituTextComponent,
    IconSearchComponent,
    IconBase,
    IconDownCaretComponent,
    IconPlusComponent,
  ],
})
export class SharedModule {}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-info',
  standalone: true,
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
    >
      <defs>
        <style>
          .a {
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title></title>
      <circle class="a" cx="12" cy="12" r="11.25" />

      <path
        class="a"
        d="M14.25,16.5H13.5A1.5,1.5,0,0,1,12,15V11.25a.75.75,0,0,0-.75-.75H10.5"
      />
      <path
        class="a"
        d="M11.625,6.75A.375.375,0,1,0,12,7.125a.375.375,0,0,0-.375-.375h0"
      />
    </svg>
  `,
})
export class IconInfoComponent extends IconBase {}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-down-caret',
  standalone: true,
  template: ` <svg
    id="Regular"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .i-drop-caret {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
          fill-rule: evenodd;
        }
      </style>
    </defs>
    <title>Expand</title>
    <path
      class="i-drop-caret"
      d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
    />
  </svg>`,
})
export class IconDownCaretComponent extends IconBase {}

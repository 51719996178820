import { Component, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer } from 'rxjs';

@Component({
  selector: 'dominion-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  @HostBinding('class') class = 'block h-full w-full overflow-auto';

  emailForm!: FormGroup;
  isErr = false;
  errMessage = '';
  successful = false;

  private observer: Observer<void> = {
    next: () => {
      this.handleSuccess();
    },
    error: (err: HttpErrorResponse) => {
      this.handleErr(err);
    },
    complete: () => {
      return;
    },
  };

  constructor(
    private readonly authService: AuthenticationService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
  ) {
    this.emailForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // send forgot password request to the auth service
  sendForgotPasswordRequest() {
    if (this.emailForm.valid) {
      this.resetErr();
      // show spinner
      this.spinner.show();
      const email = this.emailForm.get('email')?.value;
      this.authService
        .sendForgotPasswordRequest(email)
        .subscribe(this.observer);
    }
  }

  private resetErr() {
    this.isErr = false;
    this.errMessage = '';
  }

  private handleSuccess() {
    this.successful = true;
    this.spinner.hide();
  }

  private handleErr(err: HttpErrorResponse) {
    this.spinner.hide();
    this.isErr = true;
    this.errMessage = err.error.message;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownOption } from '../dropdown-search/dropdown-search.component';

@Component({
  selector: 'dominion-option-toggle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './option-toggle.component.html',
  styleUrls: ['./option-toggle.component.css'],
})
export class OptionToggleComponent {
  @Output() emitSelectedOption: EventEmitter<DropdownOption> =
    new EventEmitter<DropdownOption>();

  @Input() options: DropdownOption[] = [];
  @Input() selectedOption: DropdownOption | null = null;

  // ui config
  @Input() paddingX = 'px-2';
  @Input() paddingY = 'py-1';
  @Input() fontSize = 'text-sm';
  @Input() fontWeight = 'font-medium';
  @Input() borderRadius = 'rounded-sm';
  @Input() border = 'border-none';
  @Input() bgColor = 'bg-gray-200';
  @Input() equalWidthOptions = false;
  @Input() selectedBgColor = '[&.selected]:bg-dms-green';
  @Input() selectedTextColor = '[&.selected]:text-white';
  @Input() textColor = 'text-gray-700';
  @Input() disabledTextColor = '[&.disabled]:text-gray-400';
  @Input() disabledBgColor = '[&.disabled]:bg-gray-200';
  @Input() spaceX = 'space-x-[1px]';

  constructor() {}

  selectOption(selectedOption: DropdownOption) {
    if (selectedOption.disabled) {
      return;
    }
    this.emitSelectedOption.emit(selectedOption);
  }
}

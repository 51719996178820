import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/core-submodule.interfaces';

export type TPayrollMaintenanceGroupIdentifiers =
  | 'groupDefaultPositionBreakout'
  | 'groupEmployeeMasterReport'
  | 'groupPayrollRegisterReport'
  | 'groupQuarterlyReports'
  | 'groupQuarterlyTaxReports';

export type TPayrollMaintenanceDataKeys =
  | 'defaultPositionBreakout'
  | 'employeeMasterReport'
  | 'payrollRegisterReport'
  | 'quarterlyReports'
  | 'quarterlyTaxReports';

const payrollMaintenanceGroupMap = {
  moduleType: 'payroll',
  submoduleType: 'maintenance',
  discriminator: 'payroll-maintenance',
  groups: {
    groupDefaultPositionBreakout: ['defaultPositionBreakout'],
    groupEmployeeMasterReport: ['employeeMasterReport'],
    groupPayrollRegisterReport: ['payrollRegisterReport'],
    groupQuarterlyReports: ['quarterlyReports'],
    groupQuarterlyTaxReports: ['quarterlyTaxReports'],
  },
} satisfies TSubmoduleGroupMap<
  TPayrollMaintenanceGroupIdentifiers,
  TPayrollMaintenanceDataKeys
>;

export type TPayrollMaintenanceGroupMap = typeof payrollMaintenanceGroupMap;

export class PayrollMaintenanceData
  implements TDataDefinition<TPayrollMaintenanceDataKeys>
{
  defaultPositionBreakout = new DataInitializerBasic<IFileUploadReference[]>(
    [],
  );
  employeeMasterReport = new DataInitializerBasic<IFileUploadReference[]>([]);
  payrollRegisterReport = new DataInitializerBasic<IFileUploadReference[]>([]);
  quarterlyReports = new DataInitializerBasic<IFileUploadReference[]>([]);
  quarterlyTaxReports = new DataInitializerBasic<IFileUploadReference[]>([]);
}

export const payrollMaintenanceStructure: Structures['payroll-maintenance'] = {
  moduleType: 'payroll',
  submoduleType: 'maintenance',
  discriminator: 'payroll-maintenance',
  groups: {
    groupDefaultPositionBreakout: {
      groupLabel: 'Default Position Breakout',
      groupUrl: 'groupDefaultPositionBreakout',
      groupType: 'standard',
      questions: {
        defaultPositionBreakout: {
          questionKey: 'defaultPositionBreakout',
          label: 'Default Position Breakout',
          componentType: 'file',
          prompt: [
            {
              attributes: null,
              text: 'Please provide a document that describes the default break out for your employee positions.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each position, please provide:\n    \u2022 the position name\n    \u2022 the department\n    \u2022 the GL accounts and percentages',
            },
          ],
          requirement: 'required',
          allowSkip: true,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupEmployeeMasterReport: {
      groupLabel: 'Employee Master Report',
      groupUrl: 'groupEmployeeMasterReport',
      groupType: 'standard',
      questions: {
        employeeMasterReport: {
          questionKey: 'employeeMasterReport',
          label: 'Employee Master Report',
          componentType: 'file',
          prompt: [
            {
              attributes: null,
              text: 'Please provide a list of your employees',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For each employee, please provide: ',
            },
            {
              attributes: null,
              text: '\n    \u2022 employee number\n    \u2022 address\n    \u2022 phone number\n    \u2022 SSN\n    \u2022 pay rate\n    \u2022 default hours\n    \u2022 full/part time\n    \u2022 pay basis\n    \u2022 default hourly rate\n    \u2022 department\n    \u2022 position\n    \u2022 W4 version\n    \u2022 federal and state marital status with exemptions\n       or additional withholdings\n    \u2022 insurance amounts\n    \u2022 401K\n    \u2022 401K company percentages\n    \u2022 miscellaneous deductions\n    \u2022 other pay amounts that occur on every paycheck\n    \u2022 company breakdowns\n    \u2022 unique regular, overtime, vacation, sick, or holiday pay',
            },
          ],
          requirement: 'required',
          allowSkip: true,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPayrollRegisterReport: {
      groupLabel: 'Payroll Register Report',
      groupUrl: 'groupPayrollRegisterReport',
      groupType: 'standard',
      questions: {
        payrollRegisterReport: {
          questionKey: 'payrollRegisterReport',
          label: 'Payroll Register Report',
          componentType: 'file',
          prompt: [
            {
              attributes: null,
              text: 'Please provide your payroll register report.',
            },
          ],
          subprompt: [],
          requirement: 'required',
          allowSkip: true,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupQuarterlyReports: {
      groupLabel: 'Quarterly Reports',
      groupUrl: 'groupQuarterlyReports',
      groupType: 'standard',
      questions: {
        quarterlyReports: {
          questionKey: 'quarterlyReports',
          label: 'Quarterly Reports',
          componentType: 'file',
          prompt: [
            {
              attributes: null,
              text: 'Please provide quarterly reports with both employee and company totals.',
            },
          ],
          subprompt: [],
          requirement: 'required',
          allowSkip: true,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupQuarterlyTaxReports: {
      groupLabel: 'Quarterly Tax Reports',
      groupUrl: 'groupQuarterlyTaxReports',
      groupType: 'standard',
      questions: {
        quarterlyTaxReports: {
          questionKey: 'quarterlyTaxReports',
          label: 'Quarterly Tax Reports',
          componentType: 'file',
          prompt: [
            {
              attributes: null,
              text: 'Please provide all of your quarterly tax reports.',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: "Please include:\n    \u2022 941\n    \u2022 state withholding\n    \u2022 940 (FUTA)\n    \u2022 SUI\n    \u2022 worker's compensation",
            },
          ],
          requirement: 'required',
          allowSkip: true,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['payroll-maintenance'];

export type PayrollMaintenanceGroupRoutes =
  (typeof payrollMaintenanceStructure)['groups'][keyof (typeof payrollMaintenanceStructure)['groups']]['groupUrl'];

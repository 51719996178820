import { SelectOption } from './select-option.interfaces';

/**
 * Returns the option associated with the given value.
 *
 * @param options
 * @param value
 * @returns
 */
export function findOption<
  V extends string | number | boolean,
  T extends SelectOption<string | number | boolean>,
>(options: T[], value: V): T | null {
  const option = options.find((option) => option.value === value);
  if (!option) {
    return null;
  }
  return option;
}

<div class="flex flex-row {{ spaceX }} ">
  <div
    (click)="selectOption(option)"
    [ngClass]="{
      selected: option.value === selectedOption?.value,
      disabled: option.disabled
    }"
    class="{{
      'cursor-pointer text-center [&.disabled]:cursor-not-allowed [&.disabled]:pointer-events-none ' +
        paddingX +
        ' ' +
        paddingY +
        ' ' +
        fontSize +
        ' ' +
        fontWeight +
        ' ' +
        border +
        ' ' +
        bgColor +
        ' ' +
        selectedBgColor +
        ' ' +
        selectedTextColor +
        ' ' +
        disabledBgColor +
        ' ' +
        disabledTextColor +
        ' ' +
        ' first:rounded-tl-sm first:rounded-bl-sm last:rounded-tr-sm last:rounded-br-sm'
    }} {{ equalWidthOptions ? ' basis-0 grow' : '' }}"
    *ngFor="let option of options; let i = index"
  >
    {{ option.label }}
  </div>
</div>

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isFuture } from 'date-fns';

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = control.value;
    if (!date) {
      return null;
    }
    const dateObj = new Date(date);
    if (isFuture(dateObj)) {
      return null;
    }
    return { pastDate: true };
  };
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-lock-locked',
  standalone: true,
  template: ` <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="{{ fillColor }} {{ strokeColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .i-locked {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title>Locked</title>
    <rect
      class="i-locked"
      x="3.75"
      y="9.75"
      width="16.5"
      height="13.5"
      rx="1.5"
      ry="1.5"
    />
    <path class="i-locked" d="M6.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75" />
    <line class="i-locked" x1="12" y1="15" x2="12" y2="18" />
  </svg>`,
})
export class IconLockLockedComponent extends IconBase {}

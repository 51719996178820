import { Component, Input } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-x-circle',
  standalone: true,
  template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>
        .a {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title></title>
    <circle
      class="a {{ circleFillColor }} {{ circleStrokeColor }}"
      cx="12"
      cy="11.999"
      r="11.25"
    />
    <line
      class="a {{ xStrokeColor }}"
      x1="7.5"
      y1="16.499"
      x2="16.5"
      y2="7.499"
    />
    <line
      class="a {{ xStrokeColor }}"
      x1="16.5"
      y1="16.499"
      x2="7.5"
      y2="7.499"
    />
  </svg>`,
})
export class IconXCircleComponent extends IconBase {
  @Input() circleFillColor: string = 'fill-red-700';
  @Input() circleStrokeColor: string = 'stroke-red-700';
  @Input() xStrokeColor: string = 'stroke-gray-50';
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, fromEvent, tap } from 'rxjs';
import { SharedModule } from '../shared.module';

@Component({
  selector: 'dominion-search-bar',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
})
export class SearchBarComponent implements AfterViewInit {
  @Input() borderColor: string = 'border-dms-light-grey';
  @Input() hoverShadow: string = 'hover:shadow';
  @Input() focusBorderColor: string = 'focus-within:border-dms-green';
  @Input() textSize: string = 'text-md';
  @Input() searchIconStroke: string = 'stroke-gray-500';
  @Input() searchIconSize: string = 'h-4 w-4';
  @Input() loadingIconSize: string = 'h-4 w-4';
  @Input() loadingIconStroke: string = 'stroke-dms-green';
  @Input() cancelStroke: string = 'stroke-gray-800';
  @Input() cancelSize: string = 'h-3 w-3';
  @Input() borderRadius: string = 'rounded-sm';

  searchForm: FormGroup;
  isLoading: boolean = false;

  @Output() emitFilterValue: EventEmitter<string> = new EventEmitter();
  @Output() emitStartSearch: EventEmitter<void> = new EventEmitter();

  @ViewChild('searchInputEl') searchInputEl: ElementRef<HTMLInputElement>;

  constructor(private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      search: [''],
    });
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInputEl.nativeElement, 'keyup')
      .pipe(
        tap(() => {
          this.isLoading = true;
          this.emitStartSearch.emit();
        }),
        debounceTime(250),
      )
      .subscribe({
        next: (event: any) => {
          if (this.searchForm) {
            this.emitFilterValue.emit(this.searchForm.get('search')!.value);
          }
        },
      });
  }

  done() {
    this.isLoading = false;
  }

  clear() {
    this.isLoading = false;
    this.searchForm.get('search')!.setValue('');
    this.emitFilterValue.emit('');
  }
}

<ngx-spinner
  type="ball-clip-rotate-pulse"
  size="large"
  data-testid="confirm-spinner"
>
  @if (successful) {
    <p class="text-white">{{ successMessage }}</p>
  } @else {
    <p class="text-white">{{ confirmMessage }}</p>
  }
</ngx-spinner>

<dominion-auth-root heading="Register for VOX">
  @if (isErr) {
    <div
      class="mx-auto mb-6 flex w-full max-w-sm flex-col rounded border border-red-200 bg-red-50 p-2 text-sm text-red-700 shadow-md shadow-red-700/10"
      data-testid="passwordForm-gen-err"
    >
      <h2 class="mb-1 text-sm font-medium">Something went wrong</h2>
      <p class="text-sm" data-testid="error">{{ errMessage }}</p>
    </div>
  }
</dominion-auth-root>

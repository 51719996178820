import { Component } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { AuthorizationService } from './auth/authorization.service';
import { RoutingService } from './routing-service/routing.service';
import { HubspotTrackingService } from './services/hubspot-tracking.service';

@Component({
  selector: 'dominion-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Dominion VOX';

  constructor(
    private authentication: AuthenticationService,
    private authorization: AuthorizationService,
    private routing: RoutingService,
    private hubspot: HubspotTrackingService,
  ) {
  }
}

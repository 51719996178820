import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSubmoduleVisual, CoreQuestionVisual } from '@dominion/interfaces';
import { PromptComponent } from '../../../prompt/prompt.component';

@Component({
  selector: 'dominion-question-list',
  standalone: true,
  imports: [CommonModule, PromptComponent],
  templateUrl: './question-list.component.html',
  styleUrl: './question-list.component.css',
})
export class QuestionListComponent {
  @Input() expand = false;
  @Input() expandedQuestionKey = '';
  @Input() groups: {
    preliminary: CoreSubmoduleVisual['groups'];
    maintenance: CoreSubmoduleVisual['groups'];
  };
  @Input() questions?: CoreQuestionVisual[] = [];
  @Input() selectedModule = '';
  @Input() selectedSubmodule = '';
  @Input() selectedGroupKey = '';
  @Input() selectedQuestionKey = '';
  @Input() selectedQuestion?: CoreQuestionVisual;

  copiedQuestionKey = '';

  constructor(private router: Router) {}

  copyQuestionLink(event: MouseEvent, questionKey: string) {
    event.stopPropagation();

    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;
    params.set(
      'question',
      this.expand ? this.selectedQuestionKey : questionKey,
    );
    currentUrl.hash = questionKey;

    navigator.clipboard.writeText(currentUrl.href).catch((err) => {
      console.error('Failed to copy: ', err);
    });

    this.copiedQuestionKey = questionKey;
    setTimeout(() => {
      this.copiedQuestionKey = '';
    }, 2000);
  }

  optionLabelForValue(question: CoreQuestionVisual, value: unknown) {
    return (
      question.options.find((option) => option.value === value)?.label ?? ''
    );
  }

  countOptionDependencies(
    optionValue: unknown,
    dependencies: CoreQuestionVisual['dependencies'],
  ) {
    return dependencies.reduce(
      (acc, dependency) =>
        acc +
        (dependency.value === optionValue ? dependency.questions.length : 0),
      0,
    );
  }

  countTotalDependencies(dependencies: CoreQuestionVisual['dependencies']) {
    return dependencies.reduce(
      (acc, dependency) => acc + dependency.questions.length,
      0,
    );
  }

  expandQuestion(key: string) {
    this.router
      .navigate([], {
        queryParams: {
          module: this.selectedModule,
          submodule: this.selectedSubmodule,
          group: this.selectedGroupKey,
          question: this.selectedQuestionKey,
        },
        fragment:
          this.expandedQuestionKey === key ? this.selectedQuestionKey : key,
      })
      .then(() => {
        setTimeout(() => {
          const targetElement = document.getElementById(key);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'instant',
              block: 'start',
              inline: 'nearest',
            });
          }
        });
      });
  }

  selectQuestion(key: string) {
    this.router
      .navigate([], {
        queryParams: {
          module: this.selectedModule,
          submodule: this.selectedSubmodule,
          group: this.selectedGroupKey,
          question: this.selectedQuestionKey === key ? undefined : key,
        },
        fragment: this.selectedQuestionKey === key ? undefined : key,
      })
      .then(() => {
        setTimeout(() => {
          const targetElement = document.getElementById(key);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'instant',
              block: 'start',
              inline: 'nearest',
            });
          }
        });
      });
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableDirective } from '../directives/expandable.directive';
import { ExpandableHostDirective } from '../directives/expandable-host.directive';
import { ExpandableToggleDirective } from '../directives/expandable-toggle.directive';
import { AccordionDirective } from '../directives/accordion.directive';
import { FlipDirective } from '../directives/flip.directive';
import { DropdownCaretComponent } from '../dropdown-caret/dropdown-caret.component';

@Component({
  selector: 'dominion-animation-testing',
  standalone: true,
  imports: [
    CommonModule,
    ExpandableDirective,
    ExpandableHostDirective,
    ExpandableToggleDirective,
    AccordionDirective,
    FlipDirective,
    DropdownCaretComponent,
  ],
  animations: [],
  templateUrl: './animation-testing.component.html',
  styleUrls: ['./animation-testing.component.css'],
})
export class AnimationTestingComponent {
  flipped = false;

  flip() {
    this.flipped = !this.flipped;
  }
}

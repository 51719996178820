<button
  [ngClass]="{ disabled: !isAvailable && view === 'external' }"
  class="bg-dms-teal flex flex-grow flex-row items-center justify-center rounded-b-sm px-4 py-1 text-sm text-white hover:shadow [&.disabled]:cursor-not-allowed [&.disabled]:bg-gray-300 [&.disabled]:text-gray-600"
>
  {{ buttonText }}
  <div class="ml-2 h-4 w-4">
    @if (view === 'internal' || isAvailable) {
      <dominion-icon-go-to
        strokeColor="stroke-white"
        strokeWidth="stroke-2"
      ></dominion-icon-go-to>
    }
    @if (view === 'external' && !isAvailable) {
      <dominion-icon-lock-locked
        strokeColor="stroke-gray-600"
        strokeWidth="stroke-2"
      ></dominion-icon-lock-locked>
    }
  </div>
</button>

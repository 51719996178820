import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { SharedModule } from '../../shared/shared.module';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import {
  IDiscoveryDataProperty,
  Month,
  MonthDaysMap,
  MonthOptions,
  ResponseOptions,
} from '@dominion/interfaces';
import { LogComponent } from '../../log/log.component';
import { ModalComponent } from '../../shared/modal/modal.component';

@Pipe({
  name: 'getDayOptions',
  standalone: true,
})
export class getDayOptionsPipe implements PipeTransform {
  transform(monthOption: ResponseOptions | null | undefined) {
    if (monthOption === null || monthOption === undefined) {
      return [] as ResponseOptions[];
    }
    const dayOptions: ResponseOptions[] = [];
    const monthDays = MonthDaysMap[monthOption.value as Month];
    let i = 1;
    while (i <= monthDays) {
      dayOptions.push({ label: i.toString(), value: i });
      i++;
    }
    return dayOptions;
  }
}

@Component({
  selector: 'dominion-input-month-day',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    DiscoveryNextBtnDirective,
    getDayOptionsPipe,
    LogComponent,
  ],
  templateUrl: './input-month-day.component.html',
  styleUrls: ['./input-month-day.component.css'],
})
export class InputMonthDayComponent
  implements OnInit, OnChanges, ResponseInput
{
  @Input({ required: true }) data: IDiscoveryDataProperty<{
    month: string;
    day: string;
  }>;
  get log() {
    return this.data?.log ? this.data.log : [];
  }

  @Output() response: EventEmitter<{ month: string; day: string }> =
    new EventEmitter<{ month: string; day: string }>();

  @ViewChild('logModal') logModal: ModalComponent;

  selectedMonthOption: ResponseOptions | undefined | null;
  selectedDayOption: ResponseOptions | undefined | null;
  monthOptions = MonthOptions;

  constructor() {}

  ngOnInit() {
    this.initializeMonthAndDay();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] || changes['data.value']) {
      this.initializeMonthAndDay();
    }
  }

  handleErr(err: string): void {
    throw new Error(err);
  }

  handleSuccess(): void {
    return;
  }

  setDay(day: ResponseOptions) {
    this.selectedDayOption = day;
  }

  isDayInputDisabled() {
    if (this.selectedMonthOption === null) {
      return true;
    }
    return false;
  }

  fiscalYearEndNextBtnDisabled() {
    if (this.selectedMonthOption === null || this.selectedDayOption === null) {
      return true;
    }
    return false;
  }

  saveFiscalYearEndDate() {
    if (
      this.selectedMonthOption === null ||
      this.selectedDayOption === null ||
      this.selectedDayOption === undefined ||
      this.selectedMonthOption === undefined
    ) {
      return;
    }
    this.response.emit({
      month: this.selectedMonthOption.value,
      day: this.selectedDayOption.value,
    });
  }

  initializeMonthAndDay() {
    if (this.data === null) {
      return;
    }
    if (this.data && this.data.value!['month']) {
      const optionMatch = this.monthOptions.find(
        (option) => option.value === this.data.value!['month'],
      );
      if (optionMatch) {
        this.selectedMonthOption = optionMatch;
      }
    }
    if (this.selectedMonthOption && this.data.value!['day']) {
      this.selectedDayOption = {
        label: this.data.value!['day'].toString(),
        value: this.data.value!['day'],
      };
    }
  }

  setMonth(monthOption: ResponseOptions) {
    this.selectedMonthOption = monthOption;
    this.selectedDayOption = null;
  }
}

<div class="mb-5 flex flex-row items-center px-4">
  <div class="flex flex-row items-center space-x-2 pr-6">
    <span class="text-sm uppercase text-gray-500">Onboarding Project</span>
    <dominion-information-button [buttonHeight]="'h-4'" [buttonWidth]="'w-4'">
      <div class="flex w-72 flex-col space-y-2 p-2 text-sm">
        <p>
          This section contains a list of <strong>modules</strong>. Each module
          corresponds to a department within your dealership, like Sales or
          Accounting, and requests important information about how you operate.
        </p>
        <p>
          You and your team will complete each module. Your DominionDMS
          deployment team will use the information that you provide to tailor
          VUE's data, configurations, and integrations to your business.
        </p>
      </div>
    </dominion-information-button>
  </div>
  <!-- ADD USER BUTTON -->
  <div
    *ngIf="canAddModule && !isParentCompany"
    class="fill-dms-green [&_circle]stroke-dms-green text-dms-green flex cursor-pointer items-center stroke-white text-sm"
    (click)="openModuleModal()"
  >
    <div class="relative mr-1 h-5 w-5">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs>
          <style>
            .i-new {
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.2px;
            }
          </style>
        </defs>
        <title>{{ 'Invite a User' }}</title>
        <circle class="i-new" cx="12" cy="12" r="11.25" />
        <line class="i-new" x1="12" y1="7.5" x2="12" y2="16.5" />
        <line class="i-new" x1="7.5" y1="12" x2="16.5" y2="12" />
      </svg>
    </div>
    <span>Add Module</span>
  </div>
  <div class="flex-1"></div>
  <div class="ml-6 flex flex-shrink flex-row space-x-[2px]">
    <!-- VENDOR CENTER BUTTON -->
    <div
      class="hover:border-dms-teal hover:text-dms-teal flex cursor-pointer flex-row items-center justify-center rounded-sm rounded-br-none rounded-tr-none border border-solid border-gray-300 px-2 py-1 text-center text-sm text-gray-500 hover:shadow"
      [routerLink]="['/core', companyId, 'vendors']"
    >
      Vendor Center
    </div>
    <!-- VENDOR CENTER BUTTON -->
    <div
      class="hover:border-dms-teal hover:text-dms-teal flex cursor-pointer flex-row items-center justify-center rounded-sm rounded-bl-none rounded-tl-none border border-solid border-gray-300 px-2 text-center text-sm text-gray-500 hover:shadow"
      [routerLink]="['/core', companyId, 'documents']"
    >
      Document Center
    </div>
  </div>
</div>

<!--  -->
<!--  -->
<!-- NO MODULES INDICATOR -->
<div
  *ngIf="!isLoading && modules?.length === 0"
  class="px-4 text-sm font-light italic text-gray-600"
>
  No project modules have been assigned yet
</div>

<!--  -->
<!--  -->
<!-- IS LOADING INDICATOR -->
<div *ngIf="isLoading" class="mt-4 flex h-8 flex-row">
  <dominion-loading-placeholder></dominion-loading-placeholder>
</div>

<!--  -->
<!--  -->
<!-- MODULE LIST -->
@if (!isLoading && modules && modules.length > 0) {
  <div class="group flex flex-col">
    @for (module of modules; track module._id) {
      <!-- EXPANDABLE HOST -->
      <div
        dominionExpandableHost
        class="hover:shadow-centered flex flex-col border-b border-b-gray-100 first:border-t first:border-t-gray-100 group-hover:border-gray-200 group-hover:bg-gray-100 group-hover:hover:bg-white"
      >
        <div
          class="grid grid-cols-[24px,3fr,1fr,144px] items-center gap-4 px-4 py-3"
        >
          <!-- EXPANDABLE TOGGLE  -->
          <div
            dominionExpandableToggle
            class="flex shrink flex-row items-center justify-center"
          >
            <dominion-dropdown-caret
              [stroke]="'stroke-gray-500'"
              [strokeWidth]="'stroke-2'"
            ></dominion-dropdown-caret>
          </div>

          <div class="flex flex-col">
            <!-- MODULE NAME -->
            <div class="flex font-semibold">
              <dominion-in-situ-text
                #moduleName
                [initialValue]="
                  module.name === ''
                    ? (module.moduleType | moduleType)
                    : module.name
                "
                [isDisabled]="!canEditModuleDetails"
                [maxLength]="25"
                [minLength]="3"
                [defaultValue]="module.moduleType | moduleType"
                formErrMsg="Please enter a module name"
                (saved)="
                  updateModuleDetails(
                    {
                      field: 'name',
                      value: $event,
                      moduleId: module._id
                    },
                    moduleName
                  )
                "
              ></dominion-in-situ-text>
            </div>

            <!-- RESPONSIBLE USER -->
            <div
              class="flex flex-shrink items-center"
              [ngClass]="{
                'font-medium text-gray-700': module.externalAssigned[0],
                'text-gray-500': !module.externalAssigned[0]
              }"
            >
              @if (module.externalAssigned[0]) {
                <div
                  class="bg-dms-light-green ml-[3px] mr-1 flex h-3.5 w-3.5 items-center justify-center rounded-sm text-xs font-medium uppercase text-white"
                >
                  {{
                    (module.externalAssigned[0] | moduleActiveAssignedUser)
                      ?.label?.[0]
                  }}
                </div>
              } @else {
                <div class="relative ml-[3px] mr-1 h-3.5 w-3.5">
                  <svg
                    id="Regular"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="text-gray-400"
                  >
                    <defs>
                      <style>
                        .i-user-avi {
                          fill: none;
                          stroke: currentColor;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <title></title>
                    <circle class="i-user-avi" cx="12" cy="9.75" r="5.25" />
                    <path
                      class="i-user-avi"
                      d="M18.913,20.876a9.746,9.746,0,0,0-13.826,0"
                    />
                    <circle class="i-user-avi" cx="12" cy="12" r="11.25" />
                  </svg>
                </div>
              }
              <dominion-dropdown-search
                #assignedUser
                [options]="module | getModuleAssignedUserOptions: company"
                [viewType]="'editable'"
                [togglePaddingX]="'px-[1px]'"
                [togglePaddingY]="'py-[1px]'"
                [toggleText]="'Assign User'"
                [selectedOption]="
                  module.externalAssigned[0] | moduleActiveAssignedUser
                "
                [disabled]="!canAssignModuleUser"
                (emitSelectedValue)="assignUserToModule(module, $event)"
              ></dominion-dropdown-search>
            </div>
          </div>

          <!-- COMPLETION STATUS -->
          <div class="flex flex-1 flex-row items-center space-x-6">
            <div class="w-7">
              <dominion-module-status-circle
                [isLocked]="!module.submodules.preliminary.isAvailable"
                [completion]="module.submodules.preliminary | submoduleStatus"
                [stage]="'1'"
              ></dominion-module-status-circle>
            </div>
            <!-- infra module does not need other phases -->
            @if (
              module.moduleType !== 'infrastructure' &&
              module.moduleType !== 'fi'
            ) {
              <div class="w-7">
                <dominion-module-status-circle
                  [isLocked]="!module.submodules.maintenance.isAvailable"
                  [completion]="module.submodules.maintenance | submoduleStatus"
                  [stage]="'2'"
                ></dominion-module-status-circle>
              </div>
              <div class="w-7">
                <dominion-module-status-circle
                  [isLocked]="!module.submodules.default.isAvailable"
                  [completion]="module.submodules.default | submoduleStatus"
                  [stage]="'3'"
                ></dominion-module-status-circle>
              </div>
            } @else {
              <div class="w-7"></div>
              <div class="w-7"></div>
            }
          </div>

          <!-- ACTION BUTTON -->
          <div class="flex basis-[20%] flex-row justify-end">
            @if (
              module.moduleType !== 'fi' &&
              module.moduleType !== 'infrastructure'
            ) {
              <dominion-module-action-btn
                [percentageComplete]="module | moduleCompletion"
                (clicked)="goToModule(module)"
                [isInternalView]="internalView"
                [isAvailable]="
                  module.submodules.preliminary.isAvailable ||
                  module.submodules.maintenance.isAvailable ||
                  module.submodules.default.isAvailable
                "
              ></dominion-module-action-btn>
            } @else {
              <dominion-module-action-btn
                [percentageComplete]="module | moduleCompletion"
                (clicked)="goToModule(module)"
                [isInternalView]="internalView"
                [isAvailable]="module.submodules.preliminary.isAvailable"
              ></dominion-module-action-btn>
            }
          </div>
        </div>

        <!--  -->
        <!-- EXPANDABLE -->
        <div dominionExpandable class="flex flex-row">
          @if (
            module.moduleType !== 'infrastructure' && module.moduleType !== 'fi'
          ) {
            <div class="flex flex-1 flex-col px-4 pt-4">
              <!-- PHASE LABELS -->
              <div class="flex flex-row text-sm">
                <div
                  class="flex basis-1/3 justify-center uppercase text-gray-500"
                >
                  Phase 1
                </div>
                <div
                  class="flex basis-1/3 justify-center uppercase text-gray-500"
                >
                  Phase 2
                </div>
                <div
                  class="flex basis-1/3 justify-center uppercase text-gray-500"
                >
                  Phase 3
                </div>
              </div>

              <!-- PHASE BAR -->
              <div class="mb-8 flex flex-row">
                <div class="flex basis-1/3 flex-row justify-end">
                  <div class="basis-1/2 border-b-2 border-b-gray-400">
                    <div class="relative -left-5 top-[16px] h-8 w-8">
                      <dominion-module-status-circle
                        [stage]="'1'"
                        [completion]="
                          module.submodules.preliminary | submoduleStatus
                        "
                        [isLocked]="!module.submodules.preliminary.isAvailable"
                      ></dominion-module-status-circle>
                    </div>
                  </div>
                </div>
                <div class="flex basis-1/3 flex-row">
                  <div
                    class="flex flex-1 justify-center border-b-2 border-gray-400"
                  >
                    <div class="relative top-[16px] h-8 w-8">
                      <dominion-module-status-circle
                        [stage]="'2'"
                        [completion]="
                          module.submodules.maintenance | submoduleStatus
                        "
                        [isLocked]="!module.submodules.maintenance.isAvailable"
                      ></dominion-module-status-circle>
                    </div>
                  </div>
                </div>
                <div class="flex basis-1/3 flex-row justify-start">
                  <div
                    class="flex flex-none basis-1/2 justify-end border-b-2 border-gray-400"
                  >
                    <div class="relative -right-5 top-[16px] h-8 w-8">
                      <dominion-module-status-circle
                        [stage]="'3'"
                        [completion]="
                          module.submodules.default | submoduleStatus
                        "
                        [isLocked]="!module.submodules.default.isAvailable"
                      ></dominion-module-status-circle>
                    </div>
                  </div>
                </div>
              </div>

              <!-- COMPLETION STATUS INDICATORS -->
              <div class="flex flex-row space-x-7">
                <!-- PHASE ONE COMPLETION BAR -->
                <div class="flex basis-1/3 flex-row">
                  <dominion-completion-status-bar
                    class="h-[6px] flex-none basis-full"
                    [completionColor]="'bg-dms-green'"
                    [rounded]="'rounded-t-sm'"
                    [bgColor]="'bg-gray-300'"
                    [percentage]="
                      module.submodules.preliminary | submoduleStatus
                    "
                  >
                  </dominion-completion-status-bar>
                </div>
                <!-- PHASE TWO COMPLETION BAR -->
                <div class="flex basis-1/3 flex-row">
                  <dominion-completion-status-bar
                    class="h-[6px] flex-none basis-full"
                    [completionColor]="'bg-dms-green'"
                    [rounded]="'rounded-t-sm'"
                    [bgColor]="'bg-gray-300'"
                    [percentage]="
                      module.submodules.maintenance | submoduleStatus
                    "
                  >
                  </dominion-completion-status-bar>
                </div>
                <!-- PHASE THREE COMPLETION BAR -->
                <div class="flex basis-1/3 flex-row">
                  <dominion-completion-status-bar
                    class="h-[6px] flex-none basis-full"
                    [completionColor]="'bg-dms-green'"
                    [rounded]="'rounded-t-sm'"
                    [bgColor]="'bg-gray-300'"
                    [percentage]="module.submodules.default | submoduleStatus"
                  >
                  </dominion-completion-status-bar>
                </div>
              </div>

              <!-- PHASES -->
              <div class="flex flex-row space-x-7">
                <!-- PHASE ONE | PRELIMINARY DISCOVERY -->
                <div
                  class="flex basis-1/3 flex-col rounded-b-sm bg-gray-100 pt-3"
                >
                  <div
                    class="flex flex-row justify-center text-center font-bold"
                  >
                    Preliminary Discovery
                  </div>

                  @if (internalView) {
                    <!-- AVAILABILITY -->
                    <div
                      class="mt-3 flex flex-row justify-center text-sm italic text-gray-600"
                    >
                      Availability
                    </div>
                    <div class="flex flex-row">
                      <div class="flex flex-1 justify-center text-sm font-bold">
                        <dominion-in-situ-select
                          #prelimAvailability
                          [config]="{ viewType: 'editable', searchable: false }"
                          [initialValue]="
                            module?.submodules?.preliminary?.isAvailable
                              ? 'available'
                              : 'unavailable'
                          "
                          [options]="availabilityOptions"
                          defaultValue="unavailable"
                          (saved)="
                            updateSubmoduleDetails(
                              {
                                field: 'isAvailable',
                                value: $event,
                                moduleId: module._id,
                                submoduleId:
                                  module.submodules.preliminary.submoduleId
                              },
                              prelimAvailability
                            )
                          "
                          [isDisabled]="!canEditModuleDetails"
                        ></dominion-in-situ-select>
                      </div>
                    </div>
                  }

                  <!-- DUE DATE -->
                  <div
                    class="mt-5 flex flex-row justify-center text-sm italic text-gray-600"
                  >
                    Please complete by:
                  </div>
                  <div class="flex flex-row justify-center text-sm font-bold">
                    <dominion-in-situ-text
                      #preliminaryDueDate
                      [initialValue]="
                        module.submodules.preliminary.dueDate
                          | date: 'MM/dd/yyyy'
                      "
                      [isDisabled]="!canEditModuleDetails"
                      [maxLength]="10"
                      [minLength]="10"
                      defaultValue="No date"
                      formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                      type="date"
                      (saved)="
                        updateSubmoduleDetails(
                          {
                            moduleId: module._id,
                            submoduleId:
                              module.submodules.preliminary.submoduleId,
                            field: 'dueDate',
                            value: $event
                          },
                          preliminaryDueDate
                        )
                      "
                    ></dominion-in-situ-text>
                  </div>

                  <!-- ENTER MODULE BUTTON -->
                  <div class="mt-6 flex flex-row justify-center">
                    <div class="flex grow flex-row items-center">
                      <dominion-enter-module-btn
                        *ngIf="!internalView"
                        [total]="module.submodules.preliminary.completion.total"
                        [completed]="
                          module.submodules.preliminary.completion.complete
                        "
                        [isAvailable]="
                          module.submodules.preliminary.isAvailable
                        "
                        [view]="'external'"
                        (click)="goToModule(module)"
                        [ngClass]="{
                          'pointer-events-none':
                            !module.submodules['preliminary'].isAvailable
                        }"
                        class="flex grow"
                      ></dominion-enter-module-btn>

                      <dominion-enter-module-btn
                        *ngIf="internalView"
                        [total]="module.submodules.preliminary.completion.total"
                        [completed]="
                          module.submodules.preliminary.completion.complete
                        "
                        [view]="'internal'"
                        (click)="goToModule(module)"
                        class="flex grow"
                      ></dominion-enter-module-btn>
                    </div>
                  </div>
                </div>

                <!-- PHASE TWO | MAINTENANCE DISCOVERY -->
                <div
                  class="flex basis-1/3 flex-col rounded-b-sm bg-gray-100 pt-3"
                >
                  <div
                    class="flex flex-row justify-center text-center font-bold"
                  >
                    Maintenance Discovery
                  </div>
                  @if (internalView) {
                    <div
                      class="mt-3 flex flex-row justify-center text-sm italic text-gray-600"
                    >
                      Availability
                    </div>

                    <!-- AVAILABILITY -->
                    <div class="flex flex-row">
                      <div class="flex flex-1 justify-center text-sm font-bold">
                        <dominion-in-situ-select
                          #maintenanceAvailability
                          [config]="{ viewType: 'editable', searchable: false }"
                          [initialValue]="
                            module?.submodules?.maintenance?.isAvailable
                              ? 'available'
                              : 'unavailable'
                          "
                          [options]="availabilityOptions"
                          defaultValue="unavailable"
                          (saved)="
                            updateSubmoduleDetails(
                              {
                                field: 'isAvailable',
                                value: $event,
                                moduleId: module._id,
                                submoduleId:
                                  module.submodules.maintenance.submoduleId
                              },
                              maintenanceAvailability
                            )
                          "
                          [isDisabled]="!canEditModuleDetails"
                        ></dominion-in-situ-select>
                      </div>
                    </div>
                  }
                  <div
                    class="mt-5 flex flex-row justify-center text-sm italic text-gray-600"
                  >
                    Please complete by:
                  </div>

                  <!-- DUE DATE -->
                  <div class="flex flex-row justify-center text-sm font-bold">
                    <dominion-in-situ-text
                      #maintenanceDueDate
                      [initialValue]="
                        module.submodules.maintenance.dueDate
                          | date: 'MM/dd/yyyy'
                      "
                      [isDisabled]="!canEditModuleDetails"
                      [maxLength]="10"
                      [minLength]="10"
                      defaultValue="No date"
                      formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                      type="date"
                      (saved)="
                        updateSubmoduleDetails(
                          {
                            moduleId: module._id,
                            submoduleId:
                              module.submodules.maintenance.submoduleId,
                            field: 'dueDate',
                            value: $event
                          },
                          maintenanceDueDate
                        )
                      "
                    ></dominion-in-situ-text>
                  </div>

                  <!-- SPACE TAKER  -->
                  <div class="flex flex-1"></div>

                  <!-- ENTER MODULE BUTTON  -->
                  <div class="mt-4 flex flex-row justify-center">
                    <div class="flex grow flex-row items-center">
                      <dominion-enter-module-btn
                        *ngIf="!internalView"
                        [total]="1"
                        [completed]="0"
                        [isAvailable]="
                          module.submodules.maintenance.isAvailable
                        "
                        [view]="'external'"
                        [ngClass]="{
                          'pointer-events-none':
                            !module.submodules['maintenance'].isAvailable
                        }"
                        (click)="goToModule(module, 'maintenance')"
                        class="flex grow"
                      ></dominion-enter-module-btn>

                      <dominion-enter-module-btn
                        *ngIf="internalView"
                        [total]="1"
                        [completed]="0"
                        [view]="'internal'"
                        (click)="goToModule(module, 'maintenance')"
                        class="flex grow"
                      ></dominion-enter-module-btn>
                    </div>
                  </div>
                </div>

                <!-- PHASE THREE | DEFAULT DISCOVERY -->
                <div
                  class="flex basis-1/3 flex-col rounded-b-sm bg-gray-100 pt-3"
                >
                  <div
                    class="flex flex-row justify-center text-center font-bold"
                  >
                    Default Discovery
                  </div>
                  @if (internalView) {
                    <div
                      class="mt-3 flex flex-row justify-center text-sm italic text-gray-600"
                    >
                      Availability
                    </div>
                    <div class="flex flex-row">
                      <div class="flex flex-1 justify-center text-sm font-bold">
                        <dominion-in-situ-select
                          #defaultAvailability
                          [config]="{ viewType: 'editable', searchable: false }"
                          [initialValue]="
                            module?.submodules?.default?.isAvailable
                              ? 'available'
                              : 'unavailable'
                          "
                          [options]="availabilityOptions"
                          defaultValue="unavailable"
                          (saved)="
                            updateSubmoduleDetails(
                              {
                                field: 'isAvailable',
                                value: $event,
                                moduleId: module._id,
                                submoduleId:
                                  module.submodules.default.submoduleId
                              },
                              defaultAvailability
                            )
                          "
                          [isDisabled]="!canEditModuleDetails"
                        ></dominion-in-situ-select>
                      </div>
                    </div>
                  }
                  <div
                    class="mt-5 flex flex-row justify-center text-sm italic text-gray-600"
                  >
                    Please complete by:
                  </div>
                  <div class="flex flex-row justify-center text-sm font-bold">
                    <dominion-in-situ-text
                      #defaultDueDate
                      [initialValue]="
                        module.submodules.default.dueDate | date: 'MM/dd/yyyy'
                      "
                      [isDisabled]="!canEditModuleDetails"
                      [maxLength]="10"
                      [minLength]="10"
                      defaultValue="No date"
                      formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                      type="date"
                      (saved)="
                        updateSubmoduleDetails(
                          {
                            moduleId: module._id,
                            submoduleId: module.submodules.default.submoduleId,
                            field: 'dueDate',
                            value: $event
                          },
                          defaultDueDate
                        )
                      "
                    ></dominion-in-situ-text>
                  </div>
                  <div class="flex flex-1"></div>
                  <div class="mt-4 flex flex-row justify-center">
                    <div class="flex grow flex-row items-center">
                      <dominion-enter-module-btn
                        *ngIf="!internalView"
                        [total]="1"
                        [completed]="0"
                        [isAvailable]="module.submodules.default.isAvailable"
                        [view]="'external'"
                        [ngClass]="{
                          'pointer-events-none':
                            !module.submodules['default'].isAvailable
                        }"
                        (click)="goToModule(module, 'default')"
                        class="flex grow"
                      ></dominion-enter-module-btn>

                      <dominion-enter-module-btn
                        *ngIf="internalView"
                        [total]="1"
                        [completed]="0"
                        [view]="'internal'"
                        (click)="goToModule(module, 'default')"
                        class="flex grow"
                      ></dominion-enter-module-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-none basis-[50px]"></div>
            </div>
          } @else {
            <div class="flex flex-1 flex-col px-4 pt-4">
              <!-- Phase Label -->
              <div class="flex flex-row justify-center text-sm">
                <div
                  class="flex basis-1/2 justify-center uppercase text-gray-500"
                >
                  Single Phase
                </div>
              </div>

              <!-- PHASE BAR -->
              <div class="mb-8 flex flex-row justify-center">
                <div class="flex basis-1/2 flex-row justify-end">
                  <div class="basis-1/2">
                    <div class="relative -left-5 top-[18px] h-9 w-9">
                      <dominion-module-status-circle
                        [stage]="'1'"
                        [completion]="
                          module.submodules.preliminary | submoduleStatus
                        "
                        [isLocked]="!module.submodules.preliminary.isAvailable"
                      ></dominion-module-status-circle>
                    </div>
                  </div>
                </div>
              </div>

              <!-- COMPLETION STATUS INDICATORS -->
              <div class="flex flex-row justify-center">
                <!-- PHASE ONE COMPLETION BAR -->
                <div class="flex basis-1/2 flex-row">
                  <dominion-completion-status-bar
                    class="h-[6px] flex-none basis-full"
                    [completionColor]="'bg-dms-green'"
                    [rounded]="'rounded-t-sm'"
                    [bgColor]="'bg-gray-300'"
                    [percentage]="
                      module.submodules.preliminary | submoduleStatus
                    "
                  >
                  </dominion-completion-status-bar>
                </div>
              </div>

              <div class="flex flex-row justify-center">
                <!-- PHASE ONE | PRELIMINARY DISCOVERY -->
                <div
                  class="flex basis-1/2 flex-col rounded-b-sm bg-gray-100 pt-3"
                >
                  <div class="flex flex-row justify-center font-bold">
                    Preliminary Discovery
                  </div>

                  @if (internalView) {
                    <!-- AVAILABILITY -->
                    <div
                      class="mt-3 flex flex-row justify-center text-sm italic text-gray-600"
                    >
                      Availability
                    </div>
                    <div class="flex flex-row">
                      <div class="flex flex-1 justify-center text-sm font-bold">
                        <dominion-in-situ-select
                          #prelimAvailability
                          [config]="{ viewType: 'editable', searchable: false }"
                          [initialValue]="
                            module?.submodules?.preliminary?.isAvailable
                              ? 'available'
                              : 'unavailable'
                          "
                          [options]="availabilityOptions"
                          defaultValue="unavailable"
                          (saved)="
                            updateSubmoduleDetails(
                              {
                                field: 'isAvailable',
                                value: $event,
                                moduleId: module._id,
                                submoduleId:
                                  module.submodules.preliminary.submoduleId
                              },
                              prelimAvailability
                            )
                          "
                          [isDisabled]="!canEditModuleDetails"
                        ></dominion-in-situ-select>
                      </div>
                    </div>
                  }

                  <!-- DUE DATE -->
                  <div
                    class="mt-5 flex flex-row justify-center text-sm italic text-gray-600"
                  >
                    Please complete by:
                  </div>
                  <div class="flex flex-row justify-center text-sm font-bold">
                    <dominion-in-situ-text
                      #preliminaryDueDate
                      [initialValue]="
                        module.submodules.preliminary.dueDate
                          | date: 'MM/dd/yyyy'
                      "
                      [isDisabled]="!canEditModuleDetails"
                      [maxLength]="10"
                      [minLength]="10"
                      defaultValue="No date"
                      formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                      type="date"
                      (saved)="
                        updateSubmoduleDetails(
                          {
                            moduleId: module._id,
                            submoduleId:
                              module.submodules.preliminary.submoduleId,
                            field: 'dueDate',
                            value: $event
                          },
                          preliminaryDueDate
                        )
                      "
                    ></dominion-in-situ-text>
                  </div>

                  <!-- ENTER MODULE BUTTON -->
                  <div class="mt-6 flex flex-row justify-center">
                    <div class="flex grow flex-row items-center">
                      <dominion-enter-module-btn
                        *ngIf="!internalView"
                        [total]="module.submodules.preliminary.completion.total"
                        [completed]="
                          module.submodules.preliminary.completion.complete
                        "
                        [isAvailable]="
                          module.submodules.preliminary.isAvailable
                        "
                        [view]="'external'"
                        [ngClass]="{
                          'pointer-events-none':
                            !module.submodules['preliminary'].isAvailable
                        }"
                        (click)="goToModule(module)"
                        class="flex grow"
                      ></dominion-enter-module-btn>

                      <dominion-enter-module-btn
                        *ngIf="internalView"
                        [total]="module.submodules.preliminary.completion.total"
                        [completed]="
                          module.submodules.preliminary.completion.complete
                        "
                        [view]="'internal'"
                        (click)="goToModule(module)"
                        class="flex grow"
                      ></dominion-enter-module-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-none basis-[50px]"></div>
            </div>
          }
        </div>
      </div>
    }
  </div>
}

<!--  -->
<!--  -->
<!-- ADD MODULE MODAL -->
<dominion-modal #addModuleModal title="Add Module">
  <form
    body
    class="flex min-h-[450px] min-w-[400px] flex-col"
    [formGroup]="moduleForm"
    (ngSubmit)="addModule()"
  >
    <!-- SELECT MODULE TYPE -->
    <!-- Select Module Error -->
    <div
      *ngIf="
        moduleForm.get('moduleType')?.invalid &&
        moduleForm.get('moduleType')?.touched
      "
      class="flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormError>Please select a module type.</div>
      </div>
    </div>
    <!-- Select Module Label -->
    <div
      *ngIf="
        this.moduleForm.get('moduleType')?.valid ||
        this.moduleForm.get('moduleType')?.untouched
      "
      class="flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormLabel [required]="true">Module Type</div>
      </div>
    </div>
    <!-- Select Module Input -->
    <div class="flex flex-row">
      <div class="flex-1">
        <dominion-dropdown-search
          [options]="moduleOptions"
          [toggleText]="'Select a Module Type'"
          [selectedOption]="selectedModule"
          [enableCreateNew]="false"
          [searchable]="false"
          (emitClosed)="markSelectModuleAsTouched()"
          (emitSelectedValue)="selectModuleType($event)"
        ></dominion-dropdown-search>
      </div>
    </div>

    <!-- ENTER MODULE NAME -->
    <!-- Module Name Label -->
    <div
      *ngIf="moduleForm.get('name')?.valid || moduleForm.get('name')?.untouched"
      class="mt-6 flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormLabel>Module Name</div>
      </div>
    </div>
    <!-- Module Name Input -->
    <div class="flex flex-row">
      <div class="flex-1">
        <input
          #emailInput
          class="outline-dms-light-green w-full overflow-hidden rounded-sm border p-2 text-center text-sm placeholder:font-light placeholder:italic"
          type="text"
          formControlName="name"
          placeholder="Unique Module Name"
        />
      </div>
    </div>

    <!-- SUBMIT BUTTON -->
    <div class="mt-8 flex flex-row">
      <div dominionStandardFormCancelBtn (click)="cancel()" class="flex-1">
        Cancel
      </div>
      <div class="flex-1">
        <dominion-standard-form-submit
          type="submit"
          [disabled]="!moduleForm.valid"
          text="Add Module"
          [isLoading]="isAddingModule"
        ></dominion-standard-form-submit>
      </div>
    </div>
  </form>
</dominion-modal>

import { AbstractControl, ValidatorFn } from '@angular/forms';

type RequirementLevel = 'internal' | 'external';

export const passwordErrorMessages = {
  required: 'Password is required.',
  length: 'Password must be at least 8 characters long.',
  lowercase: 'Password must contain at least one lowercase letter.',
  uppercase: 'Password must contain at least one uppercase letter.',
  digit: 'Password must contain at least one digit.',
  nonLetterOrDigit:
    'Password must contain at least one special character (e.g., # ? ! @ $ % ^ & * -).',
};

export function passwordRequirementValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;

    if (!password) {
      return null;
    }

    // Non letters or digits: # ? ! @ $ % ^ & * -
    const hasNonLetterOrDigit = /[\\#\\?\\!@\\$%\\^&\\*\\-]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const isValidLength = /.{8,}/.test(password);

    const errors: { [key: string]: boolean } = {};

    if (!isValidLength) {
      errors['length'] = true;
    } else {
      if (!hasLowercase) {
        errors['lowercase'] = true;
      } else if (!hasUppercase) {
        errors['uppercase'] = true;
      } else if (!hasDigit) {
        errors['digit'] = true;
      } else if (!hasNonLetterOrDigit) {
        errors['nonLetterOrDigit'] = true;
      }
    }

    return Object.keys(errors).length ? { passwordRequirements: errors } : null;
  };
}

<section class="flex items-center">
  @if (internalView) {
    <button
      (click)="openTeamModal()"
      class="rounded-sm px-2 py-1 transition hover:bg-gray-100"
    >
      <h2 class="text-dms-green text-right text-xs font-light">
        Project Manager
      </h2>
      <p class="text-dms-ultra-grey text-right text-sm font-medium">
        {{ selectedProjectManager?.label ?? 'None Assigned' }}
      </p>
    </button>

    <dominion-modal title="Dominion Team" #teamModal>
      <section body class="flex min-h-[400px] min-w-[400px] flex-col gap-8">
        <dominion-team-member
          title="Project Manager"
          [options]="assignableOptions.projectmanagers"
          [selectedOption]="selectedProjectManager"
          [email]="company?.internalAssigned?.projectmanager?.email"
          [disabled]="!canAssignProjectManager"
          (emitSelectedValue)="assignProjectManager($event)"
        ></dominion-team-member>

        <dominion-team-member
          title="Deployment Manager"
          [options]="assignableOptions.deploymentmanagers"
          [selectedOption]="selectedDeploymentManager"
          [email]="company?.internalAssigned?.deploymentmanager?.email"
          [disabled]="!canAssignDeploymentManager"
          (emitSelectedValue)="assignDeploymentManager($event)"
        ></dominion-team-member>

        <dominion-team-member
          title="Deployment Specialist"
          [options]="assignableOptions.deploymenttechnicians"
          [selectedOption]="selectedDeploymentTechnician"
          [email]="company?.internalAssigned?.deploymenttechnician?.email"
          [disabled]="!canAssignDeploymentTechnician"
          (emitSelectedValue)="assignDeploymentTechnician($event)"
        ></dominion-team-member>
      </section>
    </dominion-modal>
  } @else {
    @if (selectedProjectManager) {
      <button
        (click)="openTeamModal()"
        class="rounded-sm px-2 py-1 transition hover:bg-gray-100"
      >
        <h2 class="text-dms-green text-right text-xs font-light">
          Project Manager
        </h2>
        <p class="text-dms-ultra-grey text-right text-sm font-medium">
          {{ selectedProjectManager.label }}
        </p>
      </button>

      <dominion-modal title="Dominion Team" #teamModal>
        <section body class="min-h-[250px] min-w-[400px]">
          <dominion-team-member
            title="Project Manager"
            [options]="assignableOptions.projectmanagers"
            [selectedOption]="selectedProjectManager"
            [email]="company?.internalAssigned?.projectmanager?.email"
            [disabled]="!canAssignProjectManager"
            (emitSelectedValue)="assignProjectManager($event)"
          ></dominion-team-member>
        </section>
      </dominion-modal>
    }
  }
</section>

import { Pipe, PipeTransform } from '@angular/core';
import { DominionDept } from '@dominion/interfaces';

@Pipe({
  name: 'department',
  standalone: true,
})
export class DepartmentPipe implements PipeTransform {
  transform(dept: DominionDept | string): string {
    switch (dept) {
      case 'pm':
        return 'Project Management';
      case 'accounting':
        return 'Accounting';
      case 'deployment':
        return 'Deployment';
      case 'sales':
        return 'Sales';
      case 'service':
        return 'Service';
      case 'implementation':
        return 'Implementation';
      case 'parts':
        return 'Parts';
      case 'support':
        return 'Support';
      case 'other':
        return 'Other';
      default:
        return dept;
    }
  }
}

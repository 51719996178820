<dominion-auth-root heading="Reset Password">
  @if (this.isErr) {
    <div
      class="mx-auto mb-6 flex w-full max-w-sm flex-col rounded border border-red-200 bg-red-50 p-2 text-sm text-red-700 shadow-md shadow-red-700/10"
      data-testid="passwordForm-gen-err"
    >
      <h2 class="mb-1 text-sm font-medium">{{ errMsg }}</h2>
      <p class="text-sm">
        Reset link expires 1 hour after being sent.
        <a routerLink="/forgot-password" class="underline">Resend email</a>
      </p>
    </div>
  }

  <form
    class="grid w-full grid-cols-1 gap-5 sm:grid-cols-2"
    [formGroup]="passwordForm"
    (ngSubmit)="submit()"
  >
    <!-- PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="password" class="form__label flex items-center gap-1">
        Password
        <dominion-information-button
          [strokeColor]="'stroke-gray-600 hover:stroke-white'"
        >
          <div class="w-64 p-2">
            <p>
              Password must be at least 8 characters long, include at least one
              lowercase letter, one uppercase letter, one digit, and one special
              character (# ? ! &#64; $ % ^ & * -).
            </p>
          </div>
        </dominion-information-button>
      </label>
      <div class="form__control">
        <input
          id="password"
          #password
          type="password"
          placeholder="Password"
          formControlName="password"
          data-testid="passwordForm-password"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          class="absolute right-2"
          tabindex="-1"
          data-testid="passwordForm-showPassword"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>
      @if (
        passwordForm.get('password')?.invalid &&
        passwordForm.get('password')?.touched
      ) {
        <div class="form__error" data-testid="passwordForm-password-err">
          {{ getPasswordErrorMessage() }}
        </div>
      }
    </div>

    <!-- CONFIRM PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="confirmPassword" class="form__label">Confirm Password</label>
      <div class="form__control">
        <input
          id="confirmPassword"
          #confirm
          type="password"
          formControlName="confirmPassword"
          data-testid="passwordForm-confirmPassword"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          class="absolute right-2"
          tabindex="-1"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>

      @if (
        this.passwordForm.errors?.['passwordMismatch'] &&
        this.passwordForm.get('confirmPassword')!.touched
      ) {
        <div class="form__error" data-testid="passwordForm-confirmPassword-err">
          Passwords must match.
        </div>
      }
    </div>

    <div class="sm:col-span-2">
      <!-- SUBMIT -->
      <button
        type="submit"
        class="disabled:bg-dms-light-grey bg-dms-teal h-10 w-full rounded px-2 text-sm text-white disabled:cursor-not-allowed disabled:text-gray-400"
        [disabled]="passwordForm.invalid"
        data-testid="passwordForm-submit"
      >
        Update Password
      </button>
    </div>
  </form>

  <div class="mt-10 flex w-full flex-col gap-1.5">
    <div>
      <a
        routerLink="/login"
        class="hover:text-dms-teal text-sm text-gray-500"
        data-testid="registerForm-login"
      >
        &larr; Back to sign in
      </a>
    </div>
  </div>
</dominion-auth-root>

<ngx-spinner
  type="ball-clip-rotate-pulse"
  size="large"
  data-testid="passwordForm-spinner"
>
  @if (successful) {
    <p style="color: white" data-testid="spinner-changed">
      Password changed successfully!
    </p>
  } @else {
    <p style="color: white" data-testid="spinner-changing">
      Changing password...
    </p>
  }
</ngx-spinner>

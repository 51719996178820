import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { CompanyService } from '../../company/company.service';
import {
  ICompanyReadExternalBrief,
  IInvitedUserRead,
  IRequestUserPayload,
  IUserReadPrivate,
  UserExternalAssignments,
} from '@dominion/interfaces';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../../auth/authentication.service';
import { Router } from '@angular/router';
import { CoreCardComponent } from '../../shared/core-card/core-card.component';
import { LoadingPlaceholderComponent } from '../../shared/loading-placeholder/loading-placeholder.component';
import { ModuleTypePipe } from '../../shared/pipes/module-type.pipe';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { TableComponent } from '../../shared/table/table.component';

@Component({
  selector: 'dominion-user-companies',
  templateUrl: './user-companies.component.html',
  styleUrls: ['./user-companies.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ModuleTypePipe,
    CoreCardComponent,
    LoadingPlaceholderComponent,
    IconGoToComponent,
    TableComponent,
  ],
  providers: [],
})
export class UserCompaniesComponent implements OnChanges, OnInit {
  @HostBinding() class = 'flex flex-row grow';

  @Input() user: IUserReadPrivate | IInvitedUserRead | undefined;

  private requestor: IRequestUserPayload | undefined;
  public companies: ICompanyReadExternalBrief[] = [];
  public isLoadingExternalCompanies: boolean = false;
  public isLoadingAssignments: boolean = false;
  public externalAssignments: UserExternalAssignments[] = [];

  matched: {
    company: ICompanyReadExternalBrief;
    assignment: UserExternalAssignments | null;
  }[] = [];

  private authenticationService = inject(AuthenticationService);
  private companyService = inject(CompanyService);
  private router = inject(Router);

  constructor() {}

  getInternalAssignments() {
    return (this.user as IUserReadPrivate).internal.assignments;
  }

  matchAssignmentsAndCompanies() {
    const matchedArray = [];
    for (const company of this.companies) {
      const assignment = this.externalAssignments.find((assign) => {
        if (assign._id === company._id) {
          return assign;
        }
        return null;
      });
      matchedArray.push({
        company,
        assignment: assignment ? assignment : null,
      });
    }
    this.matched = matchedArray;
  }

  getCompaniesOfExternalUser() {
    if (this.user && this.user.userType === 'external') {
      this.isLoadingExternalCompanies = true;
      this.companyService.getCompaniesOfUser(this.user._id).subscribe({
        next: (companies) => {
          this.companies = companies;
          this.isLoadingExternalCompanies = false;
          this.matchAssignmentsAndCompanies();
        },
      });
    }
  }

  getUserAssignments() {
    if (this.user) {
      this.isLoadingAssignments = true;
      this.companyService
        .getUserAssignments(this.user._id)
        .subscribe((assignments) => {
          this.externalAssignments = assignments as UserExternalAssignments[];
          this.isLoadingAssignments = false;
          this.matchAssignmentsAndCompanies();
        });
    }
  }

  goToCompany(companyId: string) {
    if (this.requestor) {
      if (this.requestor.userType === 'internal') {
        this.router.navigate(['/core/admin/companies', companyId, 'detail']);
      } else {
        this.router.navigate(['/core', companyId, 'dashboard']);
      }
    }
  }

  ngOnInit(): void {
    this.authenticationService.user.subscribe((user) => {
      this.requestor = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user']) {
      this.getCompaniesOfExternalUser();
      this.getUserAssignments();
    }
  }
}

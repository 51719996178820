import { Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[dominionExpandableToggle]',
  standalone: true,
  host: {
    '(click)': 'handleClick()',
  },
})
export class ExpandableToggleDirective {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  handleClick() {
    this.clicked.emit();
  }
}

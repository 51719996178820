import { Component, ElementRef, Input, OnInit } from '@angular/core';

type Rounding =
  | 'rounded-sm'
  | 'rounded'
  | 'rounded-md'
  | 'rounded-lg'
  | 'rounded-xl'
  | 'rounded-full';

@Component({
  selector: 'dominion-loading-placeholder',
  templateUrl: './loading-placeholder.component.html',
  styleUrls: ['./loading-placeholder.component.css'],
  standalone: true,
})
export class LoadingPlaceholderComponent implements OnInit {
  @Input() rounding: Rounding = 'rounded-sm';

  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add(this.rounding);
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

import { Injectable } from '@angular/core';

declare global {
  interface Window {
    _hsq: any[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class HubspotTrackingService {
  constructor() {
    window._hsq = window._hsq || [];
  }

  public trackPageView(page: string): void {
    window._hsq.push(['setPath', page]);
  }
}

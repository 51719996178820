import { Injectable } from '@angular/core';
import { Validators, ValidatorFn } from '@angular/forms';
import { ResponseValidation } from '@dominion/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor() {}

  getValidators(validationRules: ResponseValidation[]): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    validationRules.forEach((rule) => {
      switch (rule.method) {
        case 'required':
          validators.push(Validators.required);
          break;
        case 'min-length':
          validators.push(Validators.minLength(rule.value));
          break;
        case 'max-length':
          validators.push(Validators.maxLength(rule.value));
          break;
        case 'pattern':
          if (rule.value === 'number') {
            validators.push(Validators.pattern('^[0-9]*$'));
            break;
          }
          validators.push(Validators.pattern(rule.value));
          break;
        case 'greater-than':
          validators.push((control) => {
            if (control.value > rule.value) {
              return null;
            }
            return { greaterthan: true };
          });
          break;
        case 'less-than':
          validators.push((control) => {
            if (control.value < rule.value) {
              return null;
            }
            return { lessthan: true };
          });
          break;
        case 'greater-than-or-equal-to':
          validators.push((control) => {
            if (control.value >= rule.value) {
              return null;
            }
            return { greaterthanorequalto: true };
          });
          break;
        case 'less-than-or-equal-to':
          validators.push((control) => {
            if (control.value <= rule.value) {
              return null;
            }
            return { lessthanorequalto: true };
          });
          break;
        default:
          break;
      }
    });

    return validators;
  }

  getComposedValidator(validationRules: ResponseValidation[]): ValidatorFn {
    const validators = this.getValidators(validationRules);
    return Validators.compose(validators) ?? Validators.required;
  }
}

import { SelectOption } from './select-option.interfaces';

export const companyDeptOptions = [
  {
    label: 'Accounting',
    value: 'accounting' as const,
  },
  {
    label: 'Parts',
    value: 'parts' as const,
  },
  {
    label: 'Sales',
    value: 'sales' as const,
  },
  {
    label: 'Service',
    value: 'service' as const,
  },
  {
    label: 'Other',
    value: 'other' as const,
  },
] satisfies SelectOption<string>[];

export type CompanyDept = (typeof companyDeptOptions)[number]['value'];

export const companyDeptList = companyDeptOptions.map((dept) => dept.value);

<dominion-auth-root heading="Register for VOX">
  @if (this.error) {
    <div
      class="mx-auto mb-6 flex w-full max-w-sm justify-center rounded border border-red-200 bg-red-50 p-2 text-sm text-red-700 shadow-md shadow-red-700/10"
      data-testid="registerForm-gen-err"
    >
      {{ this.error.message }}
    </div>
  }

  <form
    class="grid w-full grid-cols-1 gap-5 sm:grid-cols-2"
    [formGroup]="form"
    (ngSubmit)="register()"
  >
    <!-- Email -->
    <div class="flex flex-col gap-1.5 sm:col-span-2">
      <label for="email" class="form__label">Email</label>
      <input
        id="email"
        class="form__control"
        [value]="invitedUser?.email"
        type="email"
        data-testid="form-first"
        disabled
      />
    </div>

    <!-- FIRST NAME -->
    <div class="flex flex-col gap-1.5">
      <label for="firstName" class="form__label">First Name</label>
      <input
        id="firstName"
        class="form__control"
        type="text"
        placeholder="Claire"
        formControlName="firstName"
        data-testid="form-first"
        required
      />
      @if (
        this.form.get('firstName')!.invalid &&
        this.form.get('firstName')!.touched
      ) {
        <div class="form__error" data-testid="form-first-err">
          Please enter your first name.
        </div>
      }
    </div>

    <!-- LAST NAME -->
    <div class="flex flex-col gap-1.5">
      <label for="lastName" class="form__label">Last Name</label>
      <input
        id="lastName"
        class="form__control"
        type="text"
        placeholder="Voyant"
        formControlName="lastName"
        data-testid="form-last"
        required
      />
      @if (
        this.form.get('lastName')!.invalid && this.form.get('lastName')!.touched
      ) {
        <div class="form__error" data-testid="form-last-err">
          Please enter your last name.
        </div>
      }
    </div>

    <!-- JOB TITLE -->
    <div class="flex flex-col gap-1.5">
      <label for="jobTitle" class="form__label">Job Title</label>
      <input
        id="jobTitle"
        class="form__control"
        type="text"
        placeholder="Sales Manager"
        formControlName="jobTitle"
        id="jobTitle"
        name="jobTitle"
        data-testid="form-jobTitle"
      />
      @if (
        this.form.get('jobTitle')!.invalid && this.form.get('jobTitle')!.touched
      ) {
        <div class="form__error" data-testid="form-jobtitle-err">
          Please enter your job title.
        </div>
      }
    </div>

    <!-- DEPARTMENT -->
    <div class="flex flex-col gap-1.5">
      <label for="department" class="form__label">Department</label>
      <select
        id="department"
        class="form__control"
        formControlName="department"
        data-testid="form-department"
      >
        <option value="" disabled selected>Select your department</option>
        <option value="sales" data-testid="form-department-sales">Sales</option>
        <option value="parts">Parts</option>
        <option value="service">Service</option>
        <option value="accounting">Accounting</option>
        <option value="other">Other</option>
      </select>
      @if (
        this.form.get('department')!.invalid &&
        this.form.get('department')!.touched
      ) {
        <div class="form__error" data-testid="form-department-err">
          Please select your department.
        </div>
      }
    </div>

    <!-- PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="password" class="form__label flex items-center gap-1">
        Password
        <dominion-information-button
          [strokeColor]="'stroke-gray-600 hover:stroke-white'"
        >
          <div class="w-64 p-2">
            <p>
              Password must be at least 8 characters long, include at least one
              lowercase letter, one uppercase letter, one digit, and one special
              character (# ? ! &#64; $ % ^ & * -).
            </p>
          </div>
        </dominion-information-button>
      </label>
      <div class="form__control">
        <input
          id="password"
          #password
          type="password"
          formControlName="password"
          data-testid="form-password"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          class="absolute right-2"
          tabindex="-1"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>

      @if (form.get('password')?.invalid && form.get('password')?.touched) {
        <div class="form__error">{{ getPasswordErrorMessage() }}</div>
      }
    </div>

    <!-- CONFIRM PASSWORD -->
    <div class="flex flex-col gap-1.5">
      <label for="confirmPassword" class="form__label">Confirm Password</label>
      <div class="form__control">
        <input
          id="confirmPassword"
          #confirm
          type="password"
          formControlName="confirmPassword"
          data-testid="form-confirmPassword"
          required
        />
        <button
          type="button"
          (click)="togglePassword()"
          class="absolute right-2"
          tabindex="-1"
        >
          @if (showPassword) {
            <span class="text-gray-500">
              <dominion-icon-eye-open size="18"></dominion-icon-eye-open>
            </span>
          } @else {
            <span class="text-gray-500">
              <dominion-icon-eye-closed size="18"></dominion-icon-eye-closed>
            </span>
          }
        </button>
      </div>

      @if (
        form.errors?.['passwordMismatch'] &&
        form.get('confirmPassword')!.touched
      ) {
        <div class="form__error" data-testid="form-confirmPassword-err">
          Passwords must match.
        </div>
      }
    </div>

    <div class="sm:col-span-2">
      <!-- SUBMIT -->
      <button
        type="submit"
        class="disabled:bg-dms-light-grey bg-dms-teal h-10 w-full rounded px-2 text-sm text-white disabled:cursor-not-allowed disabled:text-gray-400"
        [disabled]="form.invalid"
        data-testid="form-submit"
      >
        Create Account
      </button>
    </div>
  </form>

  <div class="mt-10 flex w-full flex-col gap-1.5">
    <div>
      <a
        routerLink="/login"
        class="hover:text-dms-teal text-sm text-gray-500"
        data-testid="form-login"
      >
        Already have an account? Sign in
      </a>
    </div>
  </div>
</dominion-auth-root>

<ngx-spinner
  name="spin"
  type="ball-clip-rotate-pulse"
  size="large"
  data-testid="form-spinner"
>
  <p style="color: white" data-testid="spinner">Registering...</p>
</ngx-spinner>

import { Injectable, OnInit } from '@angular/core';
import { Authorization } from '@dominion/authorization';
import { AuthenticationService } from './authentication.service';
import {
  AuthorizationStrategy,
  IRequestUserPayload,
  IUserAuthorizations,
} from '@dominion/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private _user: IRequestUserPayload | undefined;
  private _authorizations: IUserAuthorizations | undefined;

  user: BehaviorSubject<IRequestUserPayload | undefined> = new BehaviorSubject<
    IRequestUserPayload | undefined
  >(undefined);
  authorizations: BehaviorSubject<IUserAuthorizations | undefined> =
    new BehaviorSubject<IUserAuthorizations | undefined>(undefined);

  constructor(
    private readonly authorization: Authorization,
    private readonly authentication: AuthenticationService,
  ) {
    this.subscribeToUser();
    this.subscribeToAuthorizations();
  }

  /*
    the applicable user will always be the user in the authentication service
    and the applicable authorizations object will always be that included in the authentication service
  */

  // make sure this service is notified anytime the user changes in the authentication service
  subscribeToUser() {
    this.authentication.user.subscribe({
      next: (user) => {
        this.user.next(user);
        this._user = user;
      },
    });
  }

  // make sure this service is notified anytime the authorizations change in the authentication service
  subscribeToAuthorizations() {
    this.authentication.authorizations.subscribe({
      next: (authorizations) => {
        this.authorizations.next(authorizations);
        this._authorizations = authorizations;
      },
    });
  }

  // check to see if the user is authorized for the given strategy
  isAuthorized(strategy: AuthorizationStrategy): boolean {
    if (!this._authorizations) {
      return false;
    }
    return this.authorization.isAuthorized(this._authorizations, strategy);
  }
}

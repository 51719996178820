import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[dominionStandardFormError]',
})
export class StandardFormErrorDirective implements OnInit {
  constructor(private el: ElementRef) {}

  addClasses() {
    const native = this.el.nativeElement;
    native.classList.add('text-red-500', 'text-sm', 'mb-1');
  }

  ngOnInit(): void {
    this.addClasses();
  }
}

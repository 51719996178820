import { Directive } from '@angular/core';

@Directive({
  selector: '[dominionDiscoveryNextBtn]',
  standalone: true,
  host: {
    class:
      'flex flex-row basis-1/3 bg-dms-green [&.disabled]:bg-gray-200 [&.disabled]:text-gray-600 text-white text-center justify-center cursor-pointer [&.disabled]:cursor-not-allowed ring-0 rounded-sm p-2 outline-none hover:ring focus:ring focus:ring-dms-light-green hover:ring-dms-light-green hover:ring-offset-1 focus:ring-offset-1 [&.disabled]:ring-0 [&.disabled]:hover:ring-0 [&.disabled]:focus:ring-0 [&.disabled]:hover:ring-offset-0 [&.disabled]:focus:ring-offset-0',
  },
})
export class DiscoveryNextBtnDirective {
  constructor() {}
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../company/company.service';
import { ActivatedRoute } from '@angular/router';
import { DiscoverySideNavComponent } from '../discovery-side-nav/discovery-side-nav.component';
import { InformationButtonComponent } from '../shared/information-button/information-button.component';
import {
  IFileGetDto,
  AuthorizationStrategy,
  ICompanyReadFull,
  IFileUploadReference,
  IRequestUserPayload,
} from '@dominion/interfaces';
import { IconDownloadComponent } from '../icons/icon-download.component';
import { saveAs } from 'file-saver';
import { TableComponent } from '../shared/table/table.component';
import { AuthenticationService } from '../auth/authentication.service';
import { combineLatest } from 'rxjs';
import {
  DominionAnyStrategy,
  LocalCompanyDeptManagerStrategy,
} from '@dominion/authorization';
import { AuthorizationService } from '../auth/authorization.service';

@Component({
  selector: 'dominion-company-documents',
  standalone: true,
  imports: [
    CommonModule,
    DiscoverySideNavComponent,
    InformationButtonComponent,
    IconDownloadComponent,
    TableComponent,
  ],
  templateUrl: './company-documents.component.html',
  styleUrls: ['./company-documents.component.css'],
})
export class CompanyDocumentsComponent implements OnInit {
  public user: IRequestUserPayload | undefined;

  companyId: string;
  company: ICompanyReadFull;
  isLoadingDocuments = false;
  filteredDocuments: IFileUploadReference[] = [];

  @HostBinding('class') class =
    'container mx-auto flex-auto overflow-hidden flex flex-row';

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private authorization: AuthorizationService,
  ) {}

  getCompany() {
    this.isLoadingDocuments = true;
    this.companyService.getCompany(this.companyId).subscribe({
      next: (res) => {
        this.company = res;

        if (this.company && this.user) {
          this.loadDocuments();
        }
      },
    });
  }

  downloadFile(fileRef: IFileUploadReference) {
    const dto: IFileGetDto = {
      companyId: fileRef.companyId,
      fileId: fileRef._id,
    };
    this.companyService.getFile(dto).subscribe({
      next: (res) => {
        const asBlob = new Blob([res]);
        saveAs(asBlob, fileRef.name);
      },
    });
  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.authentication.user]).subscribe(
      ([params, user]) => {
        this.companyId = params['companyid'];
        this.user = user;

        this.getCompany();
      },
    );
  }

  private loadDocuments() {
    if (!this.company?.documents || !this.user) {
      this.filteredDocuments = [];
      this.isLoadingDocuments = false;
      return;
    }

    this.filteredDocuments = this.company.documents.filter((document) => {
      const strategies: AuthorizationStrategy = [
        new DominionAnyStrategy(),
        new LocalCompanyDeptManagerStrategy(
          document.companyId,
          document.moduleId,
        ),
      ];
      return this.authorization.isAuthorized(strategies);
    });

    this.isLoadingDocuments = false;
  }

  get documents() {
    return this.filteredDocuments;
  }
}

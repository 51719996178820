import { Component, Input } from '@angular/core';

@Component({
  selector: 'dominion-core-create-btn',
  templateUrl: './core-create-btn.component.html',
  styleUrls: ['./core-create-btn.component.css'],
})
export class CoreCreateBtnComponent {
  @Input() text: string = 'New';

  constructor() {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dominion-table-nav',
  templateUrl: './table-nav.component.html',
  styleUrls: ['./table-nav.component.css'],
})
export class TableNavComponent {
  @Input() public page: number;
  @Input() public pages: number;
  @Input() public results?: number;
  @Input() public resultsPerPage?: number;

  @Output() public nextPage = new EventEmitter<void>();
  @Output() public prevPage = new EventEmitter<void>();
  @Output() public firstPage = new EventEmitter<void>();
  @Output() public lastPage = new EventEmitter<void>();

  next() {
    if (this.page !== this.pages) {
      this.nextPage.emit();
    }
  }

  prev() {
    if (this.page !== 1) {
      this.prevPage.emit();
    }
  }

  first() {
    if (this.page !== 1) {
      this.firstPage.emit();
    }
  }

  last() {
    if (this.page !== this.pages) {
      this.lastPage.emit();
    }
  }

  getResultsInfo(): string {
    if (!this.results || !this.resultsPerPage || this.pages === 0) {
      return '';
    }

    const startIndex = (this.page - 1) * this.resultsPerPage + 1;
    const endIndex = Math.min(this.page * this.resultsPerPage, this.results);

    return `${startIndex} - ${endIndex} of ${this.results}`;
  }
}

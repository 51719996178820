<div class="flex items-center gap-4">
  <dominion-table-nav
    [page]="currentPage"
    [pages]="pageCount"
    [results]="totalResults"
    [resultsPerPage]="resultsPerPage"
    (firstPage)="firstPage()"
    (nextPage)="nextPage()"
    (prevPage)="previousPage()"
    (lastPage)="lastPage()"
  ></dominion-table-nav>
</div>

<div class="h-6"></div>

<dominion-table [isLoading]="isLoadingUsers" [isEmpty]="users.length === 0">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Type</th>
      <th scope="col">Job Title</th>
      <th scope="col">Department</th>
      <th scope="col"><span class="sr-only">View</span></th>
    </tr>
  </thead>
  <tbody>
    @for (user of users; track user._id) {
      <tr
        [routerLink]="['/core/admin/users', user._id]"
        [attr.data-testid]="'user-row-' + $index"
      >
        <td>
          <div class="flex flex-col justify-center gap-0.5">
            <div class="flex items-center gap-2 font-semibold">
              {{ user.firstName }} {{ user.lastName }}
              @if (user.isDeactivated) {
                <div
                  data-testid="user-deactivated-badge"
                  class="rounded-sm border-red-600/20 bg-red-50 px-1 py-px text-xs font-medium text-red-600"
                >
                  Deactivated
                </div>
              }
            </div>
            @if (user.email) {
              <div class="text-xs font-medium text-gray-500">
                {{ user.email }}
              </div>
            }
          </div>
        </td>
        <td>
          @if (user.userType) {
            <div
              class="inline-flex items-center justify-center rounded-md border px-2 py-1 text-xs font-medium capitalize"
              [ngClass]="{
                'border-gray-600/20 bg-gray-50 text-gray-700':
                  user.userType === 'internal',
                'text-dms-green bg-dms-light-green/10 border-dms-light-green/40':
                  user.userType === 'external'
              }"
            >
              {{ user.userType }}
            </div>
          } @else {
            <span class="text-gray-400">&mdash;</span>
          }
        </td>
        <td>
          @if (user.jobTitle) {
            {{ user.jobTitle }}
          } @else {
            <span class="text-gray-400">&mdash;</span>
          }
        </td>
        <td>
          @if (user.department) {
            <span class="capitalize">{{ user.department | department }}</span>
          } @else {
            <span class="text-gray-400">&mdash;</span>
          }
        </td>
        <td>
          <a
            [routerLink]="['/core/admin/users', user._id]"
            class="text-dms-green hover:bg-dms-light-green/20 group flex h-7 w-7 cursor-pointer items-center truncate rounded-sm p-[6px] text-sm font-medium"
          >
            <dominion-icon-go-to
              strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
            ></dominion-icon-go-to>
          </a>
        </td>
      </tr>
    }
  </tbody>
</dominion-table>

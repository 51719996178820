import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { IUserCreateDto } from '@dominion/interfaces';
import { Observer, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { passwordMatchValidator } from '../../validators/password-match.validator';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { APP_VERSION } from 'apps/vox-fe/src/version';
import { IconEyeOpenComponent } from '../../icons/icon-eye-open.component';
import { IconEyeClosedComponent } from '../../icons/icon-eye-closed.component';
import { AuthRootComponent } from '../auth-root/auth-root.component';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';
import {
  passwordErrorMessages,
  passwordRequirementValidator,
} from '../../validators/password-requirement.validator';

@Component({
  selector: 'dominion-register',
  templateUrl: './register.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterLink,
    AuthRootComponent,
    IconEyeOpenComponent,
    IconEyeClosedComponent,
    InformationButtonComponent,
  ],
})
export class RegisterComponent implements OnDestroy {
  @HostBinding('class') class = 'block h-full w-full overflow-auto';

  public showPassword = false;
  public registerForm!: FormGroup;
  public error: HttpErrorResponse;
  public appVersion = APP_VERSION;

  private registerObserver: Observer<void> = {
    next: () => {
      this.registerSuccess();
    },
    error: (err: HttpErrorResponse) => {
      this.spinner.hide('spin');
      this.error = err;
    },
    complete: () => {
      return;
    },
  };

  private registerSubscription: Subscription;

  @ViewChild('password') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('confirm') confirmInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
    this.registerForm = fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(/.+@dominiondms\.com$|.+@fourhundredwest.com$/),
          ],
        ],
        jobTitle: [''],
        department: ['', Validators.required],
        password: ['', [Validators.required, passwordRequirementValidator()]],
        confirmPassword: [
          '',
          [Validators.required, passwordRequirementValidator()],
        ],
        userType: ['internal', Validators.required], // static for now
        role: [undefined], // for now
      },
      { validators: passwordMatchValidator },
    );
  }

  togglePassword() {
    if (this.showPassword) {
      this.showPassword = false;
      this.confirmInput.nativeElement.setAttribute('type', 'password');
      this.passwordInput.nativeElement.setAttribute('type', 'password');
    } else {
      this.showPassword = true;
      this.confirmInput.nativeElement.setAttribute('type', 'text');
      this.passwordInput.nativeElement.setAttribute('type', 'text');
    }
  }

  private buildRegisterDto(): IUserCreateDto {
    const dto: IUserCreateDto = {
      firstName: this.registerForm.get('firstName')?.value,
      lastName: this.registerForm.get('lastName')?.value,
      email: this.registerForm.get('email')?.value,
      jobTitle: this.registerForm.get('jobTitle')?.value,
      department: this.registerForm.get('department')?.value,
      password: this.registerForm.get('password')?.value,
      userType: this.registerForm.get('userType')?.value,
    };
    return dto;
  }

  public register() {
    if (this.registerForm.valid) {
      this.spinner.show('spin');
      const dto = this.buildRegisterDto();
      this.registerSubscription = this.authService
        .register(dto)
        .subscribe(this.registerObserver);
    }
    return;
  }

  private registerSuccess() {
    this.spinner.hide('spin');
    this.router.navigateByUrl('registration-success');
    return;
  }

  ngOnDestroy(): void {
    this.registerSubscription ? this.registerSubscription.unsubscribe() : null;
  }

  getPasswordErrorMessage() {
    const control = this.registerForm.get('password');
    if (control?.hasError('required')) {
      return passwordErrorMessages['required'];
    }
    if (control?.hasError('passwordRequirements')) {
      const firstErrorKey = Object.keys(
        control.errors?.['passwordRequirements'],
      )[0] as keyof typeof passwordErrorMessages;
      return passwordErrorMessages[firstErrorKey];
    }
    return null;
  }
}

<div [id]="module ? module.moduleType : 'module'" class="flex-1 p-4">
  <div class="flex max-w-4xl flex-wrap items-center justify-between">
    <h2 class="text-dms-teal text-xl font-bold">
      @if (module) {
        {{ module.moduleType | moduleType }}
      }
    </h2>
    @if (assignedUser && assignedUser.userType === 'external') {
      <a
        [routerLink]="getAssignedUserPath()"
        class="flex items-center whitespace-nowrap rounded-md border bg-gray-50 px-3 py-1.5 text-sm text-gray-700 shadow-sm transition hover:bg-gray-100"
      >
        <span>Assigned to</span>
        <div
          class="bg-dms-light-green ml-2 mr-1 flex h-3.5 w-3.5 items-center justify-center rounded-sm text-xs font-medium uppercase text-white"
        >
          @if (assignedUser.firstName) {
            {{ assignedUser.firstName.substring(0, 1) }}
          } @else {
            {{ assignedUser.email.substring(0, 1) }}
          }
        </div>
        <p class="text-dms-dark-green font-medium">
          @if (assignedUser.firstName) {
            {{ assignedUser.firstName }}
            @if (assignedUser.lastName) {
              {{ assignedUser.lastName }}
            }
          } @else {
            {{ assignedUser.email }}
          }
        </p>
      </a>
    } @else {
      <div
        class="flex items-center whitespace-nowrap rounded-md border bg-gray-50 px-3 py-1.5 text-sm text-gray-700"
      >
        <span>Unassigned</span>
      </div>
    }
  </div>

  <div class="h-6"></div>

  @if (module && submodules.length > 0) {
    <div class="flex max-w-4xl flex-col gap-6">
      @for (
        submodule of submodules;
        track submodule ? submodule._id : submoduleIndex;
        let submoduleIndex = $index
      ) {
        @if (submodule) {
          @if (
            user?.userType === 'internal' ||
            module.submodules[submodule.submoduleType].isAvailable
          ) {
            <dominion-core-card
              [id]="submodule.submoduleType"
              [title]="
                (submodule.submoduleType | submodulePhase) +
                ' (' +
                submodule.submoduleType +
                ')'
              "
              [titleDetail]="
                module.submodules[submodule.submoduleType].dueDate
                  ? 'Due ' +
                    (module.submodules[submodule.submoduleType].dueDate
                      | date: 'MM/dd/yyyy')
                  : undefined
              "
              [hasPadding]="false"
            >
              <div class="flex w-full flex-col">
                <dominion-table
                  id="phase-{{ submoduleIndex }}-groups-table"
                  [compact]="true"
                  [hideFrame]="true"
                >
                  <thead>
                    <tr>
                      <th scope="col">Question Group</th>
                      <th scope="col">Progress</th>
                      <th scope="col">
                        <span class="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (
                      group of maps[module.moduleType][submodule.submoduleType][
                        'groups'
                      ] | groupPipe;
                      track group.groupUrl
                    ) {
                      <tr class="[&>td]:last:border-none">
                        <td>
                          <span
                            class="{{
                              getGroupCompletionStatus(
                                submodule,
                                group.groupUrl
                              ) === 100
                                ? 'text-gray-500 font-normal'
                                : 'font-semibold'
                            }}"
                          >
                            {{ group.groupLabel }}
                          </span>
                        </td>
                        <td>
                          @if (
                            getGroupCompletionStatus(
                              submodule,
                              group.groupUrl
                            ) === 100
                          ) {
                            <span class="text-gray-500">Complete</span>
                          } @else if (
                            getGroupCompletionStatus(
                              submodule,
                              group.groupUrl
                            ) === 0
                          ) {
                            <span class="text-gray-400">&mdash;</span>
                          } @else {
                            <dominion-completion-status-bar
                              class="h-2.5 max-w-[100px]"
                              [completionColor]="'bg-dms-green'"
                              [bgColor]="'bg-gray-300'"
                              [rounded]="'rounded-sm'"
                              [percentage]="
                                getGroupCompletionStatus(
                                  submodule,
                                  group.groupUrl
                                )
                              "
                            ></dominion-completion-status-bar>
                          }
                        </td>
                        <td align="right">
                          <div
                            class="flex max-w-full items-center justify-end gap-6"
                          >
                            <div class="w-28">
                              @switch (
                                getGroupActionLabel(submodule, group.groupUrl)
                              ) {
                                @case ('Continue') {
                                  <a
                                    [routerLink]="
                                      getGroupLink(submodule, group.groupUrl)
                                    "
                                    class="bg-dms-green hover:bg-dms-green/90 border-dms-green flex items-center justify-center gap-2 rounded-full border px-3 py-1 text-sm font-medium text-white transition"
                                    >Continue</a
                                  >
                                }
                                @case ('Revisit') {
                                  <a
                                    [routerLink]="
                                      getGroupLink(submodule, group.groupUrl)
                                    "
                                    class="flex items-center justify-center gap-2 rounded-full border border-gray-600/10 bg-gray-100 px-3 py-1 text-sm font-medium text-gray-600 transition hover:bg-gray-200"
                                    >Revisit</a
                                  >
                                }
                                @default {
                                  <a
                                    [routerLink]="
                                      getGroupLink(submodule, group.groupUrl)
                                    "
                                    class="bg-dms-green hover:bg-dms-green/90 border-dms-green flex items-center justify-center gap-2 rounded-full border px-3 py-1 text-sm font-medium text-white transition"
                                  >
                                    Start
                                  </a>
                                }
                              }
                            </div>

                            <button
                              (click)="openResponseModal(group, submoduleIndex)"
                              class="flex items-center justify-center px-3 py-1 text-[13px] font-medium text-gray-500 transition hover:underline"
                            >
                              View Summary
                            </button>
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </dominion-table>
              </div>
            </dominion-core-card>

            <dominion-modal
              #responseModal
              [title]="modalGroup?.groupLabel || 'Group'"
            >
              @if (modalGroup) {
                <div
                  body
                  class="group flex min-h-[500px] min-w-[750px] flex-col"
                >
                  @if (!hasResponses[modalGroup.groupUrl]) {
                    <div
                      class="flex flex-1 flex-col items-center justify-center gap-4 rounded-sm p-4 font-medium antialiased"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-8 w-8 text-gray-400"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                        />
                      </svg>

                      <div class="text-gray-600">No entries found</div>
                    </div>
                  }
                  @for (
                    question of modalGroup.questions | questionPipe;
                    track question.questionKey
                  ) {
                    @if (
                      (question | questionDataPipe: submodule.data) === null
                    ) {
                      <!-- Hide empty questions & responses -->
                    } @else {
                      <div
                        class="-mx-4 flex flex-col gap-1 border-b px-4 py-3 transition hover:bg-gray-100"
                      >
                        <div class="max-w-prose italic text-gray-700">
                          {{ question.prompt | promptPipe }}
                        </div>
                        <div class="max-w-prose whitespace-pre-wrap">
                          <span
                            class="text-[15px] font-semibold"
                            [title]="
                              question | questionDataPipe: submodule.data
                            "
                            >{{
                              question | questionDataPipe: submodule.data
                            }}</span
                          >
                        </div>
                      </div>
                    }
                  }
                  <div class="h-4 py-4"></div>
                </div>
              }
            </dominion-modal>
          } @else {
            <dominion-core-card
              [id]="submodule.submoduleType"
              [title]="
                'Phase ' +
                (submoduleIndex + 1) +
                ' (' +
                submodule.submoduleType +
                ')'
              "
              [titleDetail]="
                module.submodules[submodule.submoduleType].dueDate
                  ? 'Due ' +
                    (module.submodules[submodule.submoduleType].dueDate
                      | date: 'MM/dd/yyyy')
                  : undefined
              "
              class="pointer-events-none cursor-not-allowed opacity-60"
            >
              <div class="flex w-full items-center gap-2 p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-4 w-4 text-gray-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                <span class="text-sm text-gray-600">Locked</span>
              </div>
            </dominion-core-card>
          }
        }
      }

      @if (
        module.moduleType === 'infrastructure' || module.moduleType === 'fi'
      ) {
        <!-- There is no phase 3 to show here -->
      } @else {
        <dominion-locked-submodule-card
          title="Phase 3 (default)"
        ></dominion-locked-submodule-card>
      }
    </div>
  }

  <div class="h-10"></div>
</div>

<div class="flex flex-1 flex-row space-x-[1px] {{ maxRowWidth }}">
  <button
    (click)="emitCancel.emit()"
    class="basis-1/2 flex-1 grow rounded-tl-sm rounded-bl-sm bg-gray-300 text-gray-700 {{
      paddingX + ' ' + paddingY
    }}"
  >
    Cancel
  </button>
  <button
    (click)="emitDelete.emit()"
    class="basis-1/2 flex-1 rounded-tr-sm rounded-br-sm bg-red-800 text-white {{
      paddingX + ' ' + paddingY
    }}"
  >
    Delete
  </button>
</div>

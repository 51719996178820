import { Component } from '@angular/core';
import { fadeInFactory } from '../../shared/animations/fade-in.animation';

@Component({
  selector: 'dominion-internal-support',
  templateUrl: './admin-support.component.html',
  styleUrls: ['./admin-support.component.css'],
  animations: [fadeInFactory(0.25, 0.5)],
  host: {
    class: 'flex flex-col basis-5/6',
  },
})
export class AdminSupportComponent {}

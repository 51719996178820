import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/core-submodule.interfaces';

export type TPayrollPrelimGroupIdentifiers =
  | 'group401k'
  | 'groupACH'
  | 'groupPayCycle'
  | 'groupVendors';

export type TPayrollPrelimDataKeys =
  | 'has401kTransmission'
  | 'maxContribution'
  | 'bankName401k'
  | 'bank401kContact'
  | 'compileTo401kExport'
  | 'replica401k'
  | 'doesACH'
  | 'originatingBankName'
  | 'bankRoutingNumber'
  | 'destinationBankName'
  | 'knowsACHPreamble'
  | 'preamble'
  | 'nachaFile'
  | 'bankType'
  | 'sendCompanyAccountInfo'
  | 'sendACH220'
  | 'leftJustifyNames'
  | 'defaultToDirectDeposit'
  | 'electronicTimeClockVendor'
  | 'payCycle'
  | 'dayOfWeek'
  | 'printPayrollOnsite'
  | 'technicianMultiDealership'
  | 'multiCompanyPayrollLocation'
  | 'statePayrollReports';

const payrollPrelimGroupMap = {
  moduleType: 'payroll',
  submoduleType: 'preliminary',
  discriminator: 'payroll-preliminary',
  groups: {
    group401k: [
      'has401kTransmission',
      'maxContribution',
      'bankName401k',
      'bank401kContact',
      'compileTo401kExport',
      'replica401k',
    ],
    groupACH: [
      'doesACH',
      'originatingBankName',
      'bankRoutingNumber',
      'destinationBankName',
      'knowsACHPreamble',
      'preamble',
      'nachaFile',
      'bankType',
      'sendCompanyAccountInfo',
      'sendACH220',
      'leftJustifyNames',
      'defaultToDirectDeposit',
    ],
    groupPayCycle: [
      'payCycle',
      'dayOfWeek',
      'printPayrollOnsite',
      'technicianMultiDealership',
      'multiCompanyPayrollLocation',
      'statePayrollReports',
    ],
    groupVendors: ['electronicTimeClockVendor'],
  },
} satisfies TSubmoduleGroupMap<
  TPayrollPrelimGroupIdentifiers,
  TPayrollPrelimDataKeys
>;

export type TPayrollPrelimGroupMap = typeof payrollPrelimGroupMap;

export class PayrollPreliminaryData
  implements TDataDefinition<TPayrollPrelimDataKeys>
{
  // 401K
  // group401k
  has401kTransmission = new DataInitializerBasic<boolean>();
  maxContribution = new DataInitializerBasic<string>();
  bankName401k = new DataInitializerBasic<string>();
  bank401kContact = new DataInitializerBasic<string>();
  compileTo401kExport = new DataInitializerBasic<boolean>();
  replica401k = new DataInitializerBasic<IFileUploadReference[]>([]);

  // ACH
  // groupACH
  doesACH = new DataInitializerBasic<boolean>();
  originatingBankName = new DataInitializerBasic<string>();
  bankRoutingNumber = new DataInitializerBasic<number>();
  destinationBankName = new DataInitializerBasic<string>();
  knowsACHPreamble = new DataInitializerBasic<boolean>();
  preamble = new DataInitializerBasic<string>();
  nachaFile = new DataInitializerBasic<IFileUploadReference[]>([]);
  bankType = new DataInitializerBasic<string>();
  sendCompanyAccountInfo = new DataInitializerBasic<boolean>();
  sendACH220 = new DataInitializerBasic<boolean>();
  leftJustifyNames = new DataInitializerBasic<boolean>();
  defaultToDirectDeposit = new DataInitializerBasic<boolean>();

  // Pay Cycle
  // groupPayCycle
  electronicTimeClockVendor = new DataInitializerBasic<string>();
  payCycle = new DataInitializerBasic<
    'weekly' | 'monthly' | 'biweekly' | 'semi-monthly'
  >();
  dayOfWeek = new DataInitializerBasic<
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday'
  >();
  printPayrollOnsite = new DataInitializerBasic<boolean>();
  technicianMultiDealership = new DataInitializerBasic<boolean>();
  multiCompanyPayrollLocation = new DataInitializerBasic<
    'centralized' | 'dispersed'
  >();
  statePayrollReports = new DataInitializerBasic<boolean>();
}

export const payrollPreliminaryStructure: Structures['payroll-preliminary'] = {
  moduleType: 'payroll',
  submoduleType: 'preliminary',
  discriminator: 'payroll-preliminary',
  groups: {
    group401k: {
      groupLabel: '401K',
      groupType: 'standard',
      groupUrl: 'group401k',
      questions: {
        has401kTransmission: {
          questionKey: 'has401kTransmission',
          label: 'Has 401K Transmission',
          prompt: [
            {
              text: 'Do you have a 401K transmission?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'maxContribution',
                'bankName401k',
                'bank401kContact',
                'compileTo401kExport',
                'replica401k',
              ],
            },
          ],
        },
        maxContribution: {
          questionKey: 'maxContribution',
          label: 'Max Contribution',
          prompt: [
            {
              text: 'What is your maximum 401K company contribution?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        bankName401k: {
          questionKey: 'bankName401k',
          label: 'Bank Name 401K',
          prompt: [
            {
              text: 'Please provide the 401K bank name',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        bank401kContact: {
          questionKey: 'bank401kContact',
          label: 'Bank 401K Contact',
          prompt: [
            {
              text: 'Please provide the 401K bank contact information',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        compileTo401kExport: {
          questionKey: 'compileTo401kExport',
          label: 'Compile to 401K Export',
          prompt: [
            {
              text: 'Do you plan to compile to the 401K export during your payroll process',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        replica401k: {
          questionKey: 'replica401k',
          label: 'Replica 401K',
          prompt: [
            {
              text: 'Please provide an exact replica of your 401K file for submission',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupACH: {
      groupLabel: 'Direct Deposit/ACH',
      groupType: 'standard',
      groupUrl: 'groupACH',
      questions: {
        doesACH: {
          questionKey: 'doesACH',
          label: 'Does ACH',
          prompt: [
            {
              text: 'Will you be doing direct deposit (ACH)?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'originatingBankName',
                'bankRoutingNumber',
                'destinationBankName',
                'knowsACHPreamble',
                'bankType',
                'sendCompanyAccountInfo',
                'sendACH220',
                'leftJustifyNames',
                'defaultToDirectDeposit',
              ],
            },
          ],
        },
        originatingBankName: {
          questionKey: 'originatingBankName',
          label: 'Originating Bank Name',
          prompt: [
            {
              text: 'What is the originating bank name?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        bankRoutingNumber: {
          questionKey: 'bankRoutingNumber',
          label: 'Bank Routing Number',
          prompt: [
            {
              text: 'What is the destination bank routing number?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              text: 'Please enter a 9-digit number',
              attributes: null,
            },
          ],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [
            { method: 'required', value: true },
            { method: 'min-length', value: 9 },
            { method: 'max-length', value: 9 },
            { method: 'pattern', value: 'number' },
          ],
          dependencies: [],
        },
        destinationBankName: {
          questionKey: 'destinationBankName',
          label: 'Destination Bank Name',
          prompt: [
            {
              text: 'What is the destination bank name?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        knowsACHPreamble: {
          questionKey: 'knowsACHPreamble',
          label: 'Knows ACH Premable',
          prompt: [
            {
              text: 'Do you know the ACH preamble for your bank?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['preamble'],
            },
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['nachaFile'],
            },
          ],
        },
        preamble: {
          questionKey: 'preamble',
          label: 'Preamble',
          prompt: [
            {
              text: 'What is the ACH preamble (if required)?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        nachaFile: {
          questionKey: 'nachaFile',
          label: 'Nacha File',
          prompt: [
            {
              text: 'Please upload the NACHA file for your bank',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        bankType: {
          questionKey: 'bankType',
          label: 'Bank Type',
          prompt: [
            {
              text: 'What is the ACH bank type? (Default is 0)',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        sendCompanyAccountInfo: {
          questionKey: 'sendCompanyAccountInfo',
          label: 'Send Company Account Info',
          prompt: [
            {
              text: 'Do you want to send company account information?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        sendACH220: {
          questionKey: 'sendACH220',
          label: 'Send ACH 220',
          prompt: [
            {
              text: 'Do you want to send ACH service class 220 (credits only)?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        leftJustifyNames: {
          questionKey: 'leftJustifyNames',
          label: 'Left Justify Names',
          prompt: [
            {
              text: 'Do you want to left justify employee names in the ACH file?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        defaultToDirectDeposit: {
          questionKey: 'defaultToDirectDeposit',
          label: 'Default to Direct Deposit',
          prompt: [
            {
              text: 'Should new employees default to direct deposit?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'dependent',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPayCycle: {
      groupLabel: 'Pay Cycle',
      groupType: 'standard',
      groupUrl: 'groupPayCycle',
      questions: {
        payCycle: {
          questionKey: 'payCycle',
          label: 'Pay Cycle',
          prompt: [
            {
              text: 'What pay cycle do you follow?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: 'weekly',
              label: 'Weekly',
            },
            {
              value: 'monthly',
              label: 'Monthly',
            },
            {
              value: 'biweekly',
              label: 'Biweekly',
            },
            {
              value: 'semi-monthly',
              label: 'Semi-monthly',
            },
          ],
          validation: [],
          dependencies: [],
        },
        dayOfWeek: {
          questionKey: 'dayOfWeek',
          label: 'Day of Week',
          prompt: [
            {
              text: 'What day of the week do you start your payroll process?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'dropdown',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: 'monday',
              label: 'Monday',
            },
            {
              value: 'tuesday',
              label: 'Tuesday',
            },
            {
              value: 'wednesday',
              label: 'Wednesday',
            },
            {
              value: 'thursday',
              label: 'Thursday',
            },
            {
              value: 'friday',
              label: 'Friday',
            },
            {
              value: 'saturday',
              label: 'Saturday',
            },
            {
              value: 'sunday',
              label: 'Sunday',
            },
          ],
          validation: [],
          dependencies: [],
        },
        printPayrollOnsite: {
          questionKey: 'printPayrollOnsite',
          label: 'Print Payroll Onsite',
          prompt: [
            {
              text: 'Do you print payroll checks onsite?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        technicianMultiDealership: {
          questionKey: 'technicianMultiDealership',
          label: 'Technician Multi Dealership',
          prompt: [
            {
              text: 'Can a technician work at more than one dealership during a single pay period?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
        multiCompanyPayrollLocation: {
          questionKey: 'multiCompanyPayrollLocation',
          label: 'Multi Company Payroll Location',
          prompt: [
            {
              text: 'For multi-location dealers, is payroll centralized or handled at each dealership?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: 'centralized',
              label: 'Centralized',
            },
            {
              value: 'dispersed',
              label: 'Dispersed',
            },
          ],
          validation: [],
          dependencies: [],
        },
        statePayrollReports: {
          questionKey: 'statePayrollReports',
          label: 'State Payroll Reports',
          prompt: [
            {
              text: 'Do you have state-specific payroll reports that are required when running payroll?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupVendors: {
      groupLabel: 'Vendors',
      groupType: 'vendor',
      groupUrl: 'groupVendors',
      questions: {
        electronicTimeClockVendor: {
          questionKey: 'electronicTimeClockVendor',
          label: 'Electronic Time Clock Vendor',
          prompt: [
            {
              text: 'Which vendor do you use for your electronic time clock?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
};

export type TPayrollPrelimStructure = typeof payrollPreliminaryStructure;

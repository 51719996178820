import { Pipe, PipeTransform } from '@angular/core';
import { ICoreModule } from '@dominion/interfaces';

@Pipe({
  name: 'moduleCompletion',
  standalone: true,
})
export class ModuleCompletionPipe implements PipeTransform {
  transform(module: ICoreModule) {
    if (module.moduleType !== 'infrastructure' && module.moduleType !== 'fi') {
      const prelimTotal = module.submodules['preliminary'].completion.total;
      const prelimComplete =
        module.submodules['preliminary'].completion.complete;

      const maintenanceTotal = module.submodules['maintenance'].completion
        ? module.submodules['maintenance'].completion.total
        : 0;
      const maintenanceComplete = module.submodules['maintenance'].completion
        ? module.submodules['maintenance'].completion.complete
        : 0;

      const defaultTotal = module.submodules['default'].completion
        ? module.submodules['default'].completion.total
        : 0;
      const defaultComplete = module.submodules['default'].completion
        ? module.submodules['default'].completion.complete
        : 0;

      let total = 0;
      let complete = 0;

      if (module.submodules['preliminary'].isAvailable) {
        total += prelimTotal;
        complete += prelimComplete;
      }
      if (module.submodules['maintenance'].isAvailable) {
        total += maintenanceTotal;
        complete += maintenanceComplete;
      }
      if (module.submodules['default'].isAvailable) {
        total += defaultTotal;
        complete += defaultComplete;
      }

      if (total === 0) {
        return 0;
      }

      const percentage = Math.floor((complete / total) * 100);
      return percentage;
    } else {
      const prelimTotal = module.submodules['preliminary'].completion.total;
      const prelimComplete =
        module.submodules['preliminary'].completion.complete;

      if (prelimTotal === 0) {
        return 0;
      }

      const percentage = Math.floor((prelimComplete / prelimTotal) * 100);
      return percentage;
    }
  }
}

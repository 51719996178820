<div
  [ngClass]="{
    'justify-center': false,
    'text-center': false,
    'justify-start': true,
    'text-left': true
  }"
  class="flex-1"
>
  @for (element of prompt; track $index) {
    @if (element.attributes) {
      @if (element.attributes.tooltip) {
        <span dominionPopoverHost [hoverTrigger]="true"
          ><span class="text-dms-green whitespace-pre" dominionPopoverTarget>{{
            element.text
          }}</span>
          <div
            dominionPopover
            [dark]="true"
            class="text-dms-light-grey"
            [popoverBgColor]="'bg-dms-teal'"
            [popoverBorder]="'border-0'"
          >
            {{ element.attributes.tooltip[0].text }}
          </div></span
        >
      } @else if (element.attributes.link) {
        <a
          href="{{ element.attributes.link }}"
          target="_blank"
          class="text-dms-green inline cursor-pointer whitespace-pre-wrap underline"
          >{{ element.text }}</a
        >
      } @else if (element.attributes.bold) {
        <span
          class="whitespace-pre-wrap"
          [ngClass]="{ 'font-bold': element.attributes.bold }"
          >{{ element.text }}</span
        >
      }
    } @else {
      <span class="whitespace-pre-wrap">{{ element.text }}</span>
    }
  }
</div>

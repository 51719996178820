import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dominionExpandable]',
  standalone: true,
  host: {
    class: 'transition-all duration-750 ease-in-out overflow-hidden',
  },
})
export class ExpandableDirective {
  isExpanded = false;

  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.style.height = '0px';
  }

  collapse() {
    const height = this.el.nativeElement.scrollHeight;
    const transitionProp = this.el.nativeElement.style.transition;
    this.el.nativeElement.style.transition = '';
    window.requestAnimationFrame(() => {
      this.el.nativeElement.style.height = height + 'px';
      this.el.nativeElement.style.transition = transitionProp;
      window.requestAnimationFrame(() => {
        this.el.nativeElement.style.height = 0 + 'px';
      });
    });
    this.isExpanded = false;
  }

  expand() {
    const height = this.el.nativeElement.scrollHeight;
    const transitionProp = this.el.nativeElement.style.transition;
    this.el.nativeElement.style.transition = '';
    window.requestAnimationFrame(() => {
      this.el.nativeElement.style.height = '0px';
      this.el.nativeElement.style.transition = transitionProp;
      window.requestAnimationFrame(() => {
        this.el.nativeElement.style.height = height + 'px';
      });
    });
    this.isExpanded = true;
  }
}

<div class="mb-5 flex flex-row items-center space-x-2">
  <span class="text-sm uppercase text-gray-500">Related Companies</span>
  <dominion-information-button [buttonWidth]="'w-4'" [buttonHeight]="'h-4'">
    <div class="w-56 p-2">
      <p>
        If your dealership is part of a group, other companies in the group will
        appear here.
      </p>
    </div>
  </dominion-information-button>
</div>
<div *ngIf="!isLoading" class="flex flex-col gap-3 pl-3">
  <div
    *ngIf="!company!.parentCompany && company!.childCompanies.length === 0"
    class="text-sm font-light italic text-gray-600"
  >
    No related companies
  </div>
  <div *ngIf="company!.parentCompany" class="flex-flex-col">
    <div class="text-dms-mid-green text-xs uppercase">Parent</div>
    <div
      class="cursor-pointer"
      (click)="goToCompany(company!.parentCompany._id)"
    >
      {{ company!.parentCompany.name }}
    </div>
  </div>
  <div *ngIf="company!.childCompanies.length > 0" class="flex flex-col">
    <div class="text-dms-mid-green text-xs uppercase">Children</div>
    <div
      class="cursor-pointer"
      (click)="goToCompany(child._id)"
      *ngFor="let child of company!.childCompanies"
    >
      {{ child.name }}
    </div>
  </div>
  <div
    *ngIf="
      company!.parentCompany && company!.parentCompany.childCompanies.length > 1
    "
    class="flex-flex-col"
  >
    <div class="text-dms-mid-green text-xs uppercase">Siblings</div>
    <ng-container *ngFor="let sibling of company!.parentCompany.childCompanies">
      <div
        *ngIf="sibling._id !== company!._id"
        class="cursor-pointer"
        (click)="goToCompany(sibling._id)"
      >
        {{ sibling.name }}
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="isLoading">
  <div class="mt-4 flex h-8 max-w-[65%] flex-row">
    <dominion-loading-placeholder></dominion-loading-placeholder>
  </div>
  <div class="mt-4 flex h-8 max-w-[35%] flex-row">
    <dominion-loading-placeholder></dominion-loading-placeholder>
  </div>
  <div class="mt-4 flex h-8 max-w-[80%] flex-row">
    <dominion-loading-placeholder></dominion-loading-placeholder>
  </div>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';
import { SubmoduleType } from '@dominion/interfaces';

@Pipe({
  name: 'submodulePhase',
  standalone: true,
})
export class SubmodulePhasePipe implements PipeTransform {
  transform(value: SubmoduleType): string {
    switch (value) {
      case 'preliminary':
        return 'Phase 1';
      case 'maintenance':
        return 'Phase 2';
      case 'default':
        return 'Phase 3';
      default:
        return value;
    }
  }
}

import { ICompanyReadFull, ICoreModule } from '@dominion/interfaces';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'getModuleAssignedUserOptions',
  standalone: true,
})
export class ModuleAssignedUserPipe {
  transform(module: ICoreModule, company?: ICompanyReadFull): DropdownOption[] {
    if (!company) {
      return [];
    }
    const merged = company.users.concat(company.invitedUsers as any);
    const allOptions = merged.map((user) => {
      return {
        label:
          user.firstName && user.lastName
            ? user.firstName + ' ' + user.lastName
            : user.email,
        value: user._id as unknown as string,
        disabled: module.externalAssigned[0] === user._id,
      };
    });
    const sorted = allOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return sorted;
  }
}

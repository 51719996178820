import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-search',
  standalone: true,
  template: ` <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
  >
    <defs>
      <style>
        .search {
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>search</title>
    <circle
      class="search"
      cx="9.813"
      cy="9.812"
      r="9.063"
      transform="translate(-3.056 4.62) rotate(-23.025)"
    />
    <line class="search" x1="16.221" y1="16.22" x2="23.25" y2="23.25" />
  </svg>`,
})
export class IconSearchComponent extends IconBase {}

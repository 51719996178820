import { animate, style, transition, trigger } from '@angular/animations';

export function fadeInFactory(duration: number, delay: number) {
  return trigger('fadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(`${duration}s ${delay}s ease-in-out`, style({ opacity: 1 })),
    ]),
  ]);
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, fromEvent, tap } from 'rxjs';

export interface DropdownOption {
  label: string;
  value: string | boolean | number;
  disabled?: boolean;
}

@Component({
  selector: 'dominion-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownSearchComponent implements AfterViewInit {
  // config
  @Input() usesButtonToggle: boolean = false;
  @Input() viewType: 'editable' | 'form' = 'form';
  @Input() toggleText: string = 'Select';
  @Input() disabled: boolean = false;
  @Input() localFiltering: boolean = true;
  @Input() enableCreateNew: boolean = false;
  @Input() createNewLabel: string = 'Create New';
  @Input() searchable: boolean = true;
  @Input() dropdownCaret: boolean = false;

  // inputs
  @Input() options: DropdownOption[] = [];
  @Input() selectedOption: DropdownOption | undefined | null;

  // styles
  @Input() optionsPaddingX: string = 'px-2';
  @Input() optionsPaddingY: string = 'py-1';
  @Input() optionsTextSize: string = 'text-sm';
  @Input() toggleTextSize: string = 'text-sm';
  @Input() togglePaddingX: string = 'px-2';
  @Input() togglePaddingY: string = 'py-2';
  @Input() shadowSize: string = 'shadow-md';
  @Input() createNewTextWidth: string = '[&>div]:hover:w-32';
  @Input() hoverBorder: boolean = false;
  @Input() dropdownCaretWidth: string = 'w-4';
  @Input() dropdownCaretHeight: string = 'h-4';
  @Input() ring: boolean = false;
  @Input() capitalizeOptionLabels: boolean = false;

  @Output() emitSelectedValue: EventEmitter<DropdownOption> =
    new EventEmitter();
  @Output() emitFilterValue: EventEmitter<string> = new EventEmitter();
  @Output() emitCreateNew: EventEmitter<void> = new EventEmitter();
  @Output() emitOpened: EventEmitter<void> = new EventEmitter();
  @Output() emitClosed: EventEmitter<void> = new EventEmitter();

  @ViewChild('filterInputEl') filterInputEl: ElementRef<HTMLInputElement>;
  @ViewChild('toggle')
  dropdownToggle: ElementRef<HTMLDivElement>;

  filteredOptions: DropdownOption[] = [];
  isFilterActive: boolean = false;
  isLoading: boolean = false;
  filterForm: FormGroup;
  isOpen: boolean = false;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.filterForm = this.fb.group({
      filter: [''],
    });
  }

  ngAfterViewInit(): void {
    if (this.searchable) {
      fromEvent(this.filterInputEl.nativeElement, 'keyup')
        .pipe(
          tap(() => {
            this.isLoading = true;
            this.changeDetectorRef.markForCheck();
          }),
          debounceTime(250),
        )
        .subscribe({
          next: () => {
            this.filterOptions(this.filterForm.get('filter')?.value);
          },
        });
    }
    if (this.viewType === 'editable') {
      this.dropdownToggle.nativeElement.classList.add('editable');
    }
  }

  selectOption(option: DropdownOption) {
    if (option.value === this.selectedOption?.value) {
      return;
    }
    if (option.disabled) {
      return;
    }
    this.emitSelectedValue.emit(option);
  }

  getOptions() {
    if (this.localFiltering && this.isFilterActive) {
      return this.filteredOptions;
    } else {
      return this.options;
    }
  }

  clearFilter() {
    this.filterForm.reset();
    this.isFilterActive = false;
    this.getOptions();
  }

  createNew() {
    this.emitCreateNew.emit();
  }

  opened() {
    this.isOpen = true;
    this.emitOpened.emit();
  }

  closed() {
    this.isOpen = false;
    this.emitClosed.emit();
  }

  private filterOptions(filterValue: string) {
    // if there is content in the form input, then filter, otherwise do not
    if (filterValue) {
      this.isFilterActive = true;
      if (this.localFiltering) {
        this.filter(filterValue);
      } else {
        this.emitFilterValue.emit(filterValue);
      }
    } else {
      this.isFilterActive = false;
      this.isLoading = false;
    }
    this.changeDetectorRef.markForCheck();
  }

  private filter(filterValue: string) {
    this.filteredOptions = this.options.filter((option) => {
      return option.label.toLowerCase().includes(filterValue.toLowerCase());
    });
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }
}

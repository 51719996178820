import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  afterNextRender,
  afterRender,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverHostDirective } from '../directives/popover-host.directive';
import { PopoverDirective } from '../directives/popover.directive';
import { SharedModule } from '../shared.module';
import { PopoverTargetDirective } from '../directives/popover-target.directive';

@Component({
  selector: 'dominion-completion-status-bar',
  standalone: true,
  imports: [
    CommonModule,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
  ],
  templateUrl: './completion-status-bar.component.html',
  styleUrls: ['./completion-status-bar.component.css'],
  host: {
    class: 'flex flex-1 flex-row',
  },
})
export class CompletionStatusBarComponent {
  @Input({ required: true, transform: round }) percentage: number;
  @Input() rounded: string = 'rounded';
  @Input() bgColor: string = 'bg-gray-100';
  @Input() completionColor: string = 'bg-green-600';

  constructor() {}
}

function round(value: number) {
  return Math.round(value);
}

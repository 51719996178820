<div class="flex flex-1 flex-row justify-center">
  <div class="flex w-full max-w-[500px] flex-col">
    <div class="flex flex-1 flex-row">
      <!-- BUTTON -->
      <div
        *ngIf="!data"
        @shrinkChoose
        [ngClass]="{
          uploading: isUploading
        }"
        class="hover:bg-dms-teal [&.uploading]:bg-dms-teal mr-1 flex basis-1/3 cursor-pointer flex-row items-center justify-center overflow-hidden rounded-bl rounded-tl bg-gray-300 hover:text-white hover:shadow [&.uploading]:pointer-events-none [&.uploading]:cursor-not-allowed [&.uploading]:text-white"
      >
        <label
          *ngIf="!isUploading"
          class="relative flex flex-1 cursor-pointer flex-row justify-center whitespace-nowrap p-2"
          for="file-upload"
          >Choose File</label
        >
        <div *ngIf="isUploading" class="h-5 w-5">
          <dominion-load-spinner
            class="fill-none stroke-white"
          ></dominion-load-spinner>
        </div>
        <input
          #fileInput
          class="hidden"
          (change)="fileInputChange($event)"
          id="file-upload"
          type="file"
          [accept]="acceptedFileExtensions"
        />
      </div>
      <!-- FILE NAME -->
      <div
        [ngClass]="{ selectedfile: selectedFileName }"
        class="flex flex-1 basis-2/3 flex-row justify-center truncate bg-gray-100 p-2 font-light text-gray-700 first:rounded-bl first:rounded-tl last:rounded-br last:rounded-tr [&.selectedfile]:font-normal [&.selectedfile]:text-black"
      >
        {{ selectedFileName || 'No file selected' }}
      </div>

      <!-- DELETE FILE -->
      <div
        [ngClass]="{
          deleting: isDeleting
        }"
        @shrinkControls
        *ngIf="data"
        (click)="deleteFile()"
        class="ml-1 flex w-10 cursor-pointer flex-row items-center justify-center overflow-hidden bg-gray-300 text-gray-600 hover:bg-red-800 hover:text-red-50 [&.deleting]:pointer-events-none [&.deleting]:cursor-not-allowed [&.deleting]:bg-red-800 [&.deleting]:text-red-50"
      >
        <div class="h-5 w-5">
          <svg
            *ngIf="!isDeleting"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <style>
                .i-delete-file {
                  fill: none;
                  stroke: currentColor;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.5px;
                }
              </style>
            </defs>
            <title>Delete</title>
            <path
              class="i-delete-file"
              d="M17.25,21H6.75a1.5,1.5,0,0,1-1.5-1.5V6h13.5V19.5A1.5,1.5,0,0,1,17.25,21Z"
            />
            <line
              class="i-delete-file"
              x1="9.75"
              y1="16.5"
              x2="9.75"
              y2="10.5"
            />
            <line
              class="i-delete-file"
              x1="14.25"
              y1="16.5"
              x2="14.25"
              y2="10.5"
            />
            <line class="i-delete-file" x1="2.25" y1="6" x2="21.75" y2="6" />
            <path
              class="i-delete-file"
              d="M14.25,3H9.75a1.5,1.5,0,0,0-1.5,1.5V6h7.5V4.5A1.5,1.5,0,0,0,14.25,3Z"
            />
          </svg>
          <dominion-load-spinner
            *ngIf="isDeleting"
            class="fill-none stroke-white"
          ></dominion-load-spinner>
        </div>
      </div>
      <!-- OPEN FILE -->

      <div
        [ngClass]="{ disabled: isDeleting }"
        @shrinkControls
        *ngIf="data"
        (click)="getFile()"
        class="hover:bg-dms-teal ml-1 flex w-10 flex-shrink cursor-pointer flex-row items-center justify-center overflow-hidden rounded-br rounded-tr bg-gray-300 text-gray-600 hover:text-white disabled:pointer-events-none [&.disabled]:cursor-not-allowed [&.disabled]:hover:bg-gray-300 [&.disabled]:hover:text-gray-600"
      >
        <div class="h-5 w-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <defs>
              <style>
                .i-file-download {
                  fill: none;
                  stroke: currentColor;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.5px;
                }
              </style>
            </defs>
            <title>Open File</title>
            <circle class="i-file-download" cx="17.25" cy="17.25" r="6" />
            <line
              class="i-file-download"
              x1="17.25"
              y1="14.25"
              x2="17.25"
              y2="20.25"
            />
            <line
              class="i-file-download"
              x1="17.25"
              y1="20.25"
              x2="19.5"
              y2="18"
            />
            <line
              class="i-file-download"
              x1="17.25"
              y1="20.25"
              x2="15"
              y2="18"
            />
            <path
              class="i-file-download"
              d="M8.25,20.25h-6a1.5,1.5,0,0,1-1.5-1.5V2.25A1.5,1.5,0,0,1,2.25.75H12.879a1.5,1.5,0,0,1,1.06.439l2.872,2.872a1.5,1.5,0,0,1,.439,1.06V8.25"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

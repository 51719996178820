import {
  AfterContentInit,
  ContentChild,
  Directive,
  ElementRef,
  Input,
} from '@angular/core';
import { Placement, createPopper } from '@popperjs/core';
import { PopoverTargetDirective } from './popover-target.directive';

@Directive({
  selector: '[dominionPopoverHost]',
  standalone: true,
})
export class PopoverHostDirective implements AfterContentInit {
  @Input() hoverTrigger: boolean = false;
  @Input() clickTrigger: boolean = false;
  @Input() placement: Placement = 'bottom';

  public target: HTMLElement;
  public popover: HTMLElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public popper: any;
  public arrow: HTMLElement;
  public isVisible = false;

  @ContentChild(PopoverTargetDirective, {
    read: PopoverTargetDirective,
    descendants: true,
  })
  popoverTarget: PopoverTargetDirective;

  constructor(private el: ElementRef) {}

  public show() {
    this.popover.setAttribute('data-show', '');
    this.popper.update();
    this.isVisible = true;
  }

  public hide() {
    this.popover.removeAttribute('data-show');
    this.isVisible = false;
  }

  initializeHoverTrigger() {
    this.popoverTarget.mouseEntered.subscribe(() => {
      this.show();
    });
    this.popoverTarget.mouseLeft.subscribe(() => {
      this.hide();
    });
  }

  initializeClickTrigger() {
    this.popoverTarget.clicked.subscribe(() => {
      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    });
  }

  ngAfterContentInit(): void {
    this.target = this.el.nativeElement.querySelector(
      '[dominionPopoverTarget]',
    );
    this.popover = this.el.nativeElement.querySelector('[dominionPopover]');
    this.arrow = this.el.nativeElement.querySelector('#arrow');
    this.popper = createPopper(this.target, this.popover, {
      placement: this.placement,
      modifiers: [
        {
          name: 'arrow',
          options: {
            padding: 4,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
    if (this.hoverTrigger) {
      this.initializeHoverTrigger();
    } else if (this.clickTrigger) {
      this.initializeClickTrigger();
    }
  }
}

import { Component, HostBinding, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { type SearchableQuestion } from '../admin-questions.component';
import { IconSearchComponent } from '../../../icons/icon-search.component';

@Component({
  selector: 'dominion-question-search',
  standalone: true,
  imports: [FormsModule, RouterModule, IconSearchComponent],
  templateUrl: './question-search.component.html',
  styleUrl: './question-search.component.css',
})
export class QuestionSearchComponent {
  @Input() questions: SearchableQuestion[] = [];
  @Input() search = '';

  @HostBinding('class') class = 'w-full max-w-xs';

  constructor(private router: Router) {}

  get filteredQuestions(): SearchableQuestion[] {
    const searchLower = this.search.toLowerCase();
    return this.questions.filter(
      ({ questionLabel, questionPrompt, groupKey, questionKey }) =>
        (questionLabel?.toLowerCase().includes(searchLower) ?? false) ||
        (questionPrompt?.toLowerCase().includes(searchLower) ?? false) ||
        (groupKey?.toLowerCase().includes(searchLower) ?? false) ||
        (questionKey?.toLowerCase().includes(searchLower) ?? false),
    );
  }

  selectQuestion(question: SearchableQuestion) {
    this.router
      .navigate([], {
        queryParams: {
          module: question.module,
          submodule: question.submodule,
          group: question.groupKey,
          question: question.parentKey,
        },
        fragment: question.questionKey,
      })
      .then(() => {
        setTimeout(() => {
          const targetElement = document.getElementById(question.questionKey);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 50);
      });
  }
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-download',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ strokeWidth }} {{ fillColor }}"
  >
    <defs>
      <style>
        .a {
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      </style>
    </defs>
    <title></title>
    <polyline class="a" points="8.25 14.25 12 18 15.75 14.25" />
    <line class="a" x1="12" y1="6.75" x2="12" y2="18" />
    <circle class="a" cx="12" cy="12" r="11.25" />
  </svg>`,
})
export class IconDownloadComponent extends IconBase {}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { HttpClient } from '@angular/common/http';
import {
  IAssignVendorToCompanyDto,
  ICreateVendorDto,
  IGetVendorsResponse,
  IVendor,
  IVendorCustom,
  IVendorCustomCreateDto,
  IVendorQuestion,
  IVendorQuestionUpdateVendorsDto,
  IVendorUpdateDto,
} from '@dominion/interfaces';
import { Observable } from 'rxjs';

const apiBaseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(
    private authentication: AuthenticationService,
    private http: HttpClient,
  ) {}

  getVendors(page: number): Observable<IGetVendorsResponse> {
    return this.http.get<IGetVendorsResponse>(
      `${apiBaseUrl}vendor/${page}`,
      this.authentication.httpOptions,
    );
  }

  createVendor(dto: ICreateVendorDto): Observable<IVendor> {
    return this.http.post<IVendor>(
      `${apiBaseUrl}vendor`,
      dto,
      this.authentication.httpOptions,
    );
  }

  updateVendor(dto: IVendorUpdateDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}vendor`,
      dto,
      this.authentication.httpOptions,
    );
  }

  getVendorQuestions(): Observable<IVendorQuestion[]> {
    return this.http.get<IVendorQuestion[]>(
      `${apiBaseUrl}vendor/question`,
      this.authentication.httpOptions,
    );
  }

  addVendorToQuestion(dto: IVendorQuestionUpdateVendorsDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}vendor/question/add-vendor`,
      dto,
      this.authentication.httpOptions,
    );
  }

  deleteVendorFromQuestion(
    dto: IVendorQuestionUpdateVendorsDto,
  ): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}vendor/question/remove-vendor`,
      dto,
      this.authentication.httpOptions,
    );
  }

  getCustomVendors(companyId: string): Observable<IVendorCustom[]> {
    return this.http.get<IVendorCustom[]>(
      `${apiBaseUrl}vendor/custom-vendors/${companyId}`,
      this.authentication.httpOptions,
    );
  }

  getVendorQuestionsByKeys(keys: {
    questionKeys: string[];
  }): Observable<IVendorQuestion[]> {
    return this.http.post<IVendorQuestion[]>(
      `${apiBaseUrl}vendor/question/get-by-keys`,
      keys,
      this.authentication.httpOptions,
    );
  }

  assignVendorToCompany(dto: IAssignVendorToCompanyDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}vendor/assign-vendor`,
      dto,
      this.authentication.httpOptions,
    );
  }

  saveCustomVendor(dto: IVendorCustomCreateDto): Observable<IVendorCustom> {
    return this.http.patch<IVendorCustom>(
      `${apiBaseUrl}vendor/custom-vendor`,
      dto,
      this.authentication.httpOptions,
    );
  }
}

import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CompanyService } from '../company/company.service';
import {
  ICompanyReadFull,
  ICoreModule,
  IRequestUserPayload,
} from '@dominion/interfaces';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ExpandableDirective } from '../shared/directives/expandable.directive';
import { ExpandableHostDirective } from '../shared/directives/expandable-host.directive';
import { ExpandableToggleDirective } from '../shared/directives/expandable-toggle.directive';
import { AccordionDirective } from '../shared/directives/accordion.directive';
import { DropdownCaretComponent } from '../shared/dropdown-caret/dropdown-caret.component';
import { PopoverDirective } from '../shared/directives/popover.directive';
import { PopoverHostDirective } from '../shared/directives/popover-host.directive';
import { PopoverTargetDirective } from '../shared/directives/popover-target.directive';
import { IconCompanyComponent } from '../icons/icon-company.component';
import { ModuleTypePipe } from '../shared/pipes/module-type.pipe';

@Component({
  selector: 'dominion-discovery-side-nav',
  templateUrl: './discovery-side-nav.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    SharedModule,
    ExpandableDirective,
    ExpandableHostDirective,
    ExpandableToggleDirective,
    AccordionDirective,
    DropdownCaretComponent,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
    IconCompanyComponent,
    ModuleTypePipe,
  ],
  styleUrls: ['./discovery-side-nav.component.css'],
})
export class DiscoverySideNavComponent implements OnInit {
  @HostBinding('class') class =
    'flex flex-col basis-1/5 border-r border-dms-light-grey overflow-hidden';
  @Input() moduleId: string | null = null;

  companyId: string | undefined;
  modules: ICoreModule[] = [];
  user: IRequestUserPayload | undefined;
  company: ICompanyReadFull | undefined;

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  getCompanyModules() {
    if (this.companyId) {
      this.companyService.getCompany(this.companyId).subscribe({
        next: (company) => {
          this.modules = company.modules;
          this.company = company;
        },
      });
    }
  }

  goToDashboard() {
    if (this.user) {
      if (this.user.userType === 'internal') {
        this.router.navigate([
          '/core/admin/companies',
          this.companyId,
          'detail',
        ]);
      } else {
        this.router.navigate(['/core', this.companyId, 'dashboard']);
      }
    }
  }

  goToVendors() {
    this.router.navigate(['/core', this.companyId, 'vendors']);
  }

  ngOnInit(): void {
    this.authenticationService.user.subscribe({
      next: (user) => {
        if (user) {
          this.user = user;
        }
      },
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['companyid'];
      this.getCompanyModules();
    });
  }
}

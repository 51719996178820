import { SelectOption } from './select-option.interfaces';

export const dominionDeptOptions = [
  {
    label: 'Project Management',
    value: 'pm' as const,
  },
  {
    label: 'Deployment',
    value: 'deployment' as const,
  },
  {
    label: 'Implementation',
    value: 'implementation' as const,
  },
  {
    label: 'Sales',
    value: 'sales' as const,
  },
  {
    label: 'Support',
    value: 'support' as const,
  },
  {
    label: 'Other',
    value: 'other' as const,
  },
] satisfies SelectOption<string>[];

export type DominionDept = (typeof dominionDeptOptions)[number]['value'];

export const dominionDeptList = dominionDeptOptions.map((dept) => dept.value);

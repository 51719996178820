import { Pipe, PipeTransform } from '@angular/core';
import { IDMS } from '@dominion/interfaces';

@Pipe({
  name: 'dms',
})
export class DmsPipe implements PipeTransform {
  transform(value: IDMS | string): string {
    switch (value) {
      case 'adam':
        return 'ADAM';
      case 'autosoft':
        return 'Autosoft';
      case 'automate':
        return 'Auto/Mate';
      case 'cdk':
        return 'CDK';
      case 'dealerbuilt':
        return 'DealerBuilt';
      case 'dealertrack':
        return 'Dealertrack';
      case 'pbs':
        return 'PBS';
      case 'quorum':
        return 'Quorum';
      case 'reynolds':
        return 'Reynolds & Reynolds';
      case 'tekion':
        return 'Tekion';
      case 'other':
        return 'Other';
    }
    return value;
  }
}

<form
  [formGroup]="contactForm"
  (ngSubmit)="submit()"
  class="mx-auto max-w-md flex-1"
>
  <div class="flex flex-col gap-4">
    @if (sections.orgName.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Name"
        [required]="!!sections.orgName.required"
        [formGroup]="contactForm"
        formControlName="orgName"
      ></dominion-form-input>
    }

    @if (sections.orgAddress.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Address"
        [required]="!!sections.orgAddress.required"
        [formGroup]="contactForm"
        formControlName="orgAddress"
      ></dominion-form-input>
    }

    @if (sections.orgEmail.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Email"
        [required]="!!sections.orgEmail.required"
        [formGroup]="contactForm"
        formControlName="orgEmail"
      ></dominion-form-input>
    }

    @if (sections.orgPhone.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Organization Phone"
        [required]="!!sections.orgPhone.required"
        [formGroup]="contactForm"
        formControlName="orgPhone"
      ></dominion-form-input>
    }

    @if (sections.contactName.enabled) {
      <dominion-form-input
        ngDefaultControl
        label="Primary Contact Name"
        [required]="!!sections.contactName.required"
        [formGroup]="contactForm"
        formControlName="contactName"
      ></dominion-form-input>
    }

    @if (sections.notes.enabled) {
      <dominion-form-input
        ngDefaultControl
        type="textarea"
        label="Notes"
        [required]="!!sections.notes.required"
        [formGroup]="contactForm"
        formControlName="notes"
      ></dominion-form-input>
    }
  </div>

  <div class="mt-8 flex items-center justify-between">
    @if (log.length > 0) {
      <div>
        <button
          type="button"
          (click)="logModal.open()"
          class="group flex items-center gap-1 transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="text-gray-400"
          >
            <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
            <path d="M3 3v5h5" />
            <path d="M12 7v5l4 2" />
          </svg>
          <span class="text-sm text-gray-500 transition group-hover:underline"
            >History</span
          >
        </button>
      </div>
    }

    <div class="flex-1"></div>

    <button
      type="submit"
      dominionDiscoveryNextBtn
      [ngClass]="{ disabled: contactForm.invalid }"
      [disabled]="contactForm.invalid"
    >
      Next
    </button>
  </div>
</form>

<dominion-modal #logModal title="Response History">
  <dominion-log body class="h-[400px]" [logs]="log"></dominion-log>
</dominion-modal>

import {
  TDataDefinition,
  TSubmoduleGroupMap,
  Structures,
  DataInitializerBasic,
  IFileUploadReference,
} from '@dominion/interfaces';

export type TPartsPrelimGroupIdentifiers = 'groupVendors' | 'groupMisc';

export type TPartsPrelimDataKeys =
  | 'lastPhysicalPartsInventory'
  | 'partsOfficePrinters'
  | 'partsDoNotUpdate'
  | 'partsInventoryVendor'
  | 'partsScanningToolVendor'
  | 'electronicPartsCatalogVendor'
  | 'usesDeliveryRoutes'
  | 'deliveryRoutes'
  | 'partsOrdersOpen'
  | 'partsInactiveInventory'
  | 'partsSignaturePad';

const partsPrelimGroupMap = {
  moduleType: 'parts',
  submoduleType: 'preliminary',
  discriminator: 'parts-preliminary',
  groups: {
    groupVendors: [
      'partsInventoryVendor',
      'partsScanningToolVendor',
      'electronicPartsCatalogVendor',
      'partsSignaturePad',
    ],
    groupMisc: [
      'lastPhysicalPartsInventory',
      'partsInactiveInventory',
      'partsOfficePrinters',
      'partsDoNotUpdate',
      'usesDeliveryRoutes',
      'deliveryRoutes',
      'partsOrdersOpen',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TPartsPrelimGroupIdentifiers,
  TPartsPrelimDataKeys
>;

export type TPartsPrelimGroupMap = typeof partsPrelimGroupMap;

export class PartsPreliminaryData
  implements TDataDefinition<TPartsPrelimDataKeys>
{
  // MISC
  // groupMisc
  // When was the last time you performed a physical parts inventory?
  lastPhysicalPartsInventory = new DataInitializerBasic<string>();
  // What type of printers do you use in your Parts Office?
  partsOfficePrinters = new DataInitializerBasic<string>();
  // How do you mark your Parts Inventory as "Do Not Update" by the Parts Price Master?
  partsDoNotUpdate = new DataInitializerBasic<string>();
  // Do you use Delivery Routes?
  usesDeliveryRoutes = new DataInitializerBasic<boolean>();
  // Delivery Routes
  deliveryRoutes = new DataInitializerBasic<IFileUploadReference[]>();
  partsInactiveInventory = new DataInitializerBasic<string>();

  // VENDORS
  // groupVendors
  // What vendor do you use for parts inventory management?
  partsInventoryVendor = new DataInitializerBasic<string>();
  // What vendor do you use for parts inventory scanning?
  partsScanningToolVendor = new DataInitializerBasic<string>();
  // What vendor do you use for your electronic parts catalog?
  electronicPartsCatalogVendor = new DataInitializerBasic<string>();
  // What vendor do you use for your customer signature pad?
  partsSignaturePad = new DataInitializerBasic<string>();
  partsOrdersOpen = new DataInitializerBasic<string>();
}

export const partsPreliminaryStructure: Structures['parts-preliminary'] = {
  moduleType: 'parts',
  submoduleType: 'preliminary',
  discriminator: 'parts-preliminary',
  groups: {
    groupMisc: {
      groupLabel: 'Miscellaneous',
      groupType: 'standard',
      groupUrl: 'groupMisc',
      questions: {
        partsOrdersOpen: {
          questionKey: 'partsOrdersOpen',
          label: 'Parts Orders Open',
          prompt: [
            {
              text: 'How many parts orders do you have open currently?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        lastPhysicalPartsInventory: {
          questionKey: 'lastPhysicalPartsInventory',
          label: 'Last Physical Parts Inventory',
          prompt: [
            {
              text: 'When was the last time you performed a physical parts inventory?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        partsInactiveInventory: {
          questionKey: 'partsInactiveInventory',
          label: 'Parts Inactive Inventory',
          prompt: [
            {
              text: 'How many years of no activity should a part have before it is labeled inactive? For instance, you might want to label any part as inactive if it has a last activity date of more than 5 years ago.',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'We ask this because some DMSs do not indicate whether a part is active or inactive like VUE does. During our data conversion process, we will use your response here to mark parts as inactive in VUE.',
            },
          ],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        partsOfficePrinters: {
          questionKey: 'partsOfficePrinters',
          label: 'Parts Office Printers',
          prompt: [
            {
              text: 'What type of printers do you use in your Parts Office?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        partsDoNotUpdate: {
          questionKey: 'partsDoNotUpdate',
          label: 'Parts Do Not Update',
          prompt: [
            {
              text: 'How do you mark your Parts Inventory as "Do Not Update" by the Parts Price Master?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'We ask this to ensure that we do not update parts inventory during go-live that should not be updated.',
            },
          ],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        usesDeliveryRoutes: {
          questionKey: 'usesDeliveryRoutes',
          label: 'Uses Delivery Routes',
          prompt: [
            {
              text: 'Do you plan to use delivery routes for your parts wholesale customers?',
              attributes: null,
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: "Delivery routes are typically assigned to wholesale customers. This informs the delivery driver of where to go when delivering parts. You can set up as many routes as you need, but you can only assign one route per customer. You'll be able to generate a delivery route report to see the sales and GP per location.",
            },
          ],
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['deliveryRoutes'],
            },
          ],
        },
        deliveryRoutes: {
          questionKey: 'deliveryRoutes',
          label: 'Delivery Routes',
          prompt: [
            {
              text: 'Please upload a list of your delivery routes',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'file',
          requirement: 'dependent',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupVendors: {
      groupLabel: 'Vendors',
      groupType: 'vendor',
      groupUrl: 'groupVendors',
      questions: {
        partsInventoryVendor: {
          questionKey: 'partsInventoryVendor',
          label: 'Parts Inventory Vendor',
          prompt: [
            {
              text: 'Which vendor do you use for parts inventory management?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        partsScanningToolVendor: {
          questionKey: 'partsScanningToolVendor',
          label: 'Parts Scanning Tool Vendor',
          prompt: [
            {
              text: 'Which vendor do you use for parts inventory scanning?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        electronicPartsCatalogVendor: {
          questionKey: 'electronicPartsCatalogVendor',
          label: 'Electronic Parts Catalog Vendor',
          prompt: [
            {
              text: 'Which vendor do you use for your electronic parts catalog?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
        partsSignaturePad: {
          questionKey: 'partsSignaturePad',
          label: 'Parts Signature Pad',
          prompt: [
            {
              text: 'Which vendor do you use for your customer signature pad?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'vendor',
          requirement: 'required',
          allowSkip: false,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
};

import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, tap } from "rxjs";
import { AuthenticationService } from "./authentication.service";

export function userDeactivationInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<any> {
  const router = inject(Router);
  return next(req).pipe(
    catchError(err => {
      // if the server returns an error object with deactivated set to true, then delete the token and go to the login page
      if (err.error.deactivated) {
        window.localStorage.removeItem('access_token');
        location.reload();
      }
      throw err;
    }
  ));
}

function deleteToken() {
  localStorage.removeItem('access_token');
}


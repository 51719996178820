import {
  TDataDefinition,
  TSubmoduleGroupMap,
  Structures,
} from '@dominion/interfaces';
import {
  DataInitializerBasic,
  IAddressData,
} from '../../core/core-module.interfaces';

// this type defines the names for each of the question groups within the service preliminary submodule
export type TServicePrelimGroupIdentifiers =
  | 'groupDispatching'
  | 'groupWarrantyClaims'
  | 'groupTechniciansDMS'
  | 'groupQuickLube'
  | 'groupBodyShop'
  | 'groupTechnicianAdditionalPay'
  | 'groupPrinters'
  | 'groupVendors';

export type TServicePrelimDataKeys =
  | 'writeUpProcess'
  | 'dispatchingProcess'
  | 'followUpProcess'
  | 'closingROProcess'
  | 'warrantySubmissionProcess'
  | 'warrantyManualProcess'
  | 'techniciansUseDMS'
  | 'hasQuickLube'
  | 'isQuickLubeSameCampus'
  | 'quickLubeAddress'
  | 'quickLubeHasPartsInventory'
  | 'hasBodyShop'
  | 'isBodyShopSameCampus'
  | 'bodyShopAddress'
  | 'bodyShopHasPartsInventory'
  | 'bodyShopAdvisorsUseDMS'
  | 'bodyShopPaintersUseDMS'
  | 'additionalHoursPaid'
  | 'additionalHoursPaidExplain'
  | 'usesOilChangeLabelPrinter'
  | 'oilChangeLabelPrinter'
  | 'servicePrinters'
  | 'warrantyProcessingVendor'
  | 'warrantyLaborTimeVendor'
  | 'serviceSchedulingVendor'
  | 'serviceEstimatingVendor'
  | 'electronicMaintenanceMenusVendor'
  | 'multiPointInspectionsVendor'
  | 'rentalManagementVendor'
  | 'serviceSignaturePadVendor'
  | 'servicePricingGuideVendor'
  | 'serviceManagementVendor'
  | 'serviceSurveyVendor'
  | 'webAndMobilePaymentVendor';

const servicePrelimGroupMap = {
  moduleType: 'service',
  submoduleType: 'preliminary',
  discriminator: 'service-preliminary',
  groups: {
    groupDispatching: [
      'writeUpProcess',
      'dispatchingProcess',
      'followUpProcess',
      'closingROProcess',
    ],
    groupWarrantyClaims: ['warrantySubmissionProcess', 'warrantyManualProcess'],
    groupTechniciansDMS: ['techniciansUseDMS'],
    groupQuickLube: [
      'hasQuickLube',
      'isQuickLubeSameCampus',
      'quickLubeAddress',
      'quickLubeHasPartsInventory',
    ],
    groupBodyShop: [
      'hasBodyShop',
      'isBodyShopSameCampus',
      'bodyShopAddress',
      'bodyShopHasPartsInventory',
      'bodyShopAdvisorsUseDMS',
      'bodyShopPaintersUseDMS',
    ],
    groupTechnicianAdditionalPay: [
      'additionalHoursPaid',
      'additionalHoursPaidExplain',
    ],
    groupPrinters: [
      'oilChangeLabelPrinter',
      'usesOilChangeLabelPrinter',
      'servicePrinters',
    ],
    groupVendors: [
      'warrantyProcessingVendor',
      'warrantyLaborTimeVendor',
      'serviceSchedulingVendor',
      'serviceEstimatingVendor',
      'electronicMaintenanceMenusVendor',
      'multiPointInspectionsVendor',
      'rentalManagementVendor',
      'serviceSignaturePadVendor',
      'servicePricingGuideVendor',
      'webAndMobilePaymentVendor',
      'serviceManagementVendor',
      'serviceSurveyVendor',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TServicePrelimGroupIdentifiers,
  TServicePrelimDataKeys
>;

export type TServicePrelimGroupMap = typeof servicePrelimGroupMap;

// this interface defines the keys and corresponding data types for all questions contained within the service preliminary submodule
export class ServicePreliminaryData
  implements TDataDefinition<TServicePrelimDataKeys>
{
  // DISPATCHING
  // groupDispatching
  // What is your service write-up process?
  writeUpProcess = new DataInitializerBasic<string>();
  // What is your dispatching process?
  dispatchingProcess = new DataInitializerBasic<string>();
  // How do you provide service follow-ups?
  followUpProcess = new DataInitializerBasic<string>();
  // What is your process for closing repair orders? (Please walk through customer pay and internal pay)
  closingROProcess = new DataInitializerBasic<string>();

  // WARRANTY CLAIMS PROCESS
  // groupWarrantyClaims
  // What is your process for submitting warranty claims (manually or electronically)?
  warrantySubmissionProcess = new DataInitializerBasic<
    'manual' | 'electronic'
  >();
  // If manual, do you keep the RO open until final payment from the OEM, or do you close before payment and reconcile the warranty schedule with journal entries?
  warrantyManualProcess = new DataInitializerBasic<
    'keep-open' | 'close-before'
  >();

  // TECHNICIANS AND DMS
  // groupTechniciansDMS
  // Will your technicians use the DMS? (Tracking time, electronic up sellings, etc.)
  techniciansUseDMS = new DataInitializerBasic<boolean>();

  // QUICK LUBE
  // groupQuickLube
  // Do you have a quick lube?
  hasQuickLube = new DataInitializerBasic<boolean>();
  // Is your quick lube on the same campus as your dealership?
  isQuickLubeSameCampus = new DataInitializerBasic<boolean>();
  // Please provide the address of your quick lube.
  quickLubeAddress = new DataInitializerBasic<IAddressData>();
  // Does your quick lube have its own parts inventory?
  quickLubeHasPartsInventory = new DataInitializerBasic<boolean>();

  // BODY SHOP
  // groupBodyShop
  // Do you have a body shop?
  hasBodyShop = new DataInitializerBasic<boolean>();
  // Is your body shop on the same campus as your dealership?
  isBodyShopSameCampus = new DataInitializerBasic<boolean>();
  // Please provide the address of your body shop.
  bodyShopAddress = new DataInitializerBasic<IAddressData>();
  // Does your body shop have its own parts inventory?
  bodyShopHasPartsInventory = new DataInitializerBasic<boolean>();
  // Will body shop advisors/estimators use the DMS?
  bodyShopAdvisorsUseDMS = new DataInitializerBasic<boolean>();
  // Will body shop painters use the DMS?
  bodyShopPaintersUseDMS = new DataInitializerBasic<boolean>();

  // TECHNICIAN ADDITIONAL PAY
  // groupTechnicianAdditionalPay
  // Do technicians get paid for additional hours outside of WIP?
  additionalHoursPaid = new DataInitializerBasic<boolean>();
  // If yes, please explain for what and the rate
  additionalHoursPaidExplain = new DataInitializerBasic<string>();

  // PRINTERS
  // groupPrinters
  // Do you use an oil change label printer?
  usesOilChangeLabelPrinter = new DataInitializerBasic<boolean>();
  // Please provide the make and model of your label printer
  oilChangeLabelPrinter = new DataInitializerBasic<string>();
  // Please provide what type of printers you currently have in your service drive
  servicePrinters = new DataInitializerBasic<string>();

  // VENDORS
  // groupVendors
  // Which vendor do you plan to use for warranty processing?
  warrantyProcessingVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for warranty labor time guides?
  warrantyLaborTimeVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for service scheduling?
  serviceSchedulingVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for service estimating?
  serviceEstimatingVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for electronic maintenance menus?
  electronicMaintenanceMenusVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for multi-point inspections?
  multiPointInspectionsVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for rental management?
  rentalManagementVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for customer signature pads?
  serviceSignaturePadVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for service pricing guides?
  servicePricingGuideVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for web and mobile payments?
  webAndMobilePaymentVendor = new DataInitializerBasic<string>();
  // Which vendor do you plan to use for service management?
  serviceManagementVendor = new DataInitializerBasic<string>();
  serviceSurveyVendor = new DataInitializerBasic<string>();
}

//
//
//
//
// STRUCTURE

export const servicePreliminaryStructure: Structures['service-preliminary'] = {
  moduleType: 'service',
  submoduleType: 'preliminary',
  discriminator: 'service-preliminary',
  groups: {
    groupDispatching: {
      groupLabel: 'Dispatching, Write-Ups, and Closing',
      groupUrl: 'groupDispatching',
      groupType: 'standard',
      questions: {
        writeUpProcess: {
          questionKey: 'writeUpProcess',
          label: 'Write-Up Process',
          prompt: [
            {
              text: 'What is your service write-up process?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
          options: [],
          validation: [],
        },
        dispatchingProcess: {
          questionKey: 'dispatchingProcess',
          label: 'Dispatching Process',
          prompt: [
            {
              text: 'What is your dispatching process?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
          options: [],
          validation: [],
        },
        followUpProcess: {
          questionKey: 'followUpProcess',
          label: 'Follow-Up Process',
          prompt: [
            {
              text: 'How do you provide service follow-ups?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
          options: [],
          validation: [],
        },
        closingROProcess: {
          questionKey: 'closingROProcess',
          label: 'Closing RO Process',
          prompt: [
            {
              text: 'What is your process for closing repair orders? (Please walk through customer pay and internal pay)',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
          options: [],
          validation: [],
        },
      },
    },
    groupWarrantyClaims: {
      groupLabel: 'Warranty Claims Process',
      groupUrl: 'groupWarrantyClaims',
      groupType: 'standard',
      questions: {
        warrantySubmissionProcess: {
          questionKey: 'warrantySubmissionProcess',
          label: 'Warranty Submission Process',
          prompt: [
            {
              text: 'What is your process for submitting warranty claims (manually or electronically)?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Manually',
              value: 'manual',
            },
            {
              label: 'Electronically',
              value: 'electronic',
            },
          ],
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: 'manual',
              dependentKeys: ['warrantyManualProcess'],
            },
          ],
        },
        warrantyManualProcess: {
          questionKey: 'warrantyManualProcess',
          label: 'Warranty Manual Process',
          prompt: [
            {
              text: 'If manual, do you keep the RO open until final payment from the OEM, or do you close before payment and reconcile the warranty schedule with journal entries?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Keep Open',
              value: 'keep-open',
            },
            {
              label: 'Close Before',
              value: 'close-before',
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
      },
    },
    groupTechniciansDMS: {
      groupLabel: 'Technicians and DMS',
      groupUrl: 'groupTechniciansDMS',
      groupType: 'standard',
      questions: {
        techniciansUseDMS: {
          questionKey: 'techniciansUseDMS',
          label: 'Technicians Use DMS',
          prompt: [
            {
              text: 'Will your technicians use the DMS? (Tracking time, electronic up sellings, etc.)',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
      },
    },
    groupPrinters: {
      groupLabel: 'Printers',
      groupUrl: 'groupPrinters',
      groupType: 'standard',
      questions: {
        usesOilChangeLabelPrinter: {
          questionKey: 'usesOilChangeLabelPrinter',
          label: 'Uses Oil Change Label Printer',
          prompt: [
            {
              text: 'Do you use an oil change label printer?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['oilChangeLabelPrinter'],
            },
          ],
        },
        oilChangeLabelPrinter: {
          questionKey: 'oilChangeLabelPrinter',
          label: 'Oil Change Label Printer',
          prompt: [
            {
              text: 'Please provide the make and model of your label printer',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'text',
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
        servicePrinters: {
          questionKey: 'servicePrinters',
          label: 'Service Printers',
          prompt: [
            {
              text: 'Please provide what type of printers you currently have in your service drive',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'text',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
      },
    },

    groupTechnicianAdditionalPay: {
      groupLabel: 'Technician Additional Pay',
      groupUrl: 'groupTechnicianAdditionalPay',
      groupType: 'standard',
      questions: {
        additionalHoursPaid: {
          questionKey: 'additionalHoursPaid',
          label: 'Additional Hours Paid',
          prompt: [
            {
              text: 'Do technicians get paid for additional hours outside of WIP?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['additionalHoursPaidExplain'],
            },
          ],
        },
        additionalHoursPaidExplain: {
          questionKey: 'additionalHoursPaidExplain',
          label: 'Additional Hours Paid Explain',
          prompt: [
            {
              text: 'If yes, please explain for what and the rate',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'textarea',
          options: [],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
      },
    },
    groupQuickLube: {
      groupLabel: 'Quick Lube',
      groupUrl: 'groupQuickLube',
      groupType: 'standard',
      questions: {
        hasQuickLube: {
          questionKey: 'hasQuickLube',
          label: 'Has Quick Lube',
          prompt: [
            {
              text: 'Do you have a quick lube?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'isQuickLubeSameCampus',
                'quickLubeHasPartsInventory',
              ],
            },
          ],
        },
        isQuickLubeSameCampus: {
          questionKey: 'isQuickLubeSameCampus',
          label: 'Is Quick Lube Same Campus',
          prompt: [
            {
              text: 'Is your quick lube on the same campus as your dealership?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['quickLubeAddress'],
            },
          ],
        },
        quickLubeAddress: {
          questionKey: 'quickLubeAddress',
          label: 'Quick Lube Address',
          prompt: [
            {
              text: 'Please provide the address of your quick lube',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'address',
          options: [],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
        quickLubeHasPartsInventory: {
          questionKey: 'quickLubeHasPartsInventory',
          label: 'Quick Lube Has Parts Inventory',
          prompt: [
            {
              text: 'Does your quick lube have its own parts inventory?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
      },
    },
    groupBodyShop: {
      groupLabel: 'Body Shop',
      groupUrl: 'groupBodyShop',
      groupType: 'standard',
      questions: {
        hasBodyShop: {
          questionKey: 'hasBodyShop',
          label: 'Has Body Shop',
          prompt: [
            {
              text: 'Do you have a body shop?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: [
                'isBodyShopSameCampus',
                'bodyShopHasPartsInventory',
                'bodyShopAdvisorsUseDMS',
                'bodyShopPaintersUseDMS',
              ],
            },
          ],
        },
        isBodyShopSameCampus: {
          questionKey: 'isBodyShopSameCampus',
          label: 'Is Body Shop Same Campus',
          prompt: [
            {
              text: 'Is your body shop on the same campus as your dealership?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: false,
              dependentKeys: ['bodyShopAddress'],
            },
          ],
        },
        bodyShopAddress: {
          questionKey: 'bodyShopAddress',
          label: 'Body Shop Address',
          prompt: [
            {
              text: 'Please provide the address of your body shop',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'address',
          options: [],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
        bodyShopHasPartsInventory: {
          questionKey: 'bodyShopHasPartsInventory',
          label: 'Body Shop Has Parts Inventory',
          prompt: [
            {
              text: 'Does your body shop have its own parts inventory?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
        bodyShopAdvisorsUseDMS: {
          questionKey: 'bodyShopAdvisorsUseDMS',
          label: 'Body Shop Advisors Use DMS',
          prompt: [
            {
              text: 'Will body shop advisors/estimators use the DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
        bodyShopPaintersUseDMS: {
          questionKey: 'bodyShopPaintersUseDMS',
          label: 'Body Shop Painters Use DMS',
          prompt: [
            {
              text: 'Will body shop painters use the DMS?',
              attributes: null,
            },
          ],
          subprompt: [],
          componentType: 'select',
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          validation: [],
          requirement: 'dependent',
          allowSkip: false,
          dependencies: [],
        },
      },
    },
    groupVendors: {
      groupLabel: 'Vendors',
      groupUrl: 'groupVendors',
      groupType: 'vendor',
      questions: {
        serviceSurveyVendor: {
          questionKey: 'serviceSurveyVendor',
          label: 'Service Survey Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for service surveys?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        warrantyProcessingVendor: {
          questionKey: 'warrantyProcessingVendor',
          label: 'Warranty Processing Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for warranty processing?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        warrantyLaborTimeVendor: {
          questionKey: 'warrantyLaborTimeVendor',
          label: 'Warranty Labor Time Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for warranty labor time guides?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        serviceSchedulingVendor: {
          questionKey: 'serviceSchedulingVendor',
          label: 'Service Scheduling Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for service scheduling?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        serviceEstimatingVendor: {
          questionKey: 'serviceEstimatingVendor',
          label: 'Service Estimating Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for service estimating?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        electronicMaintenanceMenusVendor: {
          questionKey: 'electronicMaintenanceMenusVendor',
          label: 'Electronic Maintenance Menus Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for electronic maintenance menus?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        multiPointInspectionsVendor: {
          questionKey: 'multiPointInspectionsVendor',
          label: 'Multi-Point Inspections Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for multi-point inspections?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        rentalManagementVendor: {
          questionKey: 'rentalManagementVendor',
          label: 'Rental Management Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for rental management?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        serviceSignaturePadVendor: {
          questionKey: 'serviceSignaturePadVendor',
          label: 'Service Signature Pad Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for customer signature pads?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        servicePricingGuideVendor: {
          questionKey: 'servicePricingGuideVendor',
          label: 'Service Pricing Guide Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for service pricing guides?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        webAndMobilePaymentVendor: {
          questionKey: 'webAndMobilePaymentVendor',
          label: 'Web and Mobile Payment Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for web and mobile payments?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
        serviceManagementVendor: {
          questionKey: 'serviceManagementVendor',
          label: 'Service Management Vendor',
          prompt: [
            {
              text: 'Which vendor do you plan to use for service management?',
              attributes: null,
            },
          ],
          subprompt: [],
          options: [],
          componentType: 'vendor',
          validation: [],
          requirement: 'required',
          allowSkip: false,
          dependencies: [],
        },
      },
    },
  },
};

export type TServicePrelimStructure = typeof servicePreliminaryStructure;

import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../../company/company.service';
import {
  IInvitedUserRead,
  IRequestUserPayload,
  IUserReadPrivate,
  UserInternalAssignments,
} from '@dominion/interfaces';
import { CoreCardComponent } from '../../shared/core-card/core-card.component';
import { LoadingPlaceholderComponent } from '../../shared/loading-placeholder/loading-placeholder.component';
import { ModuleTypePipe } from '../../shared/pipes/module-type.pipe';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { AuthenticationService } from '../../auth/authentication.service';
import { Router } from '@angular/router';
import { TableComponent } from '../../shared/table/table.component';

/**
 * This component is used to display the assignments of an INTERNAL user.
 */
@Component({
  selector: 'dominion-user-assignments',
  standalone: true,
  imports: [
    CommonModule,
    CoreCardComponent,
    LoadingPlaceholderComponent,
    ModuleTypePipe,
    IconGoToComponent,
    TableComponent,
  ],
  templateUrl: './user-assignments.component.html',
  styleUrls: ['./user-assignments.component.css'],
})
export class UserAssignmentsComponent implements OnInit, OnChanges {
  @HostBinding() class = 'grow';

  @Input({ required: true }) user:
    | IUserReadPrivate
    | IInvitedUserRead
    | undefined;

  isLoadingAssignments: boolean = false;

  internalAssignments: UserInternalAssignments[] = [];

  requestor: IRequestUserPayload | undefined;

  constructor(
    private companyService: CompanyService,
    private authentication: AuthenticationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getUserAssignments();
    this.authentication.user.subscribe((user) => {
      this.requestor = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes) {
      this.getUserAssignments();
    }
  }

  goToCompany(companyId: string) {
    if (this.requestor) {
      if (this.requestor.userType === 'internal') {
        this.router.navigate(['/core/admin/companies', companyId, 'detail']);
      } else {
        this.router.navigate(['/core', companyId, 'dashboard']);
      }
    }
  }

  getUserAssignments() {
    if (this.user) {
      this.isLoadingAssignments = true;
      this.companyService.getUserAssignments(this.user._id).subscribe({
        next: (assignments) => {
          this.internalAssignments = assignments as UserInternalAssignments[];
          this.isLoadingAssignments = false;
        },
      });
    }
  }
}

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-close',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="{{ strokeColor }} {{ strokeWidth }} {{ fillColor }}"
  >
    <defs>
      <style>
        .i-close {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>Close</title>
    <line class="i-close" x1="0.75" y1="23.249" x2="23.25" y2="0.749" />
    <line class="i-close" x1="23.25" y1="23.249" x2="0.75" y2="0.749" />
  </svg>`,
})
export class IconCloseComponent extends IconBase {}

import { Component, Input } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-eye-closed',
  standalone: true,
  template: `<svg
    [attr.width]="size"
    [attr.height]="size"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-eye-closed {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>Hide</title>
    <line class="i-eye-closed" x1="2.783" y1="21" x2="21.533" y2="3" />
    <path
      class="i-eye-closed"
      d="M8.964,19.051A9.985,9.985,0,0,0,12,19.5c4.1.069,8.258-2.813,10.824-5.637a1.663,1.663,0,0,0,0-2.226,20.568,20.568,0,0,0-3.074-2.757"
    />
    <path
      class="i-eye-closed"
      d="M14.413,6.282A9.549,9.549,0,0,0,12,6C7.969,5.933,3.8,8.752,1.179,11.635a1.663,1.663,0,0,0,0,2.226,20.8,20.8,0,0,0,2.6,2.4"
    />
    <path class="i-eye-closed" d="M8.25,12.75A3.749,3.749,0,0,1,12,9" />
    <path class="i-eye-closed" d="M15.75,12.749h0A3.75,3.75,0,0,1,12,16.5" />
  </svg>`,
})
export class IconEyeClosedComponent extends IconBase {
  @Input() size: string = '24';
}

<div
  class="flex max-w-full grow flex-col overflow-hidden rounded-sm border shadow-md"
>
  <div
    class="flex items-center p-4 text-sm uppercase tracking-wide text-gray-500"
  >
    @if (title) {
      <div>{{ title }}</div>
    }
    <div class="flex-1"></div>
    @if (titleDetail) {
      <div>{{ titleDetail }}</div>
    }
  </div>
  <div
    class="flex overflow-x-auto"
    [ngClass]="{
      'p-4': hasPadding
    }"
  >
    <ng-content></ng-content>
  </div>
</div>

<div class="flex flex-row justify-center">
  <div
    [ngClass]="{
      'border-gray-100 shadow-2xl': active,
      'border-gray-100 opacity-60 shadow-md': !active
    }"
    class="flex max-w-[700px] flex-1 flex-col rounded-md border px-4 py-16 hover:opacity-100"
  >
    <!-- PROMPT -->
    <div class="mb-8 flex flex-row justify-center text-2xl">
      <div class="flex max-w-[500px] flex-1 flex-col">
        <div class="flex flex-1 flex-row justify-center text-center">
          <ng-content select="[prompt]"></ng-content>
        </div>
      </div>
    </div>

    <!-- SUBPROMPT -->
    <div class="flex flex-row justify-center text-base text-gray-500">
      <div
        class="flex max-w-[500px] flex-1 flex-row border-l-2 border-gray-400 pl-2 [&:has(div)]:mb-10"
      >
        <ng-content select="[subtext]"></ng-content>
      </div>
    </div>

    <!-- INPUT -->
    <div class="flex flex-1 flex-col">
      <ng-content select="[input]"></ng-content>
    </div>
  </div>
</div>

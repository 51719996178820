@if (!internalView) {
  <div class="flex items-center p-4">
    <a
      [routerLink]="['/core', companyId()]"
      class="rounded border bg-gray-100 py-0.5 pl-1.5 pr-2 text-sm"
      >&larr; Back to Company</a
    >
  </div>
}

@if (user?.isDeactivated) {
  <div class="my-8 flex flex-row justify-center">
    <div
      dominionPopoverHost
      [hoverTrigger]="true"
      class="flex max-w-[850px] grow cursor-help flex-row"
    >
      <div
        dominionPopoverTarget
        class="flex basis-36 flex-row items-center justify-center rounded-sm border border-red-700 p-1 text-sm italic text-red-700"
      >
        User is deactivated
      </div>
      <div class="max-w-[200px]" dominionPopover>
        This user was deactivated and does not have access to VOX.
      </div>
    </div>
  </div>
}

<!-- TOP LEVEL NAME OR EMAIL -->
<div class="mx-auto mt-8 flex w-full max-w-[850px] flex-row justify-center">
  <div
    class="flex max-w-[850px] grow flex-row items-center space-x-3 [&.deactivated]:text-red-700"
    [ngClass]="{ deactivated: user?.isDeactivated }"
  >
    <!-- USER AVATAR -->
    <div class="flex h-16 flex-none basis-16">
      <div
        class="bg-dms-light-green [&.internal]:bg-dms-grey flex h-full w-full items-center justify-center rounded [&.deactivated.internal]:bg-red-700 [&.deactivated]:bg-red-700 [&.internal]:p-3"
        [ngClass]="{
          internal: user?.userType === 'internal',
          deactivated: user?.isDeactivated
        }"
      >
        @if (user?.userType === 'external') {
          <span class="text-5xl uppercase text-white">
            {{
              activeUser
                ? activeUser.firstName.charAt(0)
                : invitedUser
                  ? invitedUser.email.charAt(0)
                  : ''
            }}
          </span>
        }

        @if (user?.userType === 'internal') {
          <dominion-icon-shield class="text-white"></dominion-icon-shield>
        }
      </div>
    </div>

    <!-- USER NAME OR EMAIL -->
    <div class="flex max-w-full grow flex-row">
      @if (!isLoading) {
        <!-- ACTIVE USER NAME -->
        @if (user && user.status === 'active') {
          <div class="flex grow text-3xl font-bold">
            <!-- First Name -->
            <div class="max-w-[300px]">
              <dominion-in-situ-text
                #firstName
                [initialValue]="activeUser.firstName"
                defaultValue="First Name"
                formErrMsg="Must provide first name"
                [isDisabled]="!canEditUserDetails"
                (saved)="
                  onUserUpdate({ field: 'firstName', value: $event }, firstName)
                "
              ></dominion-in-situ-text>
            </div>
            <!-- Last Name -->
            <div class="max-w-[300px]">
              <dominion-in-situ-text
                #lastName
                [initialValue]="activeUser.lastName"
                defaultValue="Last Name"
                formErrMsg="Must provide last name"
                [isDisabled]="!canEditUserDetails"
                (saved)="
                  onUserUpdate({ field: 'lastName', value: $event }, lastName)
                "
              ></dominion-in-situ-text>
            </div>
          </div>
        }

        <!-- INVITED USER EMAIL -->
        @if (user && user.status === 'invited') {
          <div class="text-3xl font-bold">
            {{ user.email }}
          </div>
        }
      } @else {
        <!-- LOADING PLACEHOLDER -->
        <div class="flex h-12 basis-2/3 space-x-4">
          <dominion-loading-placeholder
            class="basis-1/2"
          ></dominion-loading-placeholder>
          <dominion-loading-placeholder
            class="basis-1/2"
          ></dominion-loading-placeholder>
        </div>
      }
    </div>
  </div>
  <div class="flex flex-row items-center justify-center">
    @if (canEditUserDetails) {
      <div dominionDropdownHost class="group h-7 w-7 cursor-pointer">
        <dominion-icon-menu
          dominionDropdownToggle
          height="h-full"
          width="w-full"
          strokeWidth="stroke-1"
          strokeColor="stroke-gray-600 group-hover:stroke-dms-green"
        ></dominion-icon-menu>
        <div
          dominionDropdownMenu
          class="text-dms-ultra-grey relative z-50 hidden max-h-[250px] min-w-[200px] flex-col overflow-hidden rounded-sm border border-gray-200 bg-white shadow-lg data-[show]:flex"
        >
          @if (user && user.status === 'invited' && canEditUserDetails) {
            <div
              (click)="resendUserInvite()"
              class="flex flex-row px-2 py-2 hover:bg-gray-100"
            >
              Resend Invitation
            </div>
          }
          @if (!user?.isDeactivated && canEditUserDetails) {
            <div
              (click)="deactivateUser()"
              class="flex flex-row px-2 py-2 text-red-700 hover:bg-red-100"
            >
              Deactivate User
            </div>
          } @else if (canEditUserDetails) {
            <div
              (click)="reactivateUser()"
              class="flex flex-row px-2 py-1 text-green-700 hover:bg-green-100"
            >
              Reactivate User
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>

<!-- INVITED USER MESSAGE -->
<div *ngIf="invitedUser" class="my-10 flex justify-center">
  <div
    class="flex rounded-sm bg-red-50 p-4 px-3 font-light italic text-red-700"
  >
    This user has been invited but has not yet registered
  </div>
</div>

<!-- USER INFO -->
@if (user && user.status === 'active') {
  <div class="mx-auto my-10 flex w-full max-w-[850px] flex-row justify-center">
    <dominion-user-info
      [user]="user"
      [dominionAuthOption]="dominionAuthOptions"
      [isParentLoading]="isLoading"
      [canEditUserDetails]="canEditUserDetails"
    ></dominion-user-info>
  </div>
}

<!-- USER COMPANIES -->
@if (user?.userType === 'external') {
  <div class="mx-auto flex w-full max-w-[850px] flex-row">
    <dominion-user-companies [user]="user"></dominion-user-companies>
  </div>
}

<!-- USER ASSIGNMENTS -->
@if (user?.userType === 'internal') {
  <div class="mx-auto flex min-h-[200px] w-full max-w-[850px] flex-row">
    <dominion-user-assignments [user]="user"></dominion-user-assignments>
  </div>
}
<!-- ACTIVITY LOG -->

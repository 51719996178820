import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Observer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'dominion-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css'],
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  _id: string;
  confirmationCode: string;
  isErr = false;
  errMessage = '';
  successful = false;
  transitionTimeout: NodeJS.Timeout;
  confirmMessage = 'Verifying your email address...';
  successMessage = 'Verified! Transferring to the login page...';

  confirmObserver: Observer<void> = {
    next: () => {
      this.confirmSuccess();
    },
    error: (err: HttpErrorResponse) => {
      this.handleErr(err);
    },
    complete: () => {
      return;
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {}

  confirmEmail() {
    if (!this._id || !this.confirmationCode) {
      this.isErr = true;
      this.errMessage = 'Invalid confirmation link';
      this.spinner.hide();
      return;
    }
    this.authService
      .confirmEmail(this._id, this.confirmationCode)
      .subscribe(this.confirmObserver);
  }

  private handleErr(err: HttpErrorResponse) {
    this.spinner.hide();
    this.isErr = true;
    this.errMessage = err.error.message;
  }

  confirmSuccess() {
    this.successful = true;
    this.transitionTimeout = setTimeout(() => {
      this.transitionToLogin();
    }, 3000);
  }

  transitionToLogin() {
    this.router.navigateByUrl('login');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.route.params.subscribe((params: Params) => {
      this._id = params['id'];
      this.confirmationCode = params['code'];
      this.confirmEmail();
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.transitionTimeout);
  }
}

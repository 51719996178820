import {
  IModuleDataLog,
  PartsPreliminaryData,
  ServicePreliminaryData,
  ModuleType,
  TPartsPrelimGroupMap,
  TPartsPrelimGroupIdentifiers,
  TServicePrelimGroupMap,
  TServicePrelimGroupIdentifiers,
  PartsPreliminarySubmodule,
  ServicePreliminarySubmodule,
  QuestionComponentType,
  QuestionSections,
  InfraPreliminarySubmodule,
  ResponseLog,
} from '@dominion/interfaces';
import { HydratedDocument, Model, Types } from 'mongoose';
import {
  SalesPreliminaryData,
  TSalesPrelimGroupIdentifiers,
  TSalesPrelimGroupMap,
} from '../sales/prelim/sales-prelim-data.interfaces';
import {
  AccountingPreliminaryData,
  TAccountingPrelimGroupIdentifiers,
  TAccountingPrelimGroupMap,
} from '../accounting/prelim/accounting-prelim-data.interfaces';
import {
  TPayrollPrelimGroupIdentifiers,
  TPayrollPrelimGroupMap,
  PayrollPreliminaryData,
} from '../payroll/prelim/payroll-prelim-data.interfaces';
import {
  FIPreliminaryData,
  TFIPrelimGroupIdentifiers,
  TFIPrelimGroupMap,
} from '../fi/prelim/fi-prelim-data.interfaces';
import { PayrollPreliminarySubmodule } from '../payroll/prelim/payroll-submodule.class';
import { FIPreliminarySubmodule } from '../fi/prelim/fi-prelim-submodule.class';
import { AccountingPreliminarySubmodule } from '../accounting/prelim/accounting-prelim-submodule.class';
import { SalesPreliminarySubmodule } from '../sales/prelim/sales-prelim-submodule.class';
import {
  TInfraPrelimGroupMap,
  InfraPreliminaryData,
} from '../infrastructure/infra-prelim-data.interfaces';
import {
  AccountingMaintenanceData,
  TAccountingMaintenanceGroupMap,
} from '../accounting/maintenance/accounting-maintenance-data.interfaces';
import { TAccountingMaintenanceGroupIdentifiers } from '../accounting/maintenance/accounting-maintenance-data.interfaces';
import {
  PartsMaintenanceData,
  TPartsMaintenanceGroupIdentifiers,
  TPartsMaintenanceGroupMap,
} from '../parts/maintenance/parts-maintenance-data.interfaces';
import { AccountingMaintenanceSubmodule } from '../accounting/maintenance/accounting-maintenance-submodule.class';
import {
  ServiceMaintenanceData,
  TServiceMaintenanceGroupIdentifiers,
  TServiceMaintenanceGroupMap,
} from '../service/maintenance/service-maintenance-data.interfaces';
import { ServiceMaintenanceSubmodule } from '../service/maintenance/service-maintenance-submodule.class';
import {
  SalesMaintenanceData,
  TSalesMaintenanceGroupIdentifiers,
  TSalesMaintenanceGroupMap,
} from '../sales/maintenance/sales-maintenance-data.interfaces';
import { SalesMaintenanceSubmodule } from '../sales/maintenance/sales-maintenance-submodule.class';
import { PartsMaintenanceSubmodule } from '../parts/maintenance/parts-maintenance-submodule.class';
import {
  PayrollMaintenanceData,
  TPayrollMaintenanceGroupIdentifiers,
  TPayrollMaintenanceGroupMap,
} from '../payroll/maintenance/payroll-maintenance-data.interfaces';
import { PayrollMaintenanceSubmodule } from '../payroll/maintenance/payroll-maintenance-submodule.class';

export const isKeyOf = function <T extends object>(
  obj: T,
  key: string | number | symbol,
): key is keyof T {
  return key in obj;
};

//
//
//
//
// AGGREGREGATION OF SUBMODULE TYPES
//

export type TGroupIdentifiers =
  | TPartsPrelimGroupIdentifiers
  | TServicePrelimGroupIdentifiers
  | TSalesPrelimGroupIdentifiers
  | TAccountingPrelimGroupIdentifiers
  | TPayrollPrelimGroupIdentifiers
  | TFIPrelimGroupIdentifiers
  | TAccountingMaintenanceGroupIdentifiers
  | TPartsMaintenanceGroupIdentifiers
  | TServiceMaintenanceGroupIdentifiers
  | TSalesMaintenanceGroupIdentifiers
  | TPayrollMaintenanceGroupIdentifiers;

export type TDiscoveryData =
  | ServicePreliminaryData
  | PartsPreliminaryData
  | SalesPreliminaryData
  | AccountingPreliminaryData
  | PayrollPreliminaryData
  | FIPreliminaryData
  | InfraPreliminaryData
  | AccountingMaintenanceData
  | PartsMaintenanceData
  | ServiceMaintenanceData
  | SalesMaintenanceData
  | PayrollMaintenanceData;

export type TDiscoveryDataPartial = Partial<TDiscoveryData>;

export type TSubmoduleMaps =
  | TServicePrelimGroupMap
  | TPartsPrelimGroupMap
  | TSalesPrelimGroupMap
  | TAccountingPrelimGroupMap
  | TPayrollPrelimGroupMap
  | TFIPrelimGroupMap
  | TInfraPrelimGroupMap
  | TAccountingMaintenanceGroupMap
  | TPartsMaintenanceGroupMap
  | TServiceMaintenanceGroupMap
  | TSalesMaintenanceGroupMap
  | TPayrollMaintenanceGroupMap;

export type ISubmodules =
  | ICoreSubmodule<'parts-preliminary'>
  | ICoreSubmodule<'service-preliminary'>
  | ICoreSubmodule<'sales-preliminary'>
  | ICoreSubmodule<'accounting-preliminary'>
  | ICoreSubmodule<'payroll-preliminary'>
  | ICoreSubmodule<'fi-preliminary'>
  | ICoreSubmodule<'infrastructure-preliminary'>
  | ICoreSubmodule<'accounting-maintenance'>
  | ICoreSubmodule<'parts-maintenance'>
  | ICoreSubmodule<'service-maintenance'>
  | ICoreSubmodule<'sales-maintenance'>
  | ICoreSubmodule<'payroll-maintenance'>;

export type TSubmodules =
  | PartsPreliminarySubmodule
  | ServicePreliminarySubmodule
  | PayrollPreliminarySubmodule
  | FIPreliminarySubmodule
  | AccountingPreliminarySubmodule
  | SalesPreliminarySubmodule
  | InfraPreliminarySubmodule
  | AccountingMaintenanceSubmodule
  | PartsMaintenanceSubmodule
  | ServiceMaintenanceSubmodule
  | SalesMaintenanceSubmodule
  | PayrollMaintenanceSubmodule;

export type TAllGroups =
  | Structures['parts-preliminary']['groups'][keyof Structures['parts-preliminary']['groups']]
  | Structures['service-preliminary']['groups'][keyof Structures['service-preliminary']['groups']]
  | Structures['sales-preliminary']['groups'][keyof Structures['sales-preliminary']['groups']]
  | Structures['accounting-preliminary']['groups'][keyof Structures['accounting-preliminary']['groups']]
  | Structures['payroll-preliminary']['groups'][keyof Structures['payroll-preliminary']['groups']]
  | Structures['fi-preliminary']['groups'][keyof Structures['fi-preliminary']['groups']]
  | Structures['infrastructure-preliminary']['groups'][keyof Structures['infrastructure-preliminary']['groups']]
  | Structures['accounting-maintenance']['groups'][keyof Structures['accounting-maintenance']['groups']]
  | Structures['parts-maintenance']['groups'][keyof Structures['parts-maintenance']['groups']]
  | Structures['service-maintenance']['groups'][keyof Structures['service-maintenance']['groups']]
  | Structures['sales-maintenance']['groups'][keyof Structures['sales-maintenance']['groups']]
  | Structures['payroll-maintenance']['groups'][keyof Structures['payroll-maintenance']['groups']];

//
//
//
//
// BASIC TYPES
//

export type ComparisonMethod =
  | 'non-null'
  | 'equals'
  | 'not-equal-to'
  | 'greater-than'
  | 'less-than'
  | 'greater-than-or-equal-to'
  | 'less-than-or-equal-to'
  | 'one-of';

export type SubmoduleType = 'preliminary' | 'maintenance' | 'default';

//
//
//
//
// DISCOVERY DATA DEFINITION
//

export interface IDiscoveryDataProperty<T> {
  value: T | null;
  log: ResponseLog[];
}

export type TDataDefinition<DataKeys extends string> = Record<
  DataKeys,
  IDiscoveryDataProperty<any>
>;

// map discriminators to data definintions

type TDiscriminatorToDataMap = {
  [Discriminator in TSubmoduleDiscriminators]: TDiscoveryData;
};

class DiscoveryDataMap implements TDiscriminatorToDataMap {
  'parts-preliminary': PartsPreliminaryData;
  'service-preliminary': ServicePreliminaryData;
  'sales-preliminary': SalesPreliminaryData;
  'accounting-preliminary': AccountingPreliminaryData;
  'payroll-preliminary': PayrollPreliminaryData;
  'fi-preliminary': FIPreliminaryData;
  'infrastructure-preliminary': InfraPreliminaryData;
  'accounting-maintenance': AccountingMaintenanceData;
  'parts-maintenance': PartsMaintenanceData;
  'service-maintenance': ServiceMaintenanceData;
  'sales-maintenance': SalesMaintenanceData;
  'payroll-maintenance': PayrollMaintenanceData;
}

//
//
//
//
// SUBMODULE GROUP MAP DEFINITION
//

export type TSubmoduleGroupMap<
  GroupKey extends string,
  DataKey extends string,
> = {
  moduleType: ModuleType;
  submoduleType: SubmoduleType;
  discriminator: `${ModuleType}-${SubmoduleType}`;
  groups: {
    [Key in GroupKey]: readonly DataKey[];
  };
};

//
//
//
//
// CORE SUBMODULE STRUCTURE DEFINITION
//

export type AllDataTypes = PartsPreliminaryData &
  ServicePreliminaryData &
  SalesPreliminaryData &
  AccountingPreliminaryData &
  PayrollPreliminaryData &
  FIPreliminaryData &
  InfraPreliminaryData &
  AccountingMaintenanceData &
  PartsMaintenanceData &
  ServiceMaintenanceData &
  SalesMaintenanceData &
  PayrollMaintenanceData;

type GetDataDefinitionFromKey<QuestionKey extends string> =
  QuestionKey extends keyof AllDataTypes
    ? AllDataTypes[QuestionKey]['value']
    : never;

export type QuestionRequirementType = 'required' | 'dependent';

export type PromptText = {
  text: string;
  attributes: {
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    tooltip?: PromptText[];
    link?: string;
  } | null;
};

export type PromptTextWithTooltip = {
  type: 'text-with-tooltip';
  text: string;
  tooltip: string;
};

export type ResponseOptions = {
  label: string;
  value: any;
};

export type ResponseValidation =
  | { method: 'required'; value: boolean }
  | { method: 'min-length'; value: number }
  | { method: 'max-length'; value: number }
  | { method: 'pattern'; value: RegExp | 'number' }
  | { method: 'greater-than'; value: number }
  | { method: 'less-than'; value: number }
  | { method: 'greater-than-or-equal-to'; value: number }
  | { method: 'less-than-or-equal-to'; value: number };

export type ValidationMethod = ResponseValidation['method'];

export type Structures = {
  [Map in TSubmoduleMaps as Map['discriminator']]: {
    moduleType: Map['moduleType'];
    submoduleType: Map['submoduleType'];
    discriminator: Map['discriminator'];
    groups: {
      [GroupKey in keyof Map['groups']]: {
        groupLabel: string;
        groupUrl: GroupKey;
        groupType: 'standard' | 'vendor';
        questions: {
          [QuestionKey in Extract<Map['groups'][GroupKey], string[]>[number]]: {
            questionKey: QuestionKey;
            label: string;
            prompt: PromptText[];
            subprompt: PromptText[];
            componentType: QuestionComponentType;
            requirement: QuestionRequirementType;
            allowSkip: boolean;
            sections?: QuestionSections;
            options: Array<{
              label: string;
              value: GetDataDefinitionFromKey<QuestionKey>;
            }>;
            validation: ResponseValidation[];
            dependencies: Array<{
              comparisonMethod: ComparisonMethod;
              comparisonValue: GetDataDefinitionFromKey<QuestionKey>;
              dependentKeys: Extract<Map['groups'][GroupKey], string[]>;
            }>;
            order?: number;
          };
        };
      };
    };
  };
};

export type TSubmoduleDiscriminators = TSubmoduleMaps['discriminator'];
export type TAllGroupKeys = {
  [Discriminator in keyof Structures]: keyof Structures[Discriminator]['groups'];
}[keyof Structures];

//
//
//
//
// CORE SUBMODULE DEFINITION
//

export type TSubmoduleMetaCompletion<
  GroupKeys extends string | number | symbol,
> = {
  total: number;
  complete: number;
  groups: {
    [GroupKey in GroupKeys]: {
      total: number;
      complete: number;
    };
  };
};

export interface ICoreSubmodule<
  Discriminator extends TSubmoduleDiscriminators,
> {
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType: Structures[Discriminator]['moduleType'];
  submoduleType: Structures[Discriminator]['submoduleType'];
  discriminator: Structures[Discriminator]['discriminator'];
  meta: {
    completion: TSubmoduleMetaCompletion<any>;
  };
  structure: Structures[Discriminator]['groups'];
  data: DiscoveryDataMap[Discriminator];
}

export interface ICoreSubmoduleOverrides {
  _id: Types.ObjectId;
  companyId: Types.ObjectId;
  moduleId: Types.ObjectId;
}

export interface ICoreSubmoduleModel
  extends Model<
    ICoreSubmodule<TSubmoduleDiscriminators>,
    {},
    ICoreSubmoduleOverrides
  > {}

export interface ICoreSubmoduleDocument
  extends HydratedDocument<
    ICoreSubmodule<TSubmoduleDiscriminators>,
    ICoreSubmoduleOverrides
  > {}

//
//
//
//
// SUBMODULE UI GROUPS

// takes a submodule map and returns a map of groups, keys, labels

export type TSubmoduleUIGroup<Map extends TSubmoduleMaps> = {
  groups: {
    [GroupKey in keyof Map['groups']]: {
      url: string;
      groupKey: GroupKey;
      label: string;
      desc: string;
      questions: {
        [QuestionKey in Extract<Map['groups'][GroupKey], string[]>[number]]: {
          questionKey: QuestionKey;
          questionLabel: string;
        };
      }[Extract<Map['groups'][GroupKey], string[]>[number]][];
    };
  }[keyof Map['groups']][];
};

export type TVendorData =
  | {
      none: true;
      other: null;
      id: null;
    }
  | {
      none: false;
      other: string;
      id: null;
    }
  | {
      none: false;
      other: null;
      id: string;
    };

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IDiscoveryDataProperty,
  IFileUploadReference,
} from '@dominion/interfaces';
import { InputFileComponent } from '../input-file/input-file.component';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { LogComponent } from '../../log/log.component';
import { ModalComponent } from '../../shared/modal/modal.component';
import { SharedModule } from '../../shared/shared.module';

type FileInput = {
  fileInput: unknown;
  fileReference: IFileUploadReference | null;
};

@Component({
  selector: 'dominion-input-file-container',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    InputFileComponent,
    DiscoveryNextBtnDirective,
    LogComponent,
  ],
  templateUrl: './input-file-container.component.html',
  styleUrls: ['./input-file-container.component.css'],
})
export class InputFileContainerComponent implements OnChanges {
  @Input({ required: true }) data: IDiscoveryDataProperty<
    IFileUploadReference[]
  >;
  get log() {
    return this.data?.log ? this.data.log : [];
  }
  @Input({ required: true }) companyId: string;
  @Input({ required: true }) moduleId: string;
  @Input({ required: true }) submoduleId: string;
  @Input({ required: true }) questionKey: string;
  @Input() allowSkip: boolean = false;

  fileInputs: FileInput[] = [];

  @Output() response: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() advanceEvent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('logModal') logModal: ModalComponent;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] || changes['data.value']) {
      this.initialize();
    }
  }

  addFileInput() {
    this.fileInputs.push({ fileInput: null, fileReference: null });
  }

  removeFileInput(index: number) {
    const input = this.fileInputs[index];
    if (!input.fileInput && !input.fileReference) {
      this.fileInputs.splice(index, 1);
      return;
    }
  }

  tracker(input: FileInput) {
    if (input.fileReference) {
      return input.fileReference._id;
    } else if (input.fileInput) {
      return (input.fileInput as { file: { name: string } }).file.name;
    } else {
      return new Date().getTime();
    }
  }

  initialize() {
    this.fileInputs = [];
    if (this.data && this.data.value) {
      if (Array.isArray(this.data.value)) {
        this.fileInputs = this.data.value.map((fileReference) => ({
          fileInput: null,
          fileReference,
        }));
      } else {
        this.fileInputs.push({
          fileInput: null,
          fileReference: this.data.value,
        });
      }
    }
    if (this.fileInputs.length === 0) {
      this.fileInputs.push({ fileInput: null, fileReference: null });
    }
  }

  handleUpload(fileRef: IFileUploadReference) {
    if (Array.isArray(this.data.value)) {
      this.data.value = [...this.data.value, fileRef];
    } else {
      this.data.value = [fileRef];
    }
    this.response.emit(this.data.value);
  }

  handleDelete(fileId: string) {
    const filtered = this.data.value?.filter((file) => file._id !== fileId);
    this.response.emit(filtered);
  }

  isDisabled() {
    if (this.allowSkip) {
      return false;
    } else {
      return this.fileInputs.every((input) => !input.fileReference);
    }
  }

  advance() {
    if (this.allowSkip) {
      this.advanceEvent.emit();
      return;
    }
    if (!this.isDisabled()) {
      this.advanceEvent.emit();
      return;
    }
    return;
  }
}

<dominion-core-card
  title="Assigned Companies ({{ internalAssignments.length }})"
  [hasPadding]="false"
>
  <dominion-table
    [hideFrame]="true"
    [isLoading]="isLoadingAssignments"
    [isEmpty]="internalAssignments.length === 0"
  >
    <thead>
      <tr>
        <th>Company Name</th>
        <th>Go-Live Date</th>
        <th>PID</th>
        <th><span class="sr-only">View</span></th>
      </tr>
    </thead>
    <tbody>
      @for (company of internalAssignments; track company._id) {
        <tr>
          <td>
            @if (company.name) {
              <span class="font-semibold" [title]="company.name">{{
                company.name
              }}</span>
            } @else {
              <span class="text-gray-400">&mdash;</span>
            }
          </td>
          <td>
            @if (company.goLiveDate) {
              <span [title]="company.goLiveDate">{{
                company.goLiveDate | date: 'mediumDate'
              }}</span>
            } @else {
              <span class="text-gray-400">&mdash;</span>
            }
          </td>
          <td>
            @if (company.preferredInstallDate) {
              <span [title]="company.preferredInstallDate">{{
                company.preferredInstallDate | date: 'mediumDate'
              }}</span>
            } @else {
              <span class="text-gray-400">&mdash;</span>
            }
          </td>
          <td>
            <button
              class="text-dms-green hover:bg-dms-light-green/20 group flex h-7 w-7 cursor-pointer items-center truncate rounded-sm p-[6px] text-sm font-medium"
              (click)="goToCompany(company._id)"
              title="Go to company"
            >
              <dominion-icon-go-to
                strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
              ></dominion-icon-go-to>
            </button>
          </td>
        </tr>
      }
    </tbody>
  </dominion-table>
</dominion-core-card>

export type IDMS =
  | 'cdk'
  | 'dealertrack'
  | 'reynolds'
  | 'automate'
  | 'autosoft'
  | 'pbs'
  | 'dealerbuilt'
  | 'tekion'
  | 'quorum'
  | 'adam'
  | 'none'
  | 'other';
export const IDMSList: IDMS[] = [
  'adam',
  'autosoft',
  'automate',
  'cdk',
  'dealerbuilt',
  'dealertrack',
  'pbs',
  'quorum',
  'reynolds',
  'tekion',
  'other',
];

export const IDMSListLabels: { [key in IDMS]: string } = {
  adam: 'ADAM',
  autosoft: 'Autosoft',
  automate: 'Auto/Mate',
  cdk: 'CDK',
  dealerbuilt: 'DealerBuilt',
  dealertrack: 'DealerTrack',
  pbs: 'PBS',
  quorum: 'Quorum',
  reynolds: 'Reynolds & Reynolds',
  tekion: 'Tekion',
  none: 'None',
  other: 'Other',
};

export const IDMSOptions = IDMSList.map((dms) => {
  return {
    value: dms,
    label: IDMSListLabels[dms],
  };
});

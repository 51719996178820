<div class="flex h-full w-full flex-col">
  <div class="p-4">
    <div dominionCoreOutletTitle>Users</div>
  </div>

  <div class="flex-1 overflow-hidden p-4">
    <div class="flex h-full w-full flex-col">
      <div class="flex items-center justify-between gap-4">
        <dominion-option-toggle
          [selectedOption]="selectedView"
          (emitSelectedOption)="setSelectedView($event)"
          [options]="[
            { label: 'Active Users', value: 'active' },
            { label: 'Invited Users', value: 'invited' }
          ]"
        ></dominion-option-toggle>

        <!-- <dominion-search-bar -->
        <!--   (emitStartSearch)="isLoadingUsers = true" -->
        <!--   (emitFilterValue)="searchUsers($event)" -->
        <!--   class="w-full min-w-max max-w-xs" -->
        <!-- ></dominion-search-bar> -->
      </div>

      <div class="h-6"></div>

      <div class="flex-1 overflow-hidden">
        @if (selectedView.value === 'invited') {
          <dominion-invited-users-table></dominion-invited-users-table>
        } @else {
          <dominion-users-table></dominion-users-table>
        }
      </div>
    </div>
  </div>
</div>

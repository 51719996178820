import { Component, Input } from '@angular/core';
import { APP_VERSION } from '../../../version';

@Component({
  selector: 'dominion-auth-root',
  standalone: true,
  templateUrl: './auth-root.component.html',
  host: {
    class: 'block w-full h-auto overflow-y-auto',
  },
})
export class AuthRootComponent {
  @Input() heading: string;
  @Input() subheading?: string = 'VUE onboarding experience';

  appVersion = APP_VERSION;
}

import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconAuthComponent } from '../../icons/icon-auth.component';
import { IconEmailComponent } from '../../icons/icon-email.component';
import { IconJobTitleComponent } from '../../icons/icon-job-title.component';
import { IconDeptComponent } from '../../icons/icon-dept.component';
import {
  DominionAuthRole,
  IInvitedUserRead,
  IUserReadPrivate,
  IUserDominionAuthDto,
  IUserUpdateDto,
  companyDeptOptions,
  dominionDeptOptions,
} from '@dominion/interfaces';
import { UserService } from '../user.service';
import { IUserUpdateEvent } from '../../manage-account/manage-account.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Editable } from '../../interfaces/editable.interfaces';
import { InSituSelectComponent } from '../../shared/inputs/in-situ-select/in-situ-select.component';
import { InSituTextComponent } from '../../shared/inputs/in-situ-text/in-situ-text.component';
import { LoadingPlaceholderComponent } from '../../shared/loading-placeholder/loading-placeholder.component';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';

@Component({
  selector: 'dominion-user-info',
  standalone: true,
  imports: [
    CommonModule,
    IconAuthComponent,
    IconEmailComponent,
    IconJobTitleComponent,
    IconDeptComponent,
    InSituSelectComponent,
    InSituTextComponent,
    LoadingPlaceholderComponent,
  ],
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
})
export class UserInfoComponent {
  @HostBinding() class = 'grow max-w-full';
  /**
   * The user to be displayed
   */
  @Input({ required: true }) user:
    | IUserReadPrivate
    | IInvitedUserRead
    | undefined;
  /**
   * Whether the user details can be edited by the acting user
   */
  @Input({ required: true }) canEditUserDetails: boolean = false;
  /**
   * Whether the parent component is loading
   */
  @Input() isParentLoading: boolean = false;

  @Input() dominionAuthOption: DropdownOption[] = [];

  // STATE

  dominionDeptOptions = dominionDeptOptions;
  companyDeptOptions = companyDeptOptions;

  constructor(private userService: UserService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUserUpdate(event: IUserUpdateEvent, editableComp: Editable<unknown>) {
    const dto: IUserUpdateDto = {
      [event.field]: event.value,
    };
    this.updateUser(dto, editableComp);
  }

  getUserAuth() {
    if (this.user && this.user.authorizations) {
      return this.user.authorizations['dominion:'].roles[0];
    }
    return null;
  }
  setDominionAuth(event: unknown) {
    const dto: IUserDominionAuthDto = {
      role: event as DominionAuthRole,
      userId: this.user!._id,
    };
    this.userService.setDominionAuthorization(dto).subscribe({
      next: () => {
        this.user!.authorizations['dominion:'].roles[0] =
          event as DominionAuthRole;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateUser(dto: IUserUpdateDto, editableComp: Editable<unknown>) {
    // call service
    this.userService
      .updateUser(this.user!._id as unknown as string, dto)
      .subscribe({
        next: () => {
          this.user = Object.assign(this.user!, dto);
          editableComp.handleSuccess();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          editableComp.handleError(err.error.message);
        },
      });
  }
}

<div class="flex flex-1 flex-row justify-center">
  <div class="flex basis-1/2 flex-col space-y-1 text-lg">
    <ng-container *ngIf="options.length === 2">
      <div class="flex flex-row space-x-2">
        <div
          dominionDiscoveryBtn
          (click)="selectOption(options[0])"
          [ngClass]="{ selected: selectedOption === options[0].value }"
        >
          {{ options[0].label }}
        </div>
        <div
          dominionDiscoveryBtn
          (click)="selectOption(options[1])"
          [ngClass]="{ selected: selectedOption === options[1].value }"
        >
          {{ options[1].label }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="options.length === 3">
      <div class="flex flex-row">
        <div class="flex flex-1 flex-col space-y-2">
          <div
            dominionDiscoveryBtn
            (click)="selectOption(options[0])"
            [ngClass]="{ selected: selectedOption === options[0].value }"
          >
            {{ options[0].label }}
          </div>
          <div
            dominionDiscoveryBtn
            (click)="selectOption(options[1])"
            [ngClass]="{ selected: selectedOption === options[1].value }"
          >
            {{ options[1].label }}
          </div>
          <div
            dominionDiscoveryBtn
            (click)="selectOption(options[2])"
            [ngClass]="{ selected: selectedOption === options[2].value }"
          >
            {{ options[2].label }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="options.length === 4">
      <div class="mb-1 flex flex-row space-x-2">
        <div
          dominionDiscoveryBtn
          (click)="selectOption(options[0])"
          [ngClass]="{ selected: selectedOption === options[0].value }"
        >
          {{ options[0].label }}
        </div>
        <div
          dominionDiscoveryBtn
          (click)="selectOption(options[1])"
          [ngClass]="{ selected: selectedOption === options[1].value }"
        >
          {{ options[1].label }}
        </div>
      </div>
      <div class="flex flex-row space-x-2">
        <div
          dominionDiscoveryBtn
          (click)="selectOption(options[2])"
          [ngClass]="{ selected: selectedOption === options[2].value }"
        >
          {{ options[2].label }}
        </div>
        <div
          dominionDiscoveryBtn
          (click)="selectOption(options[3])"
          [ngClass]="{ selected: selectedOption === options[3].value }"
        >
          {{ options[3].label }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

import { Component } from '@angular/core';
import { IconBase } from './icon-base.class';

@Component({
  selector: 'dominion-icon-support',
  standalone: true,
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    class="{{ strokeColor }} {{ fillColor }} {{ strokeWidth }}"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .i-support {
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5px;
        }
      </style>
    </defs>
    <title>Support</title>
    <path
      class="i-support"
      d="M15.322,2.549a8.989,8.989,0,0,1,2.251,16.5v4.2"
    />
    <circle class="i-support" cx="13.072" cy="11.25" r="3.75" />
    <path
      class="i-support"
      d="M10.822,8.25V1.5a.75.75,0,0,1,.75-.75h3a.75.75,0,0,1,.75.75V8.25"
    />
    <path
      class="i-support"
      d="M4.073,15.761l-.936-.234a1.5,1.5,0,0,1-.884-2.287l1.82-2.729v-.75c0-3.542,2.868-6.511,6.749-7.3"
    />
    <path
      class="i-support"
      d="M8.573,23.25V21.011l-1.007.167a2.978,2.978,0,0,1-1.244-.053"
    />
    <path class="i-support" d="M13.072,11.25a7.5,7.5,0,0,1-7.5,7.5h-1.5" />
  </svg>`,
})
export class IconSupportComponent extends IconBase {}
